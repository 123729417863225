import React from "react";
import { Grid } from "@mui/material";
import LandingPageHeader from "./LandingPageHeader/LandingPageHeader";
import HomeImg from "../../assets/images/home-video-poster.png";
import ButtonImg from "../../assets/images/video-btn.svg";
import ipadCenterImg from "../../assets/images/ipad-center-img.png";
import featuresImg from "../../assets/images/features-img.png";
import momentumImg from "../../assets/images/momentum-engine.png";
import careerRightImg from "../../assets/images/career-infographic.png";
import fingerPrintImg from "../../assets/images/security_fingerprint.png";
import { Collapse, Tag } from "antd";
import eyeImg from "../../assets/images/security_eye.png";
import ClarifyCareer from "./Tabs/ClarifyCareer";
import AvoidGoal from "./Tabs/AvoidGoal";
import StartMoving from "./Tabs/StartMoving";
import AccelerateGrowth from "./Tabs/AccelerateGrowth";
import GrowKnowledge from "./Tabs/GrowKnowledge";
import ExtraMotivation from "./Tabs/ExtraMotivation";
import CoachingMentoring from "./Tabs/CoachingMentoring";
import { useActiveTab } from "../Dashboard/Context/activeTab";
import { Icon } from "@iconify/react";
import "./LandingPage.css";
import LandingPageFooter from "./LandingPageFooter/LandingPageFooter";

const LandingPage = () => {
  const { activeTab, setActiveTab } = useActiveTab();

  const RenderTab = [
    {
      name: "Clarify your next career pathway",
      component: <ClarifyCareer />,
    },
    {
      name: "Avoid goal ambiguity",
      component: <AvoidGoal />,
    },
    {
      name: "Start moving",
      component: <StartMoving />,
    },
    {
      name: "Accelerate growth",
      component: <AccelerateGrowth />,
    },
    {
      name: "Grow your knowledge",
      component: <GrowKnowledge />,
    },
    {
      name: "Get extra motivation",
      component: <ExtraMotivation />,
    },
    {
      name: "Access on-demand coaching & mentoring",
      component: <CoachingMentoring />,
    },
  ];

  const items = [
    {
      key: "1",
      label: "What is Youe?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "2",
      label: "How does Youe help me with my career goals?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "3",
      label: "What is RoadMap, and how does it work?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "4",
      label: "What is 'Nudge', and how does it work?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "5",
      label: "What are critical enablers?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "6",
      label: "Does Youe offer mentoring and coaching?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "7",
      label: "What can I learn in the Youe app?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "8",
      label: "Why should I join the community?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "9",
      label: "How do I ensure my data will be safe?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "10",
      label: "What if I have specific questions or need platform assistance?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "11",
      label: "Why is Youe currently in beta?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "12",
      label: "Why should I sign up to the beta version?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "13",
      label: "What is YoueAI and how does it differ from other AI tools?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "14",
      label: "Is Youe affordable?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "15",
      label: "How do I cancel my subscription?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
    {
      key: "16",
      label: "Can I request content and features?",
      children: (
        <p class="details-text">
          Youe is a global online career advancement platform that helps members
          get ahead of the curve. Our mission is to make the process of career
          advancement accessible and affordable for everyone, and easier to
          execute.
        </p>
      ),
    },
  ];

  const featuresItems = [
    {
      key: "1",
      label: "Planning",
      children: (
        <p class="details-text">
          Set ambitious yet achievable goals, broadening your professional
          horizons.
        </p>
      ),
    },
    {
      key: "2",
      label: "Visualisation",
      children: (
        <p class="details-text">
          Set ambitious yet achievable goals, broadening your professional
          horizons.
        </p>
      ),
    },
    {
      key: "3",
      label: "Execution",
      children: (
        <p class="details-text">
          Set ambitious yet achievable goals, broadening your professional
          horizons.
        </p>
      ),
    },
    {
      key: "42",
      label: "Learning",
      children: (
        <p class="details-text">
          Set ambitious yet achievable goals, broadening your professional
          horizons.
        </p>
      ),
    },
    {
      key: "5",
      label: "Support",
      children: (
        <p class="details-text">
          Set ambitious yet achievable goals, broadening your professional
          horizons.
        </p>
      ),
    },
  ];
  return (
    <>
      <div className="landing-body">
        <LandingPageHeader />
        <div className="container home-bn-section">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="caption-box">
                <h1 className="caption-title">
                  Find your next career pathway.
                </h1>
                <p className="details-text">
                  Get a holistic vision of your next career pathway, create
                  ambitious goals, set milestones, track your progress and feel
                  supported by our community.
                </p>
                <div className="subcribe-form">
                  <input type="text" placeholder="Email" />
                  <button className="join-btn">Get Started</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="poster-video">
                <img src={HomeImg} alt="" className="img-fluid poster-img" />
                <button className="play-control">
                  <img
                    src={ButtonImg}
                    alt=""
                    className="img-fluid poster-img"
                  />
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="section-space center-img-section">
          <div className="container">
            <h2 className="section-title">
              Make the right decisions to drive
              <span className="d-block">impactful change in your career</span>
            </h2>
            <img src={ipadCenterImg} alt="" className="img-fluid" />
          </div>
        </div>
        {/* Tabs */}
        <div className="section-space container">
          <div className="tab-section">
            <Grid container spacing={0}>
              <Grid item xs={3} className="tab-grid-col">
                {RenderTab.map((config) => (
                  <>
                    <button
                      onClick={() => setActiveTab(config.name)}
                      className="tabs-control"
                    >
                      {config.name}
                    </button>
                  </>
                ))}
              </Grid>
              <Grid item xs={9} className="tab-grid-col-large">
                {RenderTab.map(
                  (config) => activeTab === config.name && config.component
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        {/* End Tabs */}
        <div className="section-space">
          <div>
            <h2 className="section-title">
              Find out why our members
              <span className="d-block">love using Youe</span>
            </h2>
            <div className="tag-wrapper">
              <Tag className="primary-color-tag">
                Provides me with more direction
              </Tag>
              <Tag>Takes the guesswork out of career planning</Tag>
              <Tag className="primary-color-tag">The content is helpful</Tag>
              <Tag>I’m inspired and motivated to stay focused</Tag>
            </div>
            <div className="tag-wrapper">
              <Tag>Helps pinpoint areas that need more development </Tag>
              <Tag>Helps pinpoint areas that need more development </Tag>
              <Tag>Provides me with clearer choices</Tag>
              <Tag>Great architecture and visualisation</Tag>
              <Tag className="primary-color-tag">Brilliant!</Tag>
              <Tag className="primary-color-tag">
                Shows me the key steps I need to take
              </Tag>
            </div>
          </div>
        </div>
        <div className="section-space">
          <div className="container">
            <h2 className="section-title">
              One platform.
              <span className="d-block">5 powerful features.</span>
            </h2>
            <Grid container spacing={0} className="features-wrapper">
              <Grid item xs={6}>
                <img src={featuresImg} alt="" className="img-fluid" />
              </Grid>
              <Grid item xs={6}>
                <Collapse
                  accordion
                  items={featuresItems}
                  defaultActiveKey={["1"]}
                  // onChange={onChange}
                  className="features-accordion"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <Icon
                        icon={"majesticons:minus"}
                        width="20px"
                        height="20px"
                      />
                    ) : (
                      <Icon
                        icon={"majesticons:plus"}
                        width="20px"
                        height="20px"
                      />
                    )
                  }
                  expandIconPosition="right"
                  icon
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="section-space momentum-section">
          <div className="container">
            <h2 className="section-title">
              <span className="d-block">Meet YoueAI</span>
              Your momentum engine.
            </h2>
            <Grid container spacing={3} className="momentum-wrapper">
              <Grid item xs={6}>
                <p className="details-text">
                  <b>
                    Our AI tool, YoueAI, is a powerful and intelligent aid to
                    help analyse the goals and generate action plans tailored
                    for each member.
                  </b>
                </p>
                <p className="details-text">
                  Like other AI tools in widespread use, YoueAI is designed to
                  make your life easier. It’s been programmed with far more data
                  about careers than any member could easily access. And it
                  knows you as an individual through a persona created during
                  your onboarding. This is invaluable in getting personalised,
                  relevant guidance that is unique to you.
                </p>
                <p className="details-text">
                  YoueAI can never tell you what to do or what career to follow
                  – that is always entirely your decision. What it can do is
                  help by showing options that may be relevant to you, and
                  provide helpful action plans to explore and pursue as you gain
                  clarity in your career goals.
                </p>
              </Grid>
              <Grid item xs={6}>
                <img
                  src={momentumImg}
                  alt=""
                  className="img-fluid momentum-top-img"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="career-infographic-section">
          <div className="container p-0">
            <h2 className="section-title">
              <span className="d-block">Ready to design your</span>
              perfect career? Try Youe.
            </h2>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <p className="details-text mb-2">Premium plan – AU$5/mo</p>
                <p className="details-text mb-3">
                  billed at <span className="throughline">AU$99</span> AU$49/mo,
                  billed annually
                </p>
                <ul className="check-ullist pe-3">
                  <li className="details-text">
                    Create ambitious goals, set milestones, create actions,
                    identify critical enablers and track your progress.
                  </li>
                  <li className="details-text">
                    See your personalised roadmap.
                  </li>
                  <li className="details-text">
                    Use AI-backed guidance to help nudge you to your next
                    milestone and identify the key steps you need to take.
                  </li>
                  <li className="details-text">
                    Get extra support and empower others through support and
                    feedback.
                  </li>
                  <li className="details-text">
                    Join in and expand your career planning know-how through our
                    knowledge space.
                  </li>
                </ul>
                <div className="text-end career-path-button">
                  <button className="join-btn">Get Started</button>
                </div>
              </Grid>
            </Grid>
          </div>
          <img
            src={careerRightImg}
            alt=""
            className="img-fluid career-right-img"
          />
        </div>
        <div className="security-section">
          <div className="container p-0">
            <h2 className="section-title">We care about security</h2>
            <div className="security-bg-grid">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className="flex-box">
                    <img
                      src={fingerPrintImg}
                      alt=""
                      className="img-fluid box-icon"
                    />
                    <div>
                      <h3 className="md-title">
                        We’re 100% committed to your privacy and the security of
                        your personal data{" "}
                      </h3>
                      <p className="details-text">
                        We will never hand-over your data to any third party –
                        we’re committed to GDPR (General Data Protection
                        Regulation) compliance across our website.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-box">
                    <img src={eyeImg} alt="" className="img-fluid box-icon" />
                    <div>
                      <h3 className="md-title">
                        As a member, you’ll enjoy whatever level of privacy and
                        anonymity you choose
                      </h3>
                      <p className="details-text">
                        You’re always in complete control of the level of
                        information you share, whether that is your identity,
                        CV, job history or potential career.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="faq-section">
          <div className="container p-0">
            <h2 className="section-title">Questions before joining?</h2>
            <Collapse
              accordion
              items={items}
              defaultActiveKey={["1"]}
              // onChange={onChange}
              className="faq-wrapper"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Icon
                    icon={"majesticons:chevron-up"}
                    width="20px"
                    height="20px"
                  />
                ) : (
                  <Icon
                    icon={"majesticons:chevron-down"}
                    width="20px"
                    height="20px"
                  />
                )
              }
              expandIconPosition="right"
              icon
            />
            <div className="text-center">
              <button class="landing-btn login-btn">
                Got a question? Contact us
              </button>
            </div>
          </div>
        </div>
        <LandingPageFooter />
      </div>
    </>
  );
};

export default LandingPage;
