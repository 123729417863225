import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { Icon } from "@iconify/react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import Calender from "../SVG/calendar-02-2-light.svg";
import CustomSideSection from "../CustomComponents/CustomSideSection";
import BottomBtnFooter from "../CustomComponents/BottomBtnFooter";
import { useStepContext } from "./Context/activeStepContext";
import { useProfileContext } from "./Context/profileSetupContext";
import { useLoadingContext } from "./Context/loadingContext";
import { useStorageContext } from "./Context/storageContext";

const ProfileSetup = () => {
  const { storageDetail } = useStorageContext();
  const { profileData, setProfileData } = useProfileContext();
  const [isFocus, setFocus] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    birth_year: "",
  });
  const [open, setOpen] = useState(false);

  const minDate = moment().subtract(65, "years");
  const minYear = dayjs(minDate).format("YYYY");
  const { step, setStep } = useStepContext();
  const { setIsLoading } = useLoadingContext();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "First name must contain at least one alphabet character"
      )
      .test(
        "no-leading-trailing-spaces",
        "Initial and trailing spaces are not allowed",
        (value) => !/^\s|\s$/.test(value)
      )
      .min(1, "First name must contain at least one character")
      .max(35, "Maximum 35 characters allowed"),
    last_name: Yup.string()
      .required("Last name is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Last name must contain at least one alphabet character"
      )
      .test(
        "no-leading-trailing-spaces",
        "Initial and trailing spaces are not allowed",
        (value) => !/^\s|\s$/.test(value)
      )
      .min(1, "Last name must contain at least one character")
      .max(35, "Maximum 35 characters allowed"),
    gender: Yup.string()
      .required("Gender is required")
      .notOneOf(["Select Gender"], "Please select a gender"),
    birth_year: Yup.string().required("Birth year is required"),
  });

  useEffect(() => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}onboarding/setup-profile/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setProfileData(response.response);
          reset({
            first_name: response?.response?.first_name || "",
            last_name: response?.response?.last_name || "",
            gender: response?.response?.gender || "Select Gender",
            birth_year: response?.response?.birth_year || "",
          });
        } else {
        }
      });
    }
  }, []);

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: profileData?.first_name || "",
      last_name: profileData?.last_name || "",
      gender: profileData?.gender || "Select Gender",
      birth_year: profileData?.birth_year || "",
    },
  });
  const onSubmit = (data) => {
    setProfileData({
      ...data,
      birth_year: dayjs(data?.birth_year).format("YYYY"),
    });
    setIsLoading(true);
    postMethod(`${process.env.REACT_APP_API_URL}onboarding/setup-profile/`, {
      user_id: storageDetail?.user_id,
      birth_year: parseInt(data.birth_year),
      ...data,
    }).then((response) => {
      if (response.status === "S") {
        localStorage.setItem("profile_id", response.response.id);
        setIsLoading(false);
        setStep((prevStep) => prevStep + 1);
      } else {
      }
    });
  };

  const checkingAll = () => {
    const values = profileData;
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && !values[key]) {
        return true;
      }
    }
    return false;
  };

  const disableDate = (current) => {
    const currentDate = moment();
    const maxBirthYear = moment(currentDate).subtract(18, "years");
    const minBirthYear = moment(currentDate).subtract(65, "years");

    return current < minBirthYear || current > maxBirthYear;
  };

  const gender = ["female", "male", "others"];

  return (
    <>
      <div className="section_wrapper">
        <CustomSideSection step={step} />
        <div className="user_from_data height_auto">
          <div className="career_stream_info">
            <div className="signin-con">
              <div className="heading">
                <h2>Let's Get to Know You</h2>
              </div>
              {!checkingAll() && (
                <div className="entries-appear-to-be-incorrect">
                  Your entries appear to be incorrect. Please review and update
                  the form.
                </div>
              )}
              <div className="signin-divide">
                <div className="signin-field-label">
                  <label>First Name</label>
                  <Controller
                    control={control}
                    name="first_name"
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <input
                          placeholder="John"
                          type="text"
                          value={value}
                          name={name}
                          onBlur={() => {
                            setFocus(false);
                          }}
                          onChange={({ target: { value } }) => {
                            setValue(name, value, { shouldValidate: true });
                            setFocus((prev) => ({ ...prev, [name]: true }));
                          }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none input-design-signin"
                                : "outline-failure input-design-signin"
                              : value && isFocus?.first_name
                              ? "outline-success input-design-signin"
                              : "input-design-signin"
                          }
                        ></input>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="signin-field-label">
                  <label>Last Name</label>
                  <Controller
                    control={control}
                    name="last_name"
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <input
                          placeholder="John"
                          type="text"
                          name={name}
                          value={value}
                          onBlur={() => {
                            setFocus(false);
                          }}
                          onChange={({ target: { value } }) => {
                            setValue(name, value, { shouldValidate: true });
                            setFocus((prev) => ({ ...prev, [name]: true }));
                          }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none input-design-signin"
                                : "outline-failure input-design-signin"
                              : value && isFocus?.last_name
                              ? "outline-success input-design-signin"
                              : "input-design-signin"
                          }
                        ></input>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="signin-field-label">
                  <label>Gender</label>
                  <Controller
                    control={control}
                    name="gender"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.gender
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            setOpen(!open);
                          }}
                          suffixIcon={
                            <Icon
                              icon={
                                open
                                  ? "iconamoon:arrow-up-2"
                                  : "iconamoon:arrow-down-2"
                              }
                              className={
                                open
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Gender"
                          placement="bottomLeft"
                          options={gender.map((gen) => ({
                            label: gen.replace(/\b\w/g, (l) => l.toUpperCase()),
                            value: gen,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                          }}
                        ></Select>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="signin-field-label">
                  <label>Birth Year</label>
                  <Controller
                    name="birth_year"
                    control={control}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <div hidden={false}>
                          <DatePicker
                            format="YYYY"
                            defaultPickerValue={
                              profileData?.birth_year
                                ? dayjs(profileData?.birth_year, "YYYY")
                                : dayjs(minYear, "YYYY")
                            }
                            // value={
                            //   profileData?.birth_year
                            //     ? dayjs(profileData?.birth_year, "YYYY")
                            //     : undefined
                            // }
                            value={value ? dayjs(value, "YYYY") : undefined}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none datePick"
                                  : "outline-failure datePick"
                                : value && isFocus?.birth_year
                                ? "outline-success datePick"
                                : "datePick"
                            }
                            onBlur={() => {
                              setFocus(false);
                            }}
                            disabledDate={disableDate}
                            allowClear={false}
                            onChange={(datevalue) => {
                              setValue(name, datevalue.format("YYYY"), {
                                shouldValidate: true,
                              });
                              setFocus((prev) => ({ ...prev, [name]: true }));
                            }}
                            picker="year"
                            placeholder="YYYY"
                            id="1"
                            suffixIcon={
                              <span className="icon-img">
                                <img
                                  src={Calender}
                                  className="calender_icon"
                                  alt=""
                                />
                              </span>
                            }
                          />
                        </div>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <button className="primary_btn mobile_elem">Continue</button>
              </div>
            </div>
          </div>
          <BottomBtnFooter onSubmit={handleSubmit(onSubmit)} />
        </div>
      </div>
    </>
  );
};

export default ProfileSetup;
