import React, { useState, useEffect } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import SingleMilestone from "./SingleMilestone";
import { Button, Dropdown, Space } from "antd";
import FooterButton from "../profile/FooterButton";
import { ReactComponent as PlusIcon } from "../../../assets/images/plus-black.svg";
import { ReactComponent as FlagBlack } from "../../../assets/images/flag-black.svg";
import { ReactComponent as CheckInIcon } from "../../../assets/images/location-check-in-icon.svg";
import { Grid } from "@mui/material";
import AddMilestoneModal from "./nodemapModals/AddMilestoneModal";
import AddIntermediateRoleModal from "./nodemapModals/AddIntermediateRoleModal";
import { getMethod } from "../../Api'sCall/Apicalling";
import AddTargetedRoleModal from "./nodemapModals/AddTargetedRoleModal";
import "../../CSS/nodeMap.css";
import { useUserBasicData } from "../Context/basicDetails";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const NodeMap = () => {
  const { storageDetail } = useStorageContext();
  const user_id = storageDetail?.user_id;
  const [isAddMilestoneModalOpen, setIsAddMilestoneModalOpen] = useState(false);
  const [isAddIntermediateRoleModalOpen, setIsAddIntermediateRoleModalOpen] =
    useState(false);
  const [isAddTargetedRoleModalOpen, setIsAddTargetedRoleModalOpen] =
    useState(false);
  const [nodemapData, setNodemapData] = useState([]);
  const [nodemapDataId, setNodemapDataId] = useState("");
  const [uniqueYears, setUniqueYears] = useState([]);
  const [intermediateRolesData, setIntermediateRolesData] = useState([]);
  const [milestoneRolesData, setMilestoneRolesData] = useState([]);
  const [currentRole, setCurrentRole] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [milestoneModalData, setMilestoneModalData] = useState();
  const [intermediateModalData, setIntermediateModalData] = useState(null);
  const [targetModalData, setTargetModalData] = useState(null);
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const { userBasicData } = useUserBasicData();

  const handleCommingSoon = () => {
    setComingSoonVisible(true);
  };
  const handleRemoveCommingSoon = () => {
    setComingSoonVisible(false);
  };

  const currentDate = moment().format("dddd, MMMM D");
  const date = moment(
    nodemapData[0] && nodemapData[0][0]?.start_date,
    "DD/MM/YYYY"
  ).format("YYYY-MM-DD");
  const date1 = moment(
    nodemapData[nodemapData.length - 1] &&
      nodemapData[nodemapData.length - 1][0]?.end_date,
    "DD/MM/YYYY"
  ).format("YYYY-MM-DD");
  const formattedDate = moment(date).format("DD MMM");
  const formattedCurrentRoleDate = moment(date).format("DD/MM/YYYY");
  const formattedNorthStarDate = moment(date1).format("DD/MM/YYYY");
  const year = moment(
    nodemapData[0] && nodemapData[0][0]?.start_date,
    "DD/MM/YYYY"
  ).format("YYYY");

  const getNodemapDataList = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        let res = Object.values(
          response?.response?.nodemap.reduce((acc, cur) => {
            if (!acc[cur?.end_date]) {
              acc[cur?.end_date] = [{ ...cur }];
            } else {
              acc[cur?.end_date].push(cur);
            }
            return acc;
          }, {})
        );
        setNodemapData(res);
        setNodemapDataId(response.response.nodemap_id);
      } else {
      }
    });
  };

  useEffect(() => {
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          let res = Object.values(
            response?.response?.nodemap.reduce((acc, cur) => {
              if (!acc[cur?.end_date]) {
                acc[cur?.end_date] = [{ ...cur }];
              } else {
                acc[cur?.end_date].push(cur);
              }
              return acc;
            }, {})
          );
          setNodemapData(res);
          if (response?.response?.nodemap_id) {
            setNodemapDataId(response?.response?.nodemap_id);
          }
        } else {
        }
      });
      getMethod(
        `${process.env.REACT_APP_API_URL}onboarding/current-role/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setCurrentRole(response.response.current_role.role);
        } else {
        }
      });
      // getMethod(
      //   `${process.env.REACT_APP_API_URL}user/basic-details/?id=${user_id}`
      // ).then((response) => {
      //   if (response.status === "S") {
      //     setProfileDashboardData(response.response);
      //   } else {
      //   }
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const items = [
    {
      label: "Milestone",
      key: "0",
    },
    {
      label: "Intermediate Role",
      key: "1",
    },
  ];

  useEffect(() => {
    extractUniqueYears();
    extractAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodemapData]);

  const extractUniqueYears = () => {
    if (nodemapData.length > 0) {
      const yearsArray = [];
      nodemapData.forEach((items) =>
        items.forEach((item, index) => {
          const endYear = item.end_date.match(/\d{4}/);
          if (endYear) {
            const year = endYear[0];
            !index && yearsArray.push(year);
            // if (!yearsArray.includes(year)) {
            //   yearsArray.push(year);
            // } else {
            //   yearsArray.push("");
            // }
          }
        })
      );
      setUniqueYears(yearsArray);
    }
  };

  const extractAllRoles = () => {
    if (nodemapData.length > 0) {
      const intermediateRolesArray = [];
      const milestoneRolesArray = [];
      nodemapData.forEach((items) =>
        items.forEach((item, index) => {
          if (item?.type === "intermediate_role") {
            intermediateRolesArray.push(item?.title);
          } else if (item?.type === "milestone") {
            milestoneRolesArray.push(item?.title);
          }
        })
      );
      setIntermediateRolesData(intermediateRolesArray);
      setMilestoneRolesData(milestoneRolesArray);
    }
  };

  const handleOpenModal = (key) => {
    setIsAdd(true);
    switch (key) {
      case "0":
        setIsAddMilestoneModalOpen(true);
        break;
      case "1":
        setIsAddIntermediateRoleModalOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <AddMilestoneModal
        isAdd={isAdd}
        isOpen={isAddMilestoneModalOpen}
        handleModalOpenClose={setIsAddMilestoneModalOpen}
        setNodemapData={setNodemapData}
        nodemapId={nodemapDataId}
        modalData={milestoneModalData}
        haveMilestoneNumber={
          milestoneRolesData.includes(milestoneModalData?.title)
            ? milestoneRolesData.findIndex(
                (elm) => elm === milestoneModalData?.title
              )
            : false
        }
      />
      <AddIntermediateRoleModal
        isAdd={isAdd}
        isOpen={isAddIntermediateRoleModalOpen}
        handleModalOpenClose={setIsAddIntermediateRoleModalOpen}
        setNodemapData={setNodemapData}
        nodemapId={nodemapDataId}
        modalData={intermediateModalData}
        formattedCurrentRoleDate={formattedCurrentRoleDate}
        formattedNorthStarDate={formattedNorthStarDate}
        haveIntermediateNumber={
          intermediateRolesData.includes(intermediateModalData?.title)
            ? intermediateRolesData.findIndex(
                (elm) => elm === intermediateModalData?.title
              )
            : false
        }
      />
      <AddTargetedRoleModal
        isAdd={isAdd}
        isOpen={isAddTargetedRoleModalOpen}
        handleModalOpenClose={setIsAddTargetedRoleModalOpen}
        setNodemapData={setNodemapData}
        nodemapId={nodemapDataId}
        modalData={targetModalData}
      />

      {nodemapData.length > 0 && (
        <div className="main_wrapper">
          <div className="container">
            <div className="nodemap-user-wrapper fixed-user-navbar">
              <Grid container spacing={2} justifyContent={"flex-end"}>
                <Grid item>
                  <div
                    className="main-button-wrapper"
                    onMouseEnter={handleCommingSoon}
                    onMouseLeave={handleRemoveCommingSoon}
                  >
                    {comingSoonVisible && (
                      <div className="comming-soon-nudge comming-soon-main">
                        Coming Soon
                      </div>
                    )}
                    <Button className="nodemap-flag-btn-top">
                      <span className="main-flag">
                        <FlagBlack />
                        Main
                      </span>
                      02
                      <PlusIcon className="plus-black-icon" />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="nodemap-user-wrapper nodemap-user-margin">
              <Grid container spacing={2}>
                <Grid item>
                  <div className="user-info-wrap">
                    {userBasicData?.first_name && userBasicData?.last_name && (
                      <h1 className="nodemap-user-name">
                        Hello, {userBasicData?.first_name}{" "}
                        {userBasicData?.last_name}
                      </h1>
                    )}

                    <p className="nodemap-user-time">{currentDate}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="developers-main-wrapper">
              <div className="developer-nodemap">
                <div className="nodemap-option-wrpper">
                  <div
                    className={`nodemap-option-icon-wrap ${
                      nodemapData[0][0]?.progress === 100 ? "completed" : ""
                    }`}
                  >
                    <p className="targeted-role start-role">
                      {currentRole && currentRole}
                    </p>
                    <div className="nodemap-option-icon rectangle-shape location-icon">
                      <CheckInIcon />
                    </div>
                  </div>
                  <div className="change-year-collapse">
                    <p className="year-title">{year && year}</p>
                    <div className="nodemap-collapse-wrpper">
                      <p className="map-date">
                        {formattedDate && formattedDate}
                      </p>
                      <div className="custom-accordion-wrapper">
                        <div className="collapse-item">
                          <div className="collapse-icon rectangle-shape">
                            <CheckInIcon />
                          </div>
                          <p> {currentRole && currentRole}</p>
                        </div>
                        <div className="custom-accordion-details"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {nodemapData &&
                  nodemapData.map((data, index) => {
                    return (
                      <>
                        <SingleMilestone
                          targetRole={
                            nodemapData[nodemapData.length - 1][0]?.title
                          }
                          setIsAdd={setIsAdd}
                          isAdd={isAdd}
                          setIsAddIntermediateRoleModalOpen={
                            setIsAddIntermediateRoleModalOpen
                          }
                          setIsAddMilestoneModalOpen={
                            setIsAddMilestoneModalOpen
                          }
                          setIsAddTargetedRoleModalOpen={
                            setIsAddTargetedRoleModalOpen
                          }
                          type={data[0].type}
                          nodemapData={data}
                          mainData={nodemapData}
                          nodemapDataId={nodemapDataId}
                          getNodemapDataList={getNodemapDataList}
                          uniqueYears={uniqueYears[index]}
                          // uniqueYears={
                          //   year === uniqueYears[index]
                          //     ? ""
                          //     : uniqueYears[index]
                          // }
                          currentRoleYear={year}
                          currentIndex={index}
                          allUniqueYears={uniqueYears}
                          milestoneRolesData={milestoneRolesData}
                          intermediateRolesData={intermediateRolesData}
                          index={index}
                          setMilestoneModalData={setMilestoneModalData}
                          setIntermediateModalData={setIntermediateModalData}
                          setTargetModalData={setTargetModalData}
                        />
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="add-more-wrap add-milestone-role">
              <Dropdown
                menu={{
                  items,
                  onClick: ({ key }) => handleOpenModal(key),
                }}
                trigger={["click"]}
              >
                <button
                  className="add-more-btn"
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    <Icon icon="icon-park-outline:add" />
                    Add new Milestone/Role
                  </Space>
                </button>
              </Dropdown>
            </div>
          </div>
          <FooterButton />
        </div>
      )}
    </>
  );
};

export default NodeMap;
