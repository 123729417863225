import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Menu, MenuItem, Toolbar, Box } from "@mui/material";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { Divider, Typography } from "antd";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as Logo } from "../../assets/images/youe_logo_black.svg";
import UserIcon from "../../assets/images/user-gray.svg";
import HeaderUserBg from "../../assets/images/header-user.png";
import SignOutIcon from "../../assets/images/logout.svg";
import Settings from "../../assets/images/settings.svg";
import BankCard from "../../assets/images/bank-card-1.svg";
import { useActiveProfile } from "../../Components/Dashboard/Context/activeProfileHeader";
import { useLogoutContext } from "../BeforeLogin/Context/logoutMessageContext";
import { useUserBasicData } from "./Context/basicDetails";
import { useStorageContext } from "../BeforeLogin/Context/storageContext";

const pages = ["Roadmap", "Learn", "Communities", "Mentoring", "Coaching"];

const settingsMenu = [
  {
    key: 0,
    name: "My Profile",
    icon: UserIcon,
    addClassName: "",
  },
  {
    key: 1,
    name: "Account Settings",
    icon: Settings,
    addClassName: "",
  },
  {
    key: 2,
    name: "Billing",
    icon: BankCard,
    addClassName: "",
  },
  {
    key: 3,
    name: "",
    icon: "",
    addClassName: "",
  },
  {
    key: 4,
    name: "Sign out",
    icon: SignOutIcon,
    addClassName: "signout-button",
  },
];

const DashboardHeader = () => {
  const navigate = useNavigate();
  const { storageDetail, setStorageDetail } = useStorageContext();
  const { setActiveNav } = useActiveProfile();
  const { userBasicData, setUserBasicData } = useUserBasicData();
  const { setIsLogout } = useLogoutContext();
  const { pathname } = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [comingSoonVisible, setComingSoonVisible] = useState({});

  useEffect(() => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}account-settings/username/?id=${user_id}`
      ).then((response) => {
        if (response.status === "S") {
          setUserBasicData(response.response);
        } else {
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname === "/learn") {
      setActivePage("Learn");
    } else if (pathname === "/dashboard") {
      setActivePage("Roadmap");
    } else {
      setActivePage(null);
    }
  }, [pathname]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCommingSoon = (pageName) => {
    if (["Learn", "Communities", "Mentoring", "Coaching"].includes(pageName)) {
      setComingSoonVisible({ [pageName]: true });
    }
  };
  const handleRemoveCommingSoon = (pageName) => {
    if (["Learn", "Communities", "Mentoring", "Coaching"].includes(pageName)) {
      setComingSoonVisible({});
    }
  };
  const handleCloseNavMenu = (pageName) => {
    // if (pageName === "Learn") {
    //   navigate("/learn");
    // } else
    if (pageName === "Roadmap") {
      navigate("/dashboard");
    }
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    postMethod(`${process.env.REACT_APP_API_URL}logout/`, {
      user_id: storageDetail?.user_id,
    }).then((response) => {
      if (response.status === "S") navigate("/login");
      setIsLogout(true);
      localStorage.clear();
      setStorageDetail({ user_id: "" });
    });
  };

  const handleCloseUserMenu = (setting) => {
    if (setting && setting.name) {
      if (setting?.name !== "Sign out") {
        navigate("/profile");
        localStorage.setItem("activeNav", setting.name);
        setActiveNav(setting.name);
        setAnchorElUser(null);
      } else if (setting?.name === "Sign out") {
        // sign out
        handleLogout();
      }
    } else {
      // settings === undefined
      setAnchorElUser(null);
      setActiveNav(localStorage.getItem("activeNav") || "learn");
    }
  };

  return (
    <>
      <AppBar className="header_wrapper fixed_header_wrapper" position="static">
        <div className="container">
          <div className="profile-header">
            <Toolbar disableGutters className="headerToolbar">
              <Box sx={{ flexGrow: { md: 0, xs: 1 } }} className="logoBlack">
                <Logo />
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  // onClick={() => setAnchorElUser(null)}
                  color="black"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  className="mobile-menu-wrap"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => handleCloseNavMenu(page)}
                      className="mobile_menu"
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  display: { xs: "none", md: "flex" },
                }}
              >
                {pages.map((page, index) => (
                  <span
                    className={`header-link ${page === activePage && "active"}`}
                    key={page}
                    onClick={() => handleCloseNavMenu(page)}
                    onMouseEnter={() => handleCommingSoon(page)}
                    onMouseLeave={() => handleRemoveCommingSoon(page)}
                    sx={{ my: 2, color: "black", display: "block" }}
                  >
                    {page}
                    {comingSoonVisible[page] && (
                      <div className="comming-soon">Coming Soon</div>
                    )}
                  </span>
                ))}
              </Box>

              <Box sx={{ sx: 5 }}>
                <IconButton
                  onClick={handleOpenUserMenu}
                  // SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  sx={{ p: 0 }}
                  className="profile_menu"
                  disableRipple
                >
                  {userBasicData && (
                    <>
                      <span className="profile-name">
                        {userBasicData?.first_name}
                      </span>
                      {userBasicData?.profile_image ? (
                        <img
                          className="Rectangle2 Rectangle-profile-header-pic"
                          src={userBasicData?.profile_image}
                          alt=""
                        />
                      ) : (
                        <img
                          className="Rectangle2 Rectangle-profile-header-pic"
                          src={HeaderUserBg}
                          alt="Rectangle2"
                        />
                      )}
                    </>
                  )}
                </IconButton>
                <Menu
                  disableScrollLock={true}
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  className="settings-menu settings-dropdown-menu"
                >
                  {settingsMenu.map((menu) => {
                    if (menu.key === 3) {
                      return (
                        <MenuItem
                          key={menu.key}
                          className="settings-menu-items"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <Divider className="m-0" />
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem
                          className={`settings-menu-items ${menu.addClassName}`}
                          onClick={() => handleCloseUserMenu(menu)}
                        >
                          <Typography textAlign="center">
                            <img alt="" src={menu.icon} />
                            {menu.name}
                          </Typography>
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </Box>
            </Toolbar>
          </div>
        </div>
      </AppBar>
    </>
  );
};

export default DashboardHeader;
