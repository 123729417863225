import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BottomBtnFooter from "../CustomComponents/BottomBtnFooter";
import { Icon } from "@iconify/react";
import { Select } from "antd";
import Avatar from "../Dashboard/Componenets/Avatar";
import JourneryInfo from "./JourneryInfo";
import { Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { useNavigate } from "react-router-dom";
import { useLoadingContext } from "./Context/loadingContext";
import OnbordingLoader from "./OnbordingLoader";
import { useStorageContext } from "./Context/storageContext";

const dropdownOptions = [
  "Developer",
  "Front-end Developer",
  "Senior Developer",
];

const CustomRunway = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoadingContext();
  const { storageDetail } = useStorageContext();
  const [open, setOpen] = useState(false);
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [currentRoleData, setCurrentRoleData] = useState({});
  const [targetedRoleData, setTargetedRoleData] = useState({});
  const [tempIntermediateData, setTempIntermediateData] = useState([]);
  const [isFocus, setFocus] = useState({
    basicRole: "",
    sector: "",
    company_type: "",
    function: "",
    domain: "",
    role: "",
    rank: "",
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [industryVal, setIndustryVal] = useState("");
  const [functionVal, setFunctionVal] = useState("");
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [rankOptions, setRankOptions] = useState([]);
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [progress, setProgress] = useState(3);
  const [responseError, setResponseError] = useState("");
  const [selectOpenState, setSelectOpenState] = useState({
    sector: false,
    company_type: false,
    function: false,
    domain: false,
    rank: false,
  });

  const validationSchema = Yup.object().shape({
    basicRole: Yup.string().required("Role is required"),
    sector: Yup.string()
      .required("Industry is required")
      .notOneOf(["Select Industry"], "Please select a Industry")
      .nullable(),
    company_type: Yup.string()
      .required("Company Type is required")
      .notOneOf(["Select Company Type"], "Please select a company type")
      .nullable(),
    function: Yup.string()
      .required("Function is required")
      .notOneOf(["Select Function"], "Please select a function")
      .nullable(),
    domain: Yup.string()
      .required("Domain is required")
      .notOneOf(["Select Domain"], "Please select a domain")
      .nullable(),
    rank: Yup.string()
      .required("Seniority is required")
      .notOneOf(["Select Seniority"], "Please select a seniority")
      .nullable(),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sector: null, // "Select Sector",
      company_type: null, // "Select Company Type",
      function: null, // "Select Function",
      domain: null, // "Select Role",
      rank: null, // "Select Seniority"
    },
  });

  const watchAllFields = watch();

  const toggleSelectOpen = (name) => {
    setSelectOpenState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  let timer = {};
  useEffect(() => {
    getCurrentRoleDataById();
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getMethod(`${process.env.REACT_APP_API_URL}industries`).then((response) => {
      if (response.status === "S") {
        setIndustryOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}functions`).then((response) => {
      if (response.status === "S") {
        setFunctionOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryVal) {
      //make it dynamic once get the data now passing Technology
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}industries/?q=${industryVal}`
      ).then((response) => {
        if (response.status === "S") {
          setCompanyTypeOptions(response?.response?.result?.company_type);
          setFunctionOptions(response?.response?.result?.functions);
          setIsLoading(false);
        } else {
          setCompanyTypeOptions([]);
          setFunctionOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryVal]);

  useEffect(() => {
    if (functionVal) {
      //make it dynamic once get the data now passing IT
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}functions/?q=${functionVal}`
      ).then((response) => {
        if (response.status === "S") {
          setDomainsOptions(response?.response?.result?.domains);
          setRankOptions(response?.response?.result?.rank);
          setIsLoading(false);
        } else {
          setDomainsOptions([]);
          setRankOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionVal]);

  const getCurrentRoleDataById = () => {
    const userId = storageDetail?.user_id || "";
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/current-role/${userId}`
    ).then((response) => {
      setCurrentRoleData(response?.response?.current_role);
      // setCustomRunWayData(response?.response?.selected_user_journey);
    });
  };

  const checkingAll = () => {
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    }
    return false;
  };

  const handleDropClick = () => {
    setIsDropOpen((prevState) => !prevState);
  };

  const onSubmit = (data) => {
    setTargetedRoleData({
      role: data?.basicRole,
      sector: data?.sector || "",
      function: data?.function,
      domain: data?.domain,
      company_type: data?.company_type,
      rank: data?.rank,
    });
    setIsLoading(true);
    postMethod(
      `${process.env.REACT_APP_API_URL}onboarding/check-intermediate/`,
      {
        user_id: storageDetail?.user_id,
        targeted_role: {
          role: data?.basicRole,
          sector: data?.sector || "",
          function: data?.function,
          domain: data?.domain,
          company_type: data?.company_type,
          rank: data?.rank,
        },
      }
    ).then((response) => {
      if (response.status === "S") {
        setFunctionVal("");
        setIndustryVal("");
        setCompanyTypeOptions([]);
        setDomainsOptions([]);
        setRankOptions([]);
        setTempIntermediateData(response?.response?.intermediate_roles || []);
        reset({
          basicRole: "",
          sector: "Select Industry",
          function: "Select Function",
          domain: "Select Domain",
          company_type: "Select Company Type",
          rank: "Select Seniority",
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleSubmitCustomRunway = () => {
    if (targetedRoleData?.role) {
      setIsLoaderOn(true);
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(timer);
            return 94;
          }
          return prevProgress + 3;
        });
      }, 1900);
      try {
        postMethod(
          `${process.env.REACT_APP_API_URL}onboarding/custom-runway/`,
          {
            user_id: storageDetail?.user_id,
            custom_runway: true,
            selected_user_journey: {
              current_role: currentRoleData,
              intermediate_roles: tempIntermediateData,
              targeted_role: targetedRoleData,
            },
          }
        )
          .then((response) => {
            if (response.status === "S") {
              setIsLoaderOn(false);
              setProgress(100);
              setResponseError("");
              navigate("/dashboard");
            } else {
              setIsLoaderOn(false);
              setResponseError("Something went wrong");
            }
          })
          .catch((err) => {
            setIsLoaderOn(false);
          });
      } catch (error) {}
    }
  };
  return (
    <>
      {isLoaderOn ? (
        <OnbordingLoader progress={progress} responseError={responseError} />
      ) : (
        <div className="journery_pannel custome_runway">
          <div className="text-center">
            <h2 className="heading_text">
              Create a customer career runway journey
            </h2>
          </div>
          <div className="custome_runway_block">
            <Grid container spacing={{ md: 6, xs: 3 }}>
              <Grid item sm={6} xs={12}>
                <div className="setup_runway">
                  <h3>Setup a custom runway</h3>
                  <div className="form-block">
                    <label htmlFor="">Next Role</label>
                    <Controller
                      control={control}
                      name="basicRole"
                      // defaultValue={""}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <input
                            placeholder="Developer"
                            type="text"
                            // defaultValue={profileData?.basicRole || undefined}
                            value={value}
                            name={name}
                            onBlur={() => {
                              setFocus(false);
                            }}
                            onChange={({ target: { value } }) => {
                              setValue(name, value, { shouldValidate: true });
                              setFocus((prev) => ({ ...prev, [name]: true }));
                            }}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none input-design-signin"
                                  : "outline-failure input-design common_focus"
                                : value && isFocus?.basicRole
                                ? "input-design common_focus outline-success input-design-signin"
                                : "input-design common_focus"
                            }
                            // className="outline-failure"
                          ></input>
                          {checkingAll() && (
                            <div className="error-custom-signin">
                              {error?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-block">
                    <label htmlFor="">Industry</label>
                    <Controller
                      control={control}
                      name="sector"
                      value={watchAllFields?.sector}
                      rules={{
                        required: true,
                        message: "required",
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // defaultValue={"Select Function"}
                            value={watchAllFields?.sector}
                            sx={{ m: 1, width: "4.4cm", height: "35px" }}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none select-design-signin-ant"
                                  : "outline-failure select-design-signin-ant"
                                : value && isFocus?.sector
                                ? "outline-success select-design-signin-ant"
                                : "select-design-signin-ant"
                            }
                            popupClassName="custom-select-dropdown"
                            error={error?.message}
                            onDropdownVisibleChange={() => {
                              toggleSelectOpen("sector");
                            }}
                            suffixIcon={
                              <Icon
                                icon={
                                  open
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  open
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            placeholder="Select Industry"
                            placement="bottomLeft"
                            // options={dropdownOptions.map((sec) => ({
                            //   label: sec.replace(/\b\w/g, (l) => l.toUpperCase()),
                            //   value: sec,
                            // }))}
                            options={industryOptions.map((sec) => ({
                              label: sec,
                              value: sec,
                            }))}
                            onChange={(value) => {
                              setFocus((prev) => ({ ...prev, [name]: true }));
                              setValue(name, value, { shouldValidate: true });
                              setIndustryVal(value);
                            }}
                          ></Select>
                          {checkingAll() && (
                            <div className="error-custom-signin">
                              {error?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div
                    className={`form-block ${
                      companyTypeOptions?.length === 0 ? "is-disabled" : ""
                    }`}
                  >
                    <label htmlFor="">Company Type</label>
                    <Controller
                      control={control}
                      name="company_type"
                      value={watchAllFields?.company_type}
                      rules={{
                        required: true,
                        message: "required",
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={watchAllFields?.company_type}
                            disabled={
                              companyTypeOptions?.length === 0 ? true : false
                            }
                            sx={{ m: 1, width: "4.4cm", height: "35px" }}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none select-design-signin-ant"
                                  : "outline-failure select-design-signin-ant"
                                : value && isFocus?.company_type
                                ? "outline-success select-design-signin-ant"
                                : "select-design-signin-ant"
                            }
                            popupClassName="custom-select-dropdown"
                            error={error?.message}
                            onDropdownVisibleChange={() => {
                              toggleSelectOpen("company_type");
                            }}
                            suffixIcon={
                              <Icon
                                icon={
                                  open
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  open
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            placeholder="Select Company Type"
                            placement="bottomLeft"
                            options={companyTypeOptions?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onChange={(value) => {
                              setFocus((prev) => ({
                                ...prev,
                                [name]: true,
                              }));
                              setValue(name, value, {
                                shouldValidate: true,
                              });
                            }}
                          ></Select>
                          {checkingAll() &&
                            companyTypeOptions?.length !== 0 && (
                              <div className="error-custom-signin">
                                {error?.message}
                              </div>
                            )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-block">
                    <label htmlFor="">Function</label>
                    <Controller
                      control={control}
                      name="function"
                      value={watchAllFields?.function}
                      rules={{
                        required: true,
                        message: "required",
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={watchAllFields?.function}
                            sx={{ m: 1, width: "4.4cm", height: "35px" }}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none select-design-signin-ant"
                                  : "outline-failure select-design-signin-ant"
                                : value && isFocus?.function
                                ? "outline-success select-design-signin-ant"
                                : "select-design-signin-ant"
                            }
                            popupClassName="custom-select-dropdown"
                            error={error?.message}
                            onDropdownVisibleChange={() => {
                              toggleSelectOpen("function");
                            }}
                            suffixIcon={
                              <Icon
                                icon={
                                  open
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  open
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            placeholder="Select Function"
                            placement="bottomLeft"
                            options={functionOptions?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onChange={(value) => {
                              setFocus((prev) => ({
                                ...prev,
                                [name]: true,
                              }));
                              setValue(name, value, {
                                shouldValidate: true,
                              });
                              setFunctionVal(value);
                            }}
                          ></Select>
                          {checkingAll() && (
                            <div className="error-custom-signin">
                              {error?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div
                    className={`form-block ${
                      domainsOptions?.length === 0 ? "is-disabled" : ""
                    }`}
                  >
                    <label htmlFor="">Domain</label>
                    <Controller
                      control={control}
                      name="domain"
                      value={watchAllFields?.domain}
                      rules={{
                        required: true,
                        message: "required",
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{ m: 1, width: "4.4cm", height: "35px" }}
                            disabled={
                              domainsOptions?.length === 0 ? true : false
                            }
                            value={watchAllFields?.domain}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none select-design-signin-ant"
                                  : "outline-failure select-design-signin-ant"
                                : value && isFocus?.domain
                                ? "outline-success select-design-signin-ant"
                                : "select-design-signin-ant"
                            }
                            popupClassName="custom-select-dropdown"
                            error={error?.message}
                            onDropdownVisibleChange={() => {
                              toggleSelectOpen("domain");
                            }}
                            suffixIcon={
                              <Icon
                                icon={
                                  open
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  open
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            placeholder="Select Domain"
                            placement="bottomLeft"
                            options={domainsOptions?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onChange={(value) => {
                              setFocus((prev) => ({
                                ...prev,
                                [name]: true,
                              }));
                              setValue(name, value, {
                                shouldValidate: true,
                              });
                            }}
                          ></Select>
                          {checkingAll() && domainsOptions?.length !== 0 && (
                            <div className="error-custom-signin">
                              {error?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div
                    className={`form-block ${
                      rankOptions?.length === 0 ? "is-disabled" : ""
                    }`}
                  >
                    <label htmlFor="">Seniority</label>
                    <Controller
                      control={control}
                      name="rank"
                      value={watchAllFields?.rank}
                      rules={{
                        required: true,
                        message: "required",
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <div className="input-con-signin">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={rankOptions?.length === 0 ? true : false}
                            value={watchAllFields?.rank}
                            sx={{ m: 1, width: "4.4cm", height: "35px" }}
                            className={
                              error
                                ? !checkingAll()
                                  ? "outline-failure-shadow-none select-design-signin-ant"
                                  : "outline-failure select-design-signin-ant"
                                : value && isFocus?.rank
                                ? "outline-success select-design-signin-ant"
                                : "select-design-signin-ant"
                            }
                            popupClassName="custom-select-dropdown"
                            error={error?.message}
                            onDropdownVisibleChange={() => {
                              toggleSelectOpen("rank");
                            }}
                            suffixIcon={
                              <Icon
                                icon={
                                  open
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  open
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            placeholder="Select Seniority"
                            placement="bottomLeft"
                            options={rankOptions?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onChange={(value) => {
                              setFocus((prev) => ({
                                ...prev,
                                [name]: true,
                              }));
                              setValue(name, value, {
                                shouldValidate: true,
                              });
                            }}
                          ></Select>
                          {checkingAll() && rankOptions?.length !== 0 && (
                            <div className="error-custom-signin">
                              {error?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <button
                    className="primary_btn"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Add
                  </button>
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="avtar_pannel">
                  <div className="single_journery_block">
                    <div className="journery_thread">
                      <Avatar
                        labelText={currentRoleData?.role || "Current Role"}
                        optionalClassName="has_side_label first_label"
                      />
                      {tempIntermediateData?.length > 0 &&
                        tempIntermediateData?.map((element) => (
                          <Avatar
                            labelText={element?.role}
                            optionalClassName="has_side_label"
                          />
                        ))}
                      {!targetedRoleData?.role && (
                        <>
                          <Avatar
                            labelText="Job Position"
                            optionalClassName="has_side_label"
                          />
                          <Avatar
                            labelText="Job Position"
                            optionalClassName="has_side_label"
                          />
                        </>
                      )}
                      <div className="end_journery">
                        <Avatar
                          labelText={targetedRoleData?.role || "Targeted Role"}
                          optionalClassName="has_side_label last_label"
                          isDropOpen={isDropOpen}
                          handleDropClick={handleDropClick}
                        />
                        {isDropOpen && (
                          <JourneryInfo infoData={targetedRoleData} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <BottomBtnFooter onSubmit={() => handleSubmitCustomRunway()} />
        </div>
      )}
    </>
  );
};

export default CustomRunway;
