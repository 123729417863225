import React, { useEffect, useState } from "react";
import { OnBoardingContext } from "./Context/onBoardingContext";
import OTPDesign from "./OTPDesign";
import WelcomeField from "./WelcomeField";
import CreatePassWordField from "./CreatePasswardField";
import QuestionsOne from "./Questions/QuestionsOne";
import CareerOverview from "./CareerOverview";
import QuestionsFourNew from "./Questions/QuestionsFourNew";
import RunwayRecommendations from "./RunwayRecommendations";
import ProfileSetup from "./ProfileSetup";
import { useStepContext } from "./Context/activeStepContext";
import { useLoadingContext } from "./Context/loadingContext";
import QuestionsTwoNew from "./Questions/QuestionsTwoNew";
import QuestionsThreeNew from "./Questions/QuestionsThreeNew";
import IntermediateRole from "./Questions/IntermediateRole";
import DashboardSingleJourney from "../Dashboard/DashboardSingleJourney";
import CustomRunway from "./CustomRunway";
import { getMethod } from "../Api'sCall/Apicalling";
import { useNavigate } from "react-router-dom";
import { useStorageContext } from "./Context/storageContext";

const WelcomeStepper = () => {
  const navigate = useNavigate();
  const { storageDetail } = useStorageContext();
  const [signupMail, setsignupMail] = useState();
  const { step, setStep } = useStepContext();
  const { setIsLoading } = useLoadingContext();
  const signupStepsConfig = [
    {
      step: 1,
      component: <WelcomeField setMail={setsignupMail} />,
    },
    {
      step: 2,
      component: <OTPDesign email={signupMail} />,
    },
    {
      step: 3,
      component: <CreatePassWordField email={signupMail} />,
    },
    {
      step: 4,
      component: <ProfileSetup />,
    },
    {
      step: 5,
      component: <QuestionsOne />,
    },
    {
      step: 6,
      component: <CareerOverview />,
    },
    {
      step: 7,
      component: <QuestionsTwoNew />,
    },
    {
      step: 8,
      component: <QuestionsThreeNew />,
    },
    {
      step: 9,
      component: <IntermediateRole />,
    },
    {
      step: 10,
      component: <QuestionsFourNew />,
    },
    {
      step: 11,
      component: <RunwayRecommendations />,
    },
    {
      step: 12,
      component: <CustomRunway />,
    },
    {
      step: 13,
      component: <DashboardSingleJourney />,
    },
  ];

  const getIntermediateData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/intermediate-role/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setStep(11);
      } else {
        setStep(10);
      }
    });
  };

  const getQuestionThreeData = (redirectStep) => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === true
        ) {
          // if clear_vision ="yes"
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            if (redirectStep === 6) {
              setStep(9 + 1);
            } else {
              setStep(9);
            }
            // getIntermediateData();
          } else {
            // Q4
            setStep(10);
          }
        } else if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === false
        ) {
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            if (redirectStep === 6) {
              setStep(9 + 2);
            } else {
              setStep(9);
            }
            //check for the intermediate-role
            // getIntermediateData();
          } else {
            // if clear_vision ="no" => Runway recommendation
            setStep(11);
          }
        }
      }
    });
  };

  useEffect(() => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}onboarding-progress/${storageDetail?.user_id}/`
      ).then((response) => {
        // if (response.status === "S") {
        //   setStep(response.response.onboarding_step + 4);
        // }
        if (response.status === "S") {
          // sessionStorage.setItem("user_id", response.response.user_id);
          if (response?.response?.onboarding_status === 1) {
            // on-boarding incomplete
            // sessionStorage.setItem("user_id", response.response.user_id);
            const redirectStep = response?.response?.onboarding_step;

            [1, 2, 3, 4].includes(redirectStep) && setStep(redirectStep + 4);
            navigate("/email-signup");
            // setStep(redirectStep);
            if (redirectStep === 5 || redirectStep === 6) {
              getQuestionThreeData(redirectStep);
            } else if (redirectStep === 8) {
              setStep(11);
            } else if (redirectStep === 7) {
              setStep(redirectStep + 4);
            } else if (redirectStep === 0) {
              setStep(redirectStep + 4);
            }
          } else if (response.response.onboarding_status === 2) {
            navigate("/dashboard");
          }
        }
        setIsLoading(false);
      });
    } else {
      setStep(1);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OnBoardingContext.Provider value={{ step, setStep }}>
        {signupStepsConfig.map(
          (config) => step === config.step && config.component
        )}
      </OnBoardingContext.Provider>
    </>
  );
};

export default WelcomeStepper;
