import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Welcome from "./Components/BeforeLogin/Welcome";
import Login from "./Components/BeforeLogin/Login";
import ProfilePage from "./Components/Dashboard/profile/ProfilePage";
import DashboardSingleJourney from "./Components/Dashboard/DashboardSingleJourney";
import ResetPassword from "./Components/BeforeLogin/ResetPassword";
import CreatePassWordField from "./Components/BeforeLogin/CreatePasswardField";
import PasswordChangedPage from "./Components/BeforeLogin/PasswordChangedPage";
import CustomRunway from "./Components/BeforeLogin/CustomRunway";
import LearnPage from "./Components/Dashboard/Learn/LearnPage";
import RootProviders from "./Components/Context/RootProviders";
import Loader from "./Components/CustomComponents/Loader";
import LinkedinSuccessPage from "./Components/BeforeLogin/linkedin/LinkedinSuccessPage";
import DashboardHeader from "./Components/Dashboard/DashboardHeader";
import LandingPage from "./Components/Landing/LandingPage";
import "./App.css";

function App() {
  const { pathname } = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    const bodyTag = document.getElementsByTagName("body")[0];
    if (pathname === "/email-signup") {
      bodyTag.classList.add("signup-flow");
    } else {
      bodyTag.classList.remove("signup-flow");
    }
    setShowHeader(
      pathname === "/dashboard" ||
        pathname === "/profile" ||
        pathname === "/learn"
    );
  }, [pathname]);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <RootProviders>
        {showHeader && <DashboardHeader />}
        <Loader />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/email-signup" element={<Welcome />} />
          <Route path="/change-passward" element={<ResetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password/:id/:name"
            element={<CreatePassWordField />}
          />
          <Route
            path="/linkedin-signin/success"
            element={<LinkedinSuccessPage />}
          />
          <Route
            path="/linkedin-signup/success"
            element={<LinkedinSuccessPage />}
          />
          <Route path="/create-password" element={<CreatePassWordField />} />
          <Route
            path="/password-successfully-changed"
            element={<PasswordChangedPage />}
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/dashboard" element={<DashboardSingleJourney />} />
          <Route path="/payment/success" element={<DashboardSingleJourney />} />
          <Route path="/payment/failure" element={<DashboardSingleJourney />} />
          <Route path="/custom-runway" element={<CustomRunway />} />
          <Route path="/learn" element={<LearnPage />} />
        </Routes>
      </RootProviders>
    </div>
  );
}

export default App;
