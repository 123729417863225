import React, { useEffect, useState } from "react";
import BottomBtnFooter from "../../CustomComponents/BottomBtnFooter";
import { ReactComponent as Logo } from "../../../assets/images/youe_logo_black.svg";
import { Tooltip } from "antd";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import { useProfileContext } from "../Context/profileSetupContext";
import { useStepContext } from "../Context/activeStepContext";
import SingleSelectTag from "../../CustomComponents/SingleSelectTag";
import { useLoadingContext } from "../Context/loadingContext";
import { useStorageContext } from "../Context/storageContext";

const singleSelectOptions = ["yes", "no"];

const IntermediateRole = () => {
  const { storageDetail } = useStorageContext();
  const { setProfileData } = useProfileContext();
  const { setIsLoading } = useLoadingContext();
  const { setStep } = useStepContext();
  const [selectedTag, setSelectedTag] = useState(null);
  const [nextUserStep, setNextUserStep] = useState(null);

  const getData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/intermediate-role/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setSelectedTag(
          response.response.questionnaire.consider_intermediate_role === true
            ? "yes"
            : "no"
        );
        setProfileData(response.response.questionnaire);
      }
    });
  };

  const getTagValue = (tag) => {
    setSelectedTag(tag);
  };

  useEffect(() => {
    getData();
    getQuestionThreeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    postMethod(
      `${process.env.REACT_APP_API_URL}onboarding/intermediate-role/`,
      {
        user_id: storageDetail?.user_id,
        consider_intermediate_role: selectedTag === "yes" ? true : false,
      }
    ).then((response) => {
      if (response.status === "S") {
        setIsLoading(false);
        // setStep((prevStep) => prevStep + 1);
        setStep(nextUserStep);
      }
    });
  };

  const getQuestionThreeData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === true
        ) {
          // if clear_vision ="yes"
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Q4
            setNextUserStep(10);
          }
        } else if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === false
        ) {
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Runway
            setNextUserStep(11);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="intermediate-wrapper">
        <div className="logo-youe">
          <Logo />
        </div>
        <div className="intermediate-content-wrapper tags_blocks">
          <h4 className="star-title">
            Would you consider an{" "}
            <Tooltip
              className="tooltip-area"
              placement="top"
              rootClassName="intermediate-tooltip"
              title="An intermediate role helps develop essential skills and acquire additional experience."
            >
              <span>intermediate role</span>
            </Tooltip>{" "}
            as part of your career progression?
          </h4>
          {selectedTag && (
            <div className="text-center mx-auto">
              <SingleSelectTag
                options={singleSelectOptions}
                getTagValue={getTagValue}
                value={selectedTag}
              />
            </div>
          )}

          {/* <Space size={20} className="justify-content-center w-100">
            <Button className="small-btn primary-btn">Yes</Button>
            <Button className="small-btn">No</Button>
          </Space> */}
        </div>
        <BottomBtnFooter
          onSubmit={onSubmit}
          providedPrevStep={8} // QuestionThree
        />
      </div>
    </>
  );
};

export default IntermediateRole;
