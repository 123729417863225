import React, { createContext, useContext, useState } from "react";

const UserBasicData = createContext();

export function BasicDataProvider({ children }) {
  const [userBasicData, setUserBasicData] = useState({});

  return (
    <UserBasicData.Provider value={{ userBasicData, setUserBasicData }}>
      {children}
    </UserBasicData.Provider>
  );
}

export function useUserBasicData() {
  return useContext(UserBasicData);
}
