import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Input, Select, DatePicker, Space } from "antd";
import { ReactComponent as FlagIcon } from "../../../../assets/images/flag.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/user.svg";
import { useFieldArray } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import "./nodemapModals.css";
import { patchTripdata, postMethod } from "../../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";
import ConfirmActionAlignmentModal from "./ConfirmActionAlignmentModal";
import dayjs from "dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;

const EditMilestoneActionsModal = ({
  isOpen,
  handleModalOpenClose,
  nodemapData,
  nodemapDataId,
  getNodemapDataList,
  getActionColor,
  index,
  haveMilestoneNumber,
  haveIntermediateNumber,
}) => {
  const [tempAddActionDataValues, setTempAddActionDataValues] = useState();
  const [tempEditActionDataValues, setTempEditActionDataValues] = useState();
  const [notAllignedTitleArray, setNotAllignedTitleArray] = useState([]);
  const [allActionsData, setAllActionsData] = useState([]);
  const [
    isConfirmActionAlignmentModalOpen,
    setIsConfirmActionAlignmentModalOpen,
  ] = useState(false);
  const [isAddAction, setIsAddAction] = useState(false);
  const { setIsLoading } = useLoadingContext();

  const validationSchema = Yup.object().shape({
    actions: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .required("Title")
          .trim()
          .matches(
            /[a-zA-Z]+/,
            "Title must contain at least one alphabet character"
          )
          .min(1, "Minimum 1 character required")
          .max(150, "Maximum 150 characters allowed"),
        date: Yup.array()
          .required("Date")
          .test("len", "Date", (value) => value && value.length === 2),
      })
    ),
  });
  const { control, handleSubmit, getFieldState, reset, formState } = useForm({
    defaultValues: {
      actions: nodemapData?.actions,
    },
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "actions",
  });

  useEffect(() => {
    reset({ actions: nodemapData?.actions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, nodemapData?.actions]);
  const handleClose = () => {
    handleModalOpenClose(false);
    reset({ actions: nodemapData?.actions });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formattedData = {
      actions: data?.actions?.map((action) =>
        action.date
          ? {
              ...action,
              start_date: dayjs(action.date[0]).format("DD/MM/YYYY"),
              end_date: dayjs(action.date[1]).format("DD/MM/YYYY"),
              milestone_id: nodemapData?.milestone_id,
            }
          : {
              ...action,
              milestone_id: nodemapData?.milestone_id,
              start_date: moment().format("DD/MM/YYYY") || "",
              end_date: moment().format("DD/MM/YYYY") || "",
            }
      ),
    };
    setAllActionsData(formattedData);
    const addDataValues = formattedData.actions.slice(
      nodemapData?.actions?.length
    );
    const editDataValues = formattedData.actions.slice(
      0,
      nodemapData?.actions?.length
    );
    const editedData = [];
    const editedTitleData = [];
    let isTitleEdites = false;
    editDataValues.forEach((itm, index) => {
      if (
        getFieldState(`actions[${index}].title`).isDirty ||
        getFieldState(`actions[${index}].date`).isDirty ||
        getFieldState(`actions[${index}].status`).isDirty
      ) {
        editedData.push(itm);
        if (getFieldState(`actions[${index}].title`).isDirty) {
          isTitleEdites = true;
        }
      }
      if (getFieldState(`actions[${index}].title`).isDirty) {
        editedTitleData.push(itm);
      }
    });
    if (isAddAction) {
      postMethod(
        `${process.env.REACT_APP_API_URL}nodemap/add-action/validate-title/`,
        {
          id: nodemapDataId || "",
          action_data: addDataValues,
        }
      )
        .then((response) => {
          if (response.status === "S") {
            const isActionNotAligned = response.response.response.findIndex(
              (item) => item.matching !== "yes"
            );
            if (isActionNotAligned !== -1) {
              response.response.response.forEach((item) => {
                if (item?.matching !== "yes") {
                  setNotAllignedTitleArray((current) => [
                    ...current,
                    item?.title,
                  ]);
                }
              });
              setTempAddActionDataValues(addDataValues);
              setIsConfirmActionAlignmentModalOpen(true);
              setIsLoading(false);
            } else {
              postMethod(
                `${process.env.REACT_APP_API_URL}nodemap/add-action/`,
                {
                  id: nodemapDataId || "",
                  action_data: addDataValues,
                }
              ).then((response) => {
                if (response.status === "S") {
                  getNodemapDataList();
                  setIsAddAction(false);
                  setIsLoading(false);
                  handleClose();
                } else {
                  remove(fields?.length - 1);
                  setIsLoading(false);
                }
              });
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else {
      if (editedData?.length > 0) {
        if (isTitleEdites) {
          postMethod(
            `${process.env.REACT_APP_API_URL}nodemap/edit-action/validate-title/`,
            {
              id: nodemapDataId || "",
              action_data: editedTitleData,
            }
          )
            .then((response) => {
              if (response.status === "S") {
                const isActionNotAligned = response.response.response.findIndex(
                  (item) => item.matching !== "yes"
                );
                if (isActionNotAligned !== -1) {
                  response.response.response.forEach((item) => {
                    if (item?.matching !== "yes") {
                      setNotAllignedTitleArray((current) => [
                        ...current,
                        item?.title,
                      ]);
                    }
                  });
                  setTempEditActionDataValues(editedData);
                  setIsConfirmActionAlignmentModalOpen(true);
                  setIsLoading(false);
                } else {
                  editFlowForActions(editedData);
                }
              } else {
                setIsLoading(false);
              }
            })
            .catch((err) => setIsLoading(false));
        } else {
          editFlowForActions(editedData);
        }
      } else {
        handleClose();
        setIsLoading(false);
      }
    }
  };

  const editFlowForActions = (editedData) => {
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/edit-action/${
        nodemapDataId || ""
      }/`,
      {
        action_data: editedData,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          getNodemapDataList();
          setIsLoading(false);
          handleClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const items = [
    {
      key: 0,
      title: "Yet to start",
      value: "Yet to start",
      icon: "entypo:circle",
    },
    {
      key: 1,
      title: "In Progress",
      value: "In Progress",
      icon: "fluent:more-circle-32-regular",
    },
    {
      key: 2,
      title: "Completed",
      value: "Completed",
      icon: "icon-park-outline:check-one",
    },
  ];

  const disabledDates = (current) => {
    let customDate = moment(
      `${moment(nodemapData?.start_date, "DD/MM/YYYY").format("YYYY")}-${moment(
        nodemapData?.start_date,
        "DD/MM/YYYY"
      ).format("M")}-${moment(nodemapData?.start_date, "DD/MM/YYYY").format(
        "D"
      )}`
    ).format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const renderNewFileds = () => {
    return fields.map((action, index) => (
      <Grid item xs={12} key={action.id}>
        <Grid container spacing={1}>
          <Grid item flexGrow={1}>
            <div className="edit-custom-field">
              <div
                className={`action-item-wrap ${getActionColor(index + 1)[1]}`}
              >
                <span className="">{`A${index + 1}`}</span>
              </div>
              <Controller
                name={`actions[${index}].title`}
                control={control}
                defaultValue={action?.title}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      placeholder="Action text item space"
                      className="custom_input"
                      disabled={action?.status === 2 ? true : false}
                    />
                  </>
                )}
              />
              <Controller
                name={`actions[${index}].date`}
                control={control}
                defaultValue={
                  action.start_date
                    ? [
                        dayjs(action.start_date, "DD/MM/YYYY"),
                        dayjs(action.end_date, "DD/MM/YYYY"),
                      ]
                    : [dayjs(nodemapData?.start_date, "DD/MM/YYYY")]
                }
                render={({ field }) => (
                  <>
                    <RangePicker
                      {...field}
                      format={"DD/MM/YYYY"}
                      disabledDate={disabledDates}
                      className="datepicker-btn datepicker-control"
                      placement="bottomRight"
                      rootClassName="range-datepicker"
                      disabled={
                        action?.status === 2
                          ? true
                          : [action?.status ? true : false, false]
                      }
                    />
                  </>
                )}
              />
              {errors?.actions?.[index]?.title && (
                <span className="custom-modal-error">
                  {errors?.actions?.[index]?.title.message}
                </span>
              )}{" "}
              {errors?.actions?.[index]?.title &&
                errors?.actions?.[index]?.date && (
                  <span className="custom-modal-error">&</span>
                )}{" "}
              {errors?.actions?.[index]?.date && (
                <span className="custom-modal-error">
                  {errors?.actions?.[index]?.date.message}
                </span>
              )}{" "}
              {errors?.actions?.[index] && (
                <span className="custom-modal-error">is required.</span>
              )}
            </div>
          </Grid>
          <Grid item>
            <Controller
              name={`actions[${index}].status`}
              control={control}
              render={({ field }) => (
                <Select
                  trigger={["click"]}
                  dropdownStyle={{ zIndex: 2000 }}
                  className="status-select-box"
                  rootClassName="status-select-option"
                  style={{ width: 146 }}
                  disabled={
                    action?.status === 2
                      ? true
                      : !action.action_id && isAddAction
                  }
                  placement="bottomRight"
                  {...field}
                >
                  {items.map((item) => (
                    <Option title={item.title} key={item.key} value={item.key}>
                      <Icon icon={item.icon} /> {item.value}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <div
                    className={`collapse-icon ${
                      nodemapData?.type === "targeted_role"
                        ? "star-milestone-option"
                        : ""
                    }`}
                  >
                    {nodemapData?.type === "intermediate_role" ? (
                      <UserIcon />
                    ) : nodemapData?.type === "targeted_role" ? (
                      <Icon icon="ph:star-fill" />
                    ) : (
                      <FlagIcon />
                    )}
                  </div>
                  <p>
                    Edit{" "}
                    {nodemapData?.type === "intermediate_role"
                      ? "Intermediate role"
                      : nodemapData?.type === "targeted_role"
                      ? "Targeted Role"
                      : "Milestone"}{" "}
                    {nodemapData?.type === "intermediate_role"
                      ? haveIntermediateNumber !== false
                        ? `0${haveIntermediateNumber + 1}`
                        : ""
                      : haveMilestoneNumber !== false
                      ? `0${haveMilestoneNumber + 1}`
                      : ""}{" "}
                    Actions
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Milestone Date</label>
                      <RangePicker
                        disabled
                        format={"DD/MM/YYYY"}
                        dropdownStyle={{ zIndex: 2000 }}
                        className="date-fielde text-left milestone-date-range"
                        rootClassName="range-datepicker"
                        defaultValue={
                          nodemapData?.start_date && nodemapData?.end_date
                            ? [
                                dayjs(nodemapData?.start_date, "DD/MM/YYYY"),
                                dayjs(nodemapData?.end_date, "DD/MM/YYYY"),
                              ]
                            : []
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="actions-warp">
                      <div className="action-sand-title">
                        <span>Actions</span>
                        <Icon icon="icon-park-outline:right-small-up" />
                      </div>
                      <Grid container spacing={1}>
                        {renderNewFileds()}
                        <Grid item xs={12}>
                          {fields.length < 5 ? (
                            <Button
                              className="add-more-actions"
                              onClick={() => {
                                setIsAddAction(true);
                                append({
                                  title: "",
                                  status: 0,
                                  // action_id: fields.length + 1,
                                  milestone_id: nodemapData?.milestone_id,
                                });
                              }}
                            >
                              <Icon icon="icon-park-outline:add" />
                              Add more Actions
                            </Button>
                          ) : (
                            <Button className="add-more-actions" disabled>
                              <Icon icon="icon-park-outline:add" />
                              Add more Actions
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"flex-end"}>
                <Grid item>
                  <Space size={15}>
                    <Button
                      className="small-btn text-color-grey px-20"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      className="small-btn primary-btn px-20"
                    >
                      Save
                    </Button>
                  </Space>
                </Grid>
              </Grid>
            </form>
          </Box>
          <ConfirmActionAlignmentModal
            isOpen={isConfirmActionAlignmentModalOpen}
            notAllignedTitleArray={notAllignedTitleArray}
            setNotAllignedTitleArray={setNotAllignedTitleArray}
            allActionsData={allActionsData}
            isAddAction={isAddAction}
            nodemapDataId={nodemapDataId}
            getNodemapDataList={getNodemapDataList}
            setIsAddAction={setIsAddAction}
            tempAddActionDataValues={tempAddActionDataValues}
            tempEditActionDataValues={tempEditActionDataValues}
            setIsConfirmActionAlignmentModalOpen={
              setIsConfirmActionAlignmentModalOpen
            }
            handleClosePopup={handleClose}
            type={nodemapData?.type}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditMilestoneActionsModal;
