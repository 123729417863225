import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Grid, Box, Typography, Modal } from "@mui/material";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as EditBig } from "../../../../assets/images/edit_big.svg";

import "../../../CSS/Welcome.css";
import "./modals.css";
import EmailVerificationModal from "./EmailVerificationModal";
import { postMethod } from "../../../Api'sCall/Apicalling";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const EditEmailModal = ({ getData, prevEmail, getBasiUserDetails }) => {
  const [open, setOpen] = useState(false);
  const { storageDetail } = useStorageContext();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState(prevEmail || "");
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
  };
  const handleOTPModalOpen = () => setIsOTPModalVisible(true);
  const handleOTPModalClose = () => {
    setIsOTPModalVisible(false);
    getData();
    getBasiUserDetails();
  };
  const [isFocus, setFocus] = useState(false);

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .email("Enter a valid email")
  //     .required("Email is required")
  //     .matches(emailFormat, "Please provide a valid Email"),
  // });

  const { setValue, control } = useForm({
    // resolver: yupResolver(validationSchema),
  });

  const handleChangeEmail = () => {
    const user_id = storageDetail?.user_id;
    if (!email.trim().length) {
      setErrorMessage("Email is required");
    } else if (!email.match(emailFormat)) {
      setErrorMessage("Please provide a valid Email");
    } else {
      if (user_id) {
        postMethod(
          `${process.env.REACT_APP_API_URL}account-settings/update-email/`,
          { user_id, email: email }
        ).then((response) => {
          if (response.status === "S") {
            handleClose();
            handleOTPModalOpen();
          } else {
            setErrorMessage(response?.errorData?.response?.data?.message);
          }
        });
      }
    }
  };

  return (
    <>
      <Button onClick={handleOpen} className="flied-edit-btn">
        <EditBig />
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap samll_modal">
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            className="mb-3"
          >
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                youe
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>

          {/* <form onSubmit={handleSubmit(handleChangeEmail)}> */}
          <div className="login-field change-pass-modal">
            <label className="field-label">Change Email</label>
            <Controller
              control={control}
              rules={{
                required: true,
                message: "Email is required",
              }}
              defaultValue={email}
              name="email"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error, focused },
                formState,
              }) => {
                return (
                  <div className={error ? "input-con-e" : "input-con"}>
                    <Icon
                      icon={"majesticons:mail-line"}
                      className={
                        error
                          ? "failure-icon icon"
                          : value && isFocus?.email
                          ? "sucess-icon icon"
                          : "icon"
                      }
                    />
                    <input
                      type="text"
                      name={name}
                      defaultValue={email}
                      onBlur={() => {
                        setFocus((prev) => ({ ...prev, [name]: false }));
                      }}
                      onChange={({ target: { value } }) => {
                        setErrorMessage("");
                        setEmail(value);
                        setValue(name, value, { shouldValidate: true });
                        setFocus((prev) => ({ ...prev, [name]: true }));
                      }}
                      placeholder="Enter your email address"
                      className={
                        error
                          ? "outline-failure input-design"
                          : value && isFocus?.email
                          ? "outline-success input-design"
                          : "input-design"
                      }
                    ></input>
                    <div className="error-custom-login">{error?.message}</div>
                    {errorMessage && (
                      <span className="error-msg">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />
            <Button
              className="email-signup-button primary_btn mb-0"
              type="submit"
              // htmlType="submit"
              onClick={handleChangeEmail}
            >
              Continue
            </Button>
          </div>
          {/* </form> */}
        </Box>
      </Modal>
      <EmailVerificationModal
        email={email}
        // email={formValues?.email}
        isFrom={"email"}
        isOTPModalVisible={isOTPModalVisible}
        setIsOTPModalVisible={setIsOTPModalVisible}
        handleOTPModalOpen={handleOTPModalOpen}
        handleOTPModalClose={handleOTPModalClose}
        getData={getData}
      />
    </>
  );
};

export default EditEmailModal;
