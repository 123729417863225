import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { Button, Input, DatePicker, Space, Slider } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as FlagIcon } from "../../../../assets/images/flag.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/user.svg";
import "./nodemapModals.css";
import { Controller, useForm } from "react-hook-form";
import { patchTripdata } from "../../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const EditCriticalFactorsModal = ({
  isOpen,
  handleModalOpenClose,
  nodemapData,
  nodemapDataId,
  getNodemapDataList,
  index,
  haveMilestoneNumber,
  haveIntermediateNumber,
}) => {
  const [enablerProgress, setEnablerProgress] = useState({});
  const { setIsLoading } = useLoadingContext();
  const {
    handleSubmit,
    control,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm();

  const getCriticalColor = (progress) => {
    if (progress < 25) {
      return "low-progress";
    } else if (progress < 75) {
      return "medium-progress";
    } else {
      return "high-progress";
    }
  };

  const handleClose = () => {
    handleModalOpenClose(false);
    reset();
    setEnablerProgress({ 1: 0, 2: 0, 3: 0 });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const updatedData = {
      enablers: data?.enablers
        ?.filter((item) => item !== "empty")
        .map((item, index) => ({
          ...item,
          milestone_id: nodemapData?.milestone_id,
          ce_id: index + 1,
        })),
    };
    const editedData = [];
    updatedData?.enablers.forEach((itm, index) => {
      if (
        getFieldState(`enablers[${itm?.ce_id}].title`).isDirty ||
        getFieldState(`enablers[${itm?.ce_id}].progress`).isDirty
      ) {
        editedData.push(itm);
      }
    });
    if (updatedData?.enablers) {
      if (nodemapDataId && editedData?.length > 0) {
        patchTripdata(
          `${process.env.REACT_APP_API_URL}nodemap/edit-ce/${nodemapDataId}/`,
          editedData
        ).then((response) => {
          if (response.status === "S") {
            getNodemapDataList();
            handleClose();
            setIsLoading(false);
          }
        });
      } else {
        handleClose();
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const initialEnablerProgress = {};
    nodemapData?.enablers?.forEach((item) => {
      initialEnablerProgress[item.enabler_id] = item.progress;
    });
    setEnablerProgress(initialEnablerProgress);
  }, [isOpen, nodemapData]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <div
                    className={`collapse-icon ${
                      nodemapData?.type === "targeted_role"
                        ? "star-milestone-option"
                        : ""
                    }`}
                  >
                    {nodemapData?.type === "intermediate_role" ? (
                      <UserIcon />
                    ) : nodemapData?.type === "targeted_role" ? (
                      <Icon icon="ph:star-fill" />
                    ) : (
                      <FlagIcon />
                    )}
                  </div>
                  <p>
                    Edit{" "}
                    {nodemapData?.type === "intermediate_role"
                      ? "Intermediate role"
                      : nodemapData?.type === "targeted_role"
                      ? "Targeted Role"
                      : "Milestone"}{" "}
                    {nodemapData?.type === "intermediate_role"
                      ? haveIntermediateNumber !== false
                        ? `0${haveIntermediateNumber + 1}`
                        : ""
                      : haveMilestoneNumber !== false
                      ? `0${haveMilestoneNumber + 1}`
                      : ""}{" "}
                    Critical Enablers
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Button
                  className="modal-close-btn"
                  onClick={() => handleClose()}
                >
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Milestone Date</label>
                      <RangePicker
                        disabled
                        format={"DD/MM/YYYY"}
                        dropdownStyle={{ zIndex: 2000 }}
                        className="date-fielde text-left milestone-date-range"
                        rootClassName="range-datepicker"
                        defaultValue={
                          nodemapData?.start_date && nodemapData?.end_date
                            ? [
                                dayjs(nodemapData?.start_date, "DD/MM/YYYY"),
                                dayjs(nodemapData?.end_date, "DD/MM/YYYY"),
                              ]
                            : []
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="actions-warp">
                      <div className="action-sand-title">
                        <span>Critical Enablers</span>
                        <Icon icon="icon-park-outline:right-small-up" />
                      </div>
                      <Grid container rowSpacing={2}>
                        {nodemapData?.enablers?.map((item) => (
                          <Grid item xs={12} key={item?.enabler_id}>
                            <div className="critical-factors-wrap">
                              <div className="common-progress-bar text-center">
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-flex",
                                  }}
                                >
                                  <CircularProgress
                                    variant="determinate"
                                    value={enablerProgress[item?.enabler_id]}
                                    className={`progress-circle ${getCriticalColor(
                                      enablerProgress[item?.enabler_id]
                                    )}`}
                                  />

                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    className="progress-count"
                                  >
                                    {`${Math.round(
                                      enablerProgress[item?.enabler_id]
                                    )}`}
                                  </Typography>
                                </Box>
                              </div>
                              <div>
                                <Controller
                                  name={`enablers[${item?.enabler_id}].title`}
                                  control={control}
                                  rules={{
                                    required: "Critical Factor is required",
                                    minLength: {
                                      value: 1,
                                      message: "Minimum 1 characters required",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message: "Maximum 50 characters allowed",
                                    },
                                    validate: (value) => {
                                      const regex = /[a-zA-Z]+/;
                                      if (!regex.test(value)) {
                                        return "Critical Factor must contain at least one alphabet character";
                                      }
                                      return true;
                                    },
                                  }}
                                  defaultValue={item?.title}
                                  render={({ field }) => (
                                    <Input
                                      placeholder={item?.title}
                                      className="custom_input h-40"
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.enablers &&
                                  errors.enablers[item?.enabler_id]?.title && (
                                    <p className="custom-modal-error">
                                      {
                                        errors.enablers[item?.enabler_id]?.title
                                          ?.message
                                      }
                                    </p>
                                  )}
                              </div>

                              <div className="slider-progress-wrapper">
                                {/* <Controller
                                  name={`enablers[${item?.enabler_id}].progress`}
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={item?.progress}
                                  render={({ field }) => (
                                    <Slider
                                      min={0}
                                      max={100}
                                      className="slider"
                                      {...field}
                                    />
                                  )}
                                /> */}
                                <Controller
                                  name={`enablers[${item?.enabler_id}].progress`}
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={item?.progress}
                                  render={({ field }) => (
                                    <Slider
                                      min={0}
                                      max={100}
                                      className="slider"
                                      {...field}
                                      onChange={(value) => {
                                        field.onChange(value); // Update the form data
                                        // Update the enablerProgress state
                                        setEnablerProgress((prevProgress) => ({
                                          ...prevProgress,
                                          [item.enabler_id]: value,
                                        }));
                                      }}
                                    />
                                  )}
                                />
                                {errors.enablers &&
                                  errors.enablers[item?.enabler_id]
                                    ?.progress && (
                                    <p className="error-text">
                                      Progress is required
                                    </p>
                                  )}
                                <Controller
                                  name={`enablers[${item?.enabler_id}].progress`}
                                  control={control}
                                  defaultValue={item?.progress}
                                  render={({ field }) => (
                                    <Input
                                      min={0}
                                      max={100}
                                      className="slider-value-input"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"flex-end"}>
                <Grid item>
                  <Space size={15}>
                    <Button
                      className="small-btn text-color-grey px-20"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="small-btn primary-btn px-20"
                      htmlType="submit"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default EditCriticalFactorsModal;
