import React, { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import MyProfile from "./MyProfile";
import ProfileNav from "./ProfileNav";
import ProfileHeader from "./ProfileHeader";
import AccountSettings from "./AccountSettings";
import Billing from "./Billing";
import Support from "./Support";
import "../../CSS/ProfilePage.css";
import FooterButton from "./FooterButton";
import { useActiveProfile } from "../Context/activeProfileHeader";
import { getMethod } from "../../Api'sCall/Apicalling";
import { useProfileDashboardData } from "../Context/profileDashboardData";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

export default function ProfilePage() {
  const { activeNav } = useActiveProfile();
  const { storageDetail } = useStorageContext();
  const { profileDashboardData, setProfileDashboardData } =
    useProfileDashboardData();

  const getBasiUserDetails = () => {
    let id = storageDetail?.user_id;

    if (id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}user/basic-details/?id=${id}`
      ).then((response) => {
        if (response.status === "S") {
          setProfileDashboardData(response.response);
        } else {
        }
      });
    }
  };

  const RenderProfile = [
    {
      name: "My Profile",
      component: <MyProfile />,
    },
    {
      name: "Account Settings",
      component: <AccountSettings getBasiUserDetails={getBasiUserDetails} />,
    },
    {
      name: "Billing",
      component: <Billing />,
    },
    {
      name: "Support",
      component: <Support />,
    },
  ];

  useEffect(() => {
    getBasiUserDetails();
  }, []);

  return (
    <div className="ProfilePage-view">
      <div className="main_wrapper">
        <div className="container">
          <div className="profilepage-Rectangle3">
            <ProfileHeader profiledata={profileDashboardData} />
            <ProfileNav />
            {RenderProfile.map(
              (config) => activeNav === config.name && config.component
            )}
          </div>
          <FooterButton />
        </div>
      </div>
    </div>
  );
}
