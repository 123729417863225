import React, { useState } from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, Input, Tag } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
} from "../../../Api'sCall/Apicalling";
import "../../../CSS/Welcome.css";
import "./modals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const EditHobbiesModal = ({
  data,
  profiledata,
  setProfileData,
  isAdd,
  setCurrentModalData,
  isOpen,
  handleModalOpenClose,
}) => {
  const { storageDetail } = useStorageContext();
  const [tags, setTags] = useState(data ? data : []);
  const [newlyAddedTag, setNewlyAddedTag] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [otherSkillError, setOtherSkillError] = useState(null);

  const tagHandleClose = (removedTag) => {
    setOtherSkillError(null);
    const newTags = tags.filter((tag) => tag !== removedTag);
    const updatedNewlyAddedTag = newlyAddedTag.filter(
      (tag) => tag !== removedTag
    );
    setValue("hobbies", newTags);
    trigger();
    setTags(newTags);
    setNewlyAddedTag(updatedNewlyAddedTag);
    if (tags.includes(removedTag)) {
      handleDelete(removedTag);
    }
  };

  const validationSchema = yup.object().shape({
    hobbies: yup.array().min(1, "At least one hobbie is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getData = (type) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}my-profile/hobbies/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setProfileData({
            ...profiledata,
            hobbies: response.response.Hobbies,
          });
        }
        if (type !== "delete") {
          handleResetClose();
        }
      });
    }
  };

  const handleEdit = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      if (tags.length > 10) {
        setOtherSkillError("Maximum 10 hobbies are allowed");
      } else {
        patchTripdata(
          `${process.env.REACT_APP_API_URL}my-profile/hobbies/${user_id}/`,
          { hobbies: newlyAddedTag }
        ).then((response) => {
          if (response.status === "S") {
            getData();
          }
        });
      }
    }
  };

  const handleDelete = (deleteTag) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      deleteTrip(
        `${process.env.REACT_APP_API_URL}my-profile/hobbies/${user_id}/`,
        { data: { hobbies_to_delete: [deleteTag] } }
      ).then((response) => {
        if (response.status === "S") {
          getData("delete");
        }
      });
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    if (/[a-zA-Z]/.test(e.target.value)) {
      setOtherSkillError(null);
    } else if (e.target.value === "") {
      setOtherSkillError(null);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      if (
        e.target.value.trim().length >= 1 &&
        e.target.value.trim().length <= 50
      ) {
        const newTags = [...tags].filter(
          (tag) => tag.toLowerCase() !== inputValue.toLowerCase()
        );
        if (/[a-zA-Z]/.test(inputValue)) {
          if (tags.length >= 10) {
            setOtherSkillError("Maximum 10 hobbies are allowed");
          } else {
            setTags([...newTags, inputValue]);
            setNewlyAddedTag([...newlyAddedTag, inputValue]);
            setValue("hobbies", [...newTags, inputValue]);
            trigger();
            setInputValue("");
          }
        } else {
          setOtherSkillError(
            "Hobbies must contain at least one alphabet character"
          );
        }
      } else {
        setOtherSkillError("Maximum 50 characters allowed");
      }
    }
  };

  const onFinish = () => {
    handleEdit();
  };

  const handleResetClose = () => {
    handleModalOpenClose(false);
    setCurrentModalData({});
    setInputValue("");
    setOtherSkillError(null);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap">
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                {isAdd ? "Add" : "Edit"} Hobbies and Interests
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleResetClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>
          <div className="form-wrap mb-0">
            <form onSubmit={handleSubmit(onFinish)}>
              <Grid container spacing={2} className="skill_role_side">
                {tags.length > 0 && (
                  <Grid item xs={12}>
                    <div className="tags-group pt-0">
                      <Controller
                        name="hobbies"
                        control={control}
                        render={({ field }) => (
                          <>
                            {tags.map((tag) => (
                              <Tag
                                closable
                                {...field}
                                onClose={(e) => {
                                  e.preventDefault();
                                  // disable tag removing, when only one item is remaining
                                  // tags.length !== 1 &&
                                  tagHandleClose(tag);
                                }}
                                className="tag-item hobbies-item"
                              >
                                {tag}
                              </Tag>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <label htmlFor="">Other</label>
                  <Grid container spacing={3}>
                    <Grid item flex={1}>
                      <Input
                        className="custom_input w-100"
                        placeholder="Type in other hobbies or interests"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyPress}
                      />
                      {otherSkillError && (
                        <p className="custom-modal-error">{otherSkillError}</p>
                      )}
                      {errors.hobbies && (
                        <p className="custom-modal-error">
                          {errors.hobbies.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        className="btn primary primary-btn"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditHobbiesModal;
