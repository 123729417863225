import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import { Grid, Box, Typography, Modal } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "antd";
import { ReactComponent as EditBig } from "../../../../assets/images/edit_big.svg";
import { postMethod } from "../../../Api'sCall/Apicalling";
import EmailVerificationModal from "./EmailVerificationModal";
import "../../../CSS/Welcome.css";
import "./modals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const EditPasswordModal = ({ getData }) => {
  const { storageDetail } = useStorageContext();
  const [open, setOpen] = useState(false);
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOTPModalOpen = () => setIsOTPModalVisible(true);
  const handleOTPModalClose = () => {
    getData();
    setIsOTPModalVisible(false);
  };
  const [password, setPassword] = useState("");

  const [isFocus, setFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const validationSchema = Yup.object().shape({
    create_password: Yup.string()
      .min(8)
      .matches(new RegExp(/[*@!#%$&()^~{}]+/))
      .required("Password is required"),
  });

  const { getValues, setValue, handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const values = getValues();

  // const onSubmit = (data) => {
  //   const updatedEndPoint = pathname.includes("/reset-password")
  //     ? `password-reset/setup-new-password/${id}/${name}/`
  //     : "onboarding/setup-password/";
  //   const passwordData = pathname.includes("/reset-password")
  //     ? {
  //         password: data.create_password,
  //       }
  //     : {
  //         email,
  //         password: data.create_password,
  //       };
  //   postMethod(
  //     `${process.env.REACT_APP_API_URL}${updatedEndPoint}`,
  //     passwordData
  //   ).then((response) => {
  //     if (response.status === "S") {
  //       if (pathname.includes("/reset-password")) {
  //         navigate("/password-successfully-changed");
  //       } else {
  //         setStep((prevStep) => prevStep + 1);
  //       }
  //       sessionStorage.setItem("user_id", response.response.user_id);
  //     } else {
  //       setErrorMessage(response?.errorData?.response?.data?.message);
  //     }
  //   });
  // };

  const validating = (fun) => {
    let flag = false;

    if (values?.create_password) {
      if (fun === "min") {
        if (values?.create_password?.length < 8) flag = true;
      } else {
        if (/[*@!#%$&()^~{}]+/.test(values?.create_password)) {
          flag = false;
        } else {
          flag = true;
        }
      }
    }

    return flag;
  };

  const handleChangePassword = (data) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      postMethod(
        `${process.env.REACT_APP_API_URL}account-settings/update-password/`,
        { user_id }
      ).then((response) => {
        if (response.status === "S") {
          handleClose();
          handleOTPModalOpen();
        } else {
          setErrorMessage(response?.errorData?.response?.data?.message);
        }
      });
    }
  };

  return (
    <>
      <Button onClick={handleOpen} className="flied-edit-btn">
        <EditBig />
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap samll_modal">
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            className="mb-3"
          >
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                youe
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit(handleChangePassword)}>
            <div className="login-field change-pass-modal">
              <label className="field-label">Change Password</label>
              <Controller
                control={control}
                name="create_password"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div className={"input-con"}>
                    <Icon
                      icon={
                        showPassword ? "iconamoon:eye" : "octicon:eye-closed-16"
                      }
                      className={
                        error
                          ? "failure-icon eye-password"
                          : value && isFocus?.password
                          ? "sucess-icon eye-password"
                          : "eye-password"
                      }
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                    <Icon
                      icon={"majesticons:lock-line"}
                      className={
                        error
                          ? "failure-icon icon"
                          : value && isFocus?.password
                          ? "sucess-icon icon"
                          : "icon"
                      }
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      name={name}
                      onBlur={() => {
                        setFocus((prev) => ({ ...prev, [name]: false }));
                      }}
                      onChange={({ target: { value } }) => {
                        setValue(name, value, { shouldValidate: true });
                        setPassword(value);
                        setFocus((prev) => ({ ...prev, [name]: true }));
                      }}
                      placeholder="Enter password"
                      className={
                        error
                          ? "outline-failure input-design mt-0 mb-3"
                          : value && isFocus?.password
                          ? "outline-success input-design mt-0 mb-3"
                          : "input-design mt-0 mb-3"
                      }
                      // className='outline-failure'
                    />
                    {errorMessage && (
                      <span className="error-msg">{errorMessage}</span>
                    )}
                  </div>
                )}
              />

              <div className="validate-con">
                <div className="validate-msg">
                  {values?.create_password ? (
                    <span>
                      <Icon
                        icon={validating("min") ? "iconoir:cancel" : "mdi:tick"}
                        className={!validating("min") ? "tick" : "cancel"}
                      />
                    </span>
                  ) : (
                    <span>
                      <Icon icon={"iconoir:cancel"} className="cancel" />
                    </span>
                  )}{" "}
                  <span className="password_req desktop_elem">
                    8 characters or more
                  </span>
                  <span className="password_req mobile_elem">8 characters</span>
                </div>
                <div className="validate-msg">
                  {values?.create_password ? (
                    <span className="ic-width">
                      <Icon
                        icon={
                          validating("matches") ? "iconoir:cancel" : "mdi:tick"
                        }
                        className={!validating("matches") ? "tick" : "cancel"}
                      />
                    </span>
                  ) : (
                    <span className="ic-width">
                      <Icon icon={"iconoir:cancel"} className="cancel" />
                    </span>
                  )}{" "}
                  <span className="need_special_chrc">
                    at least 1 special character
                  </span>
                </div>
              </div>

              <Button
                className="email-signup-button primary_btn mb-0"
                type="submit"
                htmlType="submit"
              >
                Change password
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <EmailVerificationModal
        password={password}
        isFrom={"password"}
        isOTPModalVisible={isOTPModalVisible}
        setIsOTPModalVisible={setIsOTPModalVisible}
        handleOTPModalOpen={handleOTPModalOpen}
        handleOTPModalClose={handleOTPModalClose}
        getData={getData}
      />
    </>
  );
};

export default EditPasswordModal;
