import React, { createContext, useContext, useEffect, useState } from "react";

const StorageContext = createContext();

export function StorageProvider({ children }) {
  const [storageDetail, setStorageDetail] = useState({
    user_id: localStorage.getItem("user_id"),
  });

  useEffect(() => {
    if (storageDetail?.user_id)
      localStorage.setItem("user_id", storageDetail?.user_id);
  }, [storageDetail]);

  return (
    <StorageContext.Provider value={{ storageDetail, setStorageDetail }}>
      {children}
    </StorageContext.Provider>
  );
}

export function useStorageContext() {
  return useContext(StorageContext);
}
