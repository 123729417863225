import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Input, Select, DatePicker, Space } from "antd";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as FlagIcon } from "../../../../assets/images/flag.svg";
import "./nodemapModals.css";
import dayjs from "dayjs";
import {
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import ConfirmationPopup from "./ConfirmationPopup";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";
import { useEffect } from "react";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const Option = Select.Option;
const { RangePicker } = DatePicker;

const editReasonsOptions = [
  { value: "Changed Priorities", label: "Changed Priorities" },
  { value: "New Opportunities", label: "New Opportunities" },
  { value: "Job Market Changes", label: "Job Market Changes" },
  { value: "Renaming", label: "Renaming" },
];
const addReasonsOptions = [
  { value: "Changed Priorities", label: "Changed Priorities" },
  { value: "New Opportunities", label: "New Opportunities" },
  { value: "Job Market Changes", label: "Job Market Changes" },
];

const AddMilestoneModal = ({
  isOpen,
  handleModalOpenClose,
  isAdd,
  setNodemapData,
  nodemapId,
  modalData,
  haveMilestoneNumber,
}) => {
  const dateFormat = "DD/MM/YYYY";
  const { storageDetail } = useStorageContext();
  const { setIsLoading } = useLoadingContext();
  const [isCompleted, setIsCompleted] = useState(0);
  const [milestoneData, setMileStoneData] = useState({});
  const [isEnable, setIsEnable] = useState("no");
  const [isTimeLineUpdated, setIsTimeLineUpdated] = useState("no");
  const [currentMode, setCurrentMode] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [tempData, setTempData] = useState({});
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [placeOptions, setPlaceOptions] = useState([]);
  const reasonValidation = isTitleChanged
    ? { reason: Yup.string().required("Reason for edit is required") }
    : {};

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Minimum 1 characters required")
      .max(100, "Maximum 100 characters allowed"),
    date: Yup.array()
      .of(Yup.date().required("Date is required"))
      .required("Date is required")
      .test(
        "is-not-same",
        "Start and end dates should not be the same",
        (value) => {
          if (value && value.length === 2) {
            const [startDate, endDate] = value;

            // Compare the timestamps of the dates
            return startDate.getTime() !== endDate.getTime();
          } else {
            return true;
          }
        }
      ),
    reason: Yup.string().required("Reason for addition  is required"),
    place: Yup.string().required("Select Place is required"),
  });

  const validationSchemaForEdit = Yup.object().shape({
    ...reasonValidation,
    title: Yup.string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Minimum 1 characters required")
      .max(100, "Maximum 100 characters allowed"),
    date: Yup.array()
      .of(Yup.date().required("Date is required"))
      .required("Date is required")
      .test(
        "is-not-same",
        "Start and end dates should not be the same",
        (value) => {
          if (value && value.length === 2) {
            const [startDate, endDate] = value;

            // Compare the timestamps of the dates
            return startDate.getTime() !== endDate.getTime();
          } else {
            return true;
          }
        }
      ),
    // reason: Yup.string().required("Reason for edit is required"),
  });

  const {
    handleSubmit,
    control,
    getFieldState,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: isAdd
      ? yupResolver(validationSchema)
      : yupResolver(validationSchemaForEdit),
  });

  useEffect(() => {
    getPlaceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodemapId]);

  const getPlaceOptions = () => {
    if (nodemapId) {
      getMethod(
        `${process.env.REACT_APP_API_URL}nodemap/add-milestone/place-after/${nodemapId}/`
      ).then((response) => {
        if (response.status === "S") {
          setPlaceOptions(response.response.response);
        } else {
        }
      });
    }
  };

  useEffect(() => {
    if (getFieldState("title").isDirty || isAdd) {
      setIsTitleChanged(true);
    } else {
      setIsTitleChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("title"), isAdd]);

  useEffect(() => {
    if (!isAdd && modalData) {
      // Edit case
      setIsCompleted(modalData?.progress);
      reset({
        title: modalData.title,
        date: [
          dayjs(modalData.start_date, dateFormat),
          dayjs(modalData.end_date, dateFormat),
        ],
      });
      setMileStoneData({
        id: modalData?.milestone_id,
      });
    } else {
      // Add case
      reset({
        title: "",
        reason: "",
        place: "",
      });
    }
  }, [isOpen, modalData, reset, isAdd]);

  const handleClose = () => {
    setIsCompleted(0);
    handleModalOpenClose(false);
    reset();
  };

  const handleProgress = () => {
    setIsLoading(true);
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/milestone-progress/${nodemapId}/`,
      {
        milestone_id: milestoneData?.id || "",
        progress: 100,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getPlaceOptions();
          getData();
          handleClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };
  const getData = () => {
    const user_id = storageDetail?.user_id;
    getMethod(
      `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        let res = Object.values(
          response.response.nodemap.reduce((acc, cur) => {
            if (!acc[cur.end_date]) {
              acc[cur.end_date] = [{ ...cur }];
            } else {
              acc[cur.end_date].push(cur);
            }
            return acc;
          }, {})
        );
        setNodemapData(res);
      }
    });
  };

  const handleEdit = (editData) => {
    let apiData = {
      id: nodemapId,
      title: editData?.title,
      // reason: editData?.reason,
      ...(isAdd
        ? { reason: editData?.reason }
        : isTitleChanged && { reason: editData?.reason }),
      start_date: moment(editData.date[0]).format("DD/MM/YYYY"),
      end_date: moment(editData.date[1]).format("DD/MM/YYYY"),
      milestone_id: milestoneData?.id,
      milestone_title: milestoneData?.title,
    };
    setTempData(apiData);
    if (isAdd) {
      setCurrentMode("regularConfirmation");
      const editActionTitle = (
        <p className="confirmation-dic">
          A new milestone has been added. Would you like{" "}
          <b style={{ fontWeight: 800 }}>youe AI</b> to create new actions and
          critical enablers?
        </p>
      );
      setConfirmationTitle(editActionTitle);
      setIsConfirmationModalOpen(true);
    } else {
      if (getFieldState("title").isDirty && !getFieldState("date").isDirty) {
        // If user is changing only the title
        if (editData?.reason === "Renaming") {
          // If REASON IS Renaming Save the title without any validation
          setIsLoading(true);
          patchTripdata(
            `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${nodemapId}/`,
            {
              ...apiData,
              generate_actions: "no",
              update_timeline: "no",
            }
          )
            .then((response) => {
              if (response.status === "S") {
                setIsLoading(false);
                getPlaceOptions();
                getData();
                handleClose();
              } else {
                setIsLoading(false);
              }
            })
            .catch((err) => setIsLoading(false));
        } else {
          setCurrentMode("regularConfirmation");
          const editActionTitle = (
            <p className="confirmation-dic">
              Would you like <b style={{ fontWeight: 800 }}>youe AI</b> to
              generate new actions?
            </p>
          );
          setConfirmationTitle(editActionTitle);
          setIsConfirmationModalOpen(true);
        }
      } else if (getFieldState("date").isDirty) {
        if (editData?.reason === "Renaming") {
          // If user is changing title, start date or end date with reason renaming.
          const editActionTitle = (
            <p className="confirmation-dic">
              A new milestone has been added. Would you like{" "}
              <b style={{ fontWeight: 800 }}>youe AI</b> to update your timeline
              for the remaining milestones?
            </p>
          );
          setCurrentMode("timeLineConfirmation");
          setConfirmationTitle(editActionTitle);
          setIsConfirmationModalOpen(true);
        } else if (editData?.reason && editData?.reason !== "renaming") {
          // If user is changing title, start date or end date with reason not renaming.
          setCurrentMode("regularConfirmation");
          const editActionTitle = (
            <p className="confirmation-dic">
              Would you like <b style={{ fontWeight: 800 }}>youe AI</b> to
              generate new actions?
            </p>
          );
          setConfirmationTitle(editActionTitle);
          setIsConfirmationModalOpen(true);
        } else {
          // If user is changing start date or end date
          const editActionTitle = (
            <p className="confirmation-dic">
              A new milestone has been added. Would you like{" "}
              <b style={{ fontWeight: 800 }}>youe AI</b> to update your timeline
              for the remaining milestones?
            </p>
          );
          setCurrentMode("timeLineConfirmation");
          setConfirmationTitle(editActionTitle);
          setIsConfirmationModalOpen(true);
        }
      } else {
        //If user changes fields other than title and dates.
        setIsLoading(true);
        patchTripdata(
          `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${nodemapId}/`,
          {
            ...apiData,
            generate_actions: "no",
            update_timeline: "no",
          }
        ).then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getPlaceOptions();
            getData();
            handleClose();
          } else {
            setIsLoading(false);
          }
        });
      }
    }
  };

  const handleSaveData = (isEnable) => {
    setIsEnable(isEnable ? "yes" : "no");
    if (
      isAdd ||
      // getFieldState("title").isDirty ||
      getFieldState("date").isDirty
    ) {
      setCurrentMode("timeLineConfirmation");
      const editActionTitle = (
        <p className="confirmation-dic">
          A new milestone has been added. Would you like{" "}
          <b style={{ fontWeight: 800 }}>youe AI</b> to update your timeline for
          the remaining milestones?
        </p>
      );
      setConfirmationTitle(editActionTitle);
      setTimeout(() => {
        setIsConfirmationModalOpen(true);
      }, 500);
    } else {
      setIsLoading(true);
      postMethod(
        `${process.env.REACT_APP_API_URL}nodemap/milestone/validate-title/`,
        {
          id: tempData?.id || "",
          milestone_id: milestoneData?.id || "",
          title: tempData?.title || "",
          reason: tempData?.reason || "",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            if (response.response.matching === "yes") {
              patchTripdata(
                `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${tempData?.id}/`,
                {
                  ...tempData,
                  generate_actions: isEnable ? "yes" : "no",
                  update_timeline: "no",
                }
              )
                .then((response) => {
                  if (response.status === "S") {
                    setIsLoading(false);
                    getPlaceOptions();
                    getData();
                    handleClose();
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => setIsLoading(false));
            } else {
              setIsLoading(false);
              setCurrentMode("alignmentConfirmation");
              setConfirmationTitle(
                "Title is not aligned with your milestone. Do you still want to save it"
              );
              setIsConfirmationModalOpen(true);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const handleSaveTimeline = (updateTimeline) => {
    setIsTimeLineUpdated(updateTimeline ? "yes" : "no");
    setIsLoading(true);
    if (isAdd) {
      postMethod(
        `${process.env.REACT_APP_API_URL}nodemap/add-milestone/validate-title/`,
        {
          id: tempData?.id || "",
          title: tempData?.title || "",
          reason: tempData?.reason || "",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            if (response.response.matching === "yes") {
              postMethod(
                `${process.env.REACT_APP_API_URL}nodemap/add-milestone/`,
                {
                  ...tempData,
                  generate_actions_and_enablers: isEnable || "no",
                  update_timeline: updateTimeline ? "yes" : "no",
                }
              )
                .then((response) => {
                  if (response.status === "S") {
                    setIsLoading(false);
                    getPlaceOptions();
                    getData();
                    handleClose();
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => setIsLoading(false));
            } else {
              setIsLoading(false);
              setCurrentMode("alignmentConfirmation");
              setConfirmationTitle(
                "Title is not aligned with your milestone. Do you still want to save it"
              );
              setIsConfirmationModalOpen(true);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else if (
      !isAdd &&
      !getFieldState("title").isDirty &&
      getFieldState("date").isDirty
    ) {
      patchTripdata(
        `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${tempData.id}/`,
        {
          ...tempData,
          generate_actions: isEnable || "no",
          update_timeline: updateTimeline ? "yes" : "no",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getPlaceOptions();
            getData();
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else if (!isAdd && getFieldState("title").isDirty) {
      postMethod(
        `${process.env.REACT_APP_API_URL}nodemap/milestone/validate-title/`,
        {
          id: tempData?.id || "",
          milestone_id: milestoneData?.id || "",
          title: tempData?.title || "",
          reason: tempData?.reason || "",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            if (response.response.matching === "yes") {
              patchTripdata(
                `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${tempData.id}/`,
                {
                  ...tempData,
                  generate_actions: isEnable || "no",
                  update_timeline: updateTimeline ? "yes" : "no",
                }
              )
                .then((response) => {
                  if (response.status === "S") {
                    setIsLoading(false);
                    getPlaceOptions();
                    getData();
                    handleClose();
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => setIsLoading(false));
            } else {
              setIsLoading(false);
              setCurrentMode("alignmentConfirmation");
              setConfirmationTitle(
                "Title is not aligned with your milestone. Do you still want to save it"
              );
              setIsConfirmationModalOpen(true);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else {
      patchTripdata(
        `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${tempData?.id}/`,
        {
          ...tempData,
          generate_actions: "no",
          update_timeline: updateTimeline ? "yes" : "no",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getPlaceOptions();
            getData();
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const handleAlignment = (isSubmit) => {
    setIsLoading(true);
    if (isAdd && isSubmit) {
      postMethod(`${process.env.REACT_APP_API_URL}nodemap/add-milestone/`, {
        ...tempData,
        generate_actions_and_enablers: isEnable || "no",
        update_timeline: isTimeLineUpdated || "no",
      })
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getPlaceOptions();
            getData();
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else if (isSubmit) {
      patchTripdata(
        `${process.env.REACT_APP_API_URL}nodemap/edit-milestone/${tempData?.id}/`,
        {
          ...tempData,
          generate_actions: isEnable || "no",
          update_timeline: isTimeLineUpdated || "no",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getPlaceOptions();
            getData();
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <div className="collapse-icon">
                    <FlagIcon />
                  </div>
                  <p>
                    {isAdd
                      ? "Add Milestone"
                      : `Edit Milestone ${
                          haveMilestoneNumber !== false
                            ? `0${haveMilestoneNumber + 1}`
                            : ""
                        }`}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="title">Title</label>
                    <Controller
                      name="title"
                      // defaultValue={""}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="Short Term Goal 03"
                          // defaultValue={currentModalData.title}
                        />
                      )}
                    />
                    {errors.title && (
                      <p className="custom-modal-error">
                        {errors.title.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="date">Date</label>
                      <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                          <RangePicker
                            {...field}
                            format={"DD/MM/YYYY"}
                            className="custom_input startdatepicker-control"
                            rootClassName="range-datepicker"
                          />
                        )}
                      />
                      {errors?.date && (
                        <p className="custom-modal-error">
                          {errors?.date?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  {isTitleChanged && (
                    <Grid item xs={12}>
                      <div className="select-field">
                        <label htmlFor="reason">{`Reason for ${
                          isAdd ? "Addition" : "Edit"
                        } `}</label>
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="select-design-signin-ant"
                              placeholder="Select Reason"
                              dropdownStyle={{ zIndex: 2000 }}
                              options={
                                isAdd ? addReasonsOptions : editReasonsOptions
                              }
                            />
                          )}
                        />
                        {errors?.reason && (
                          <p className="custom-modal-error">
                            {errors?.reason?.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  )}
                  {isAdd && (
                    <Grid item xs={12}>
                      <div className="select-field">
                        <label htmlFor="place">
                          Select an entry to place under
                        </label>

                        <Controller
                          name="place"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="select-design-signin-ant"
                              placeholder="Select Place"
                              dropdownStyle={{ zIndex: 2000 }}
                              onSelect={(value, LabeledValue) => {
                                setMileStoneData({
                                  id: LabeledValue?.key,
                                  title: value,
                                });
                              }}
                            >
                              {placeOptions &&
                                placeOptions.map((option) => (
                                  <Option
                                    key={option.milestone_id}
                                    value={option.milestone_title}
                                  >
                                    {option.milestone_title}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        />
                        {errors?.place && (
                          <p className="custom-modal-error">
                            {errors?.place?.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {!isAdd &&
                    (isCompleted === 100 ? (
                      <Button className="small-btn check-btn completed-btn">
                        <Icon icon="icon-park-outline:check-one" /> Completed
                      </Button>
                    ) : (
                      <Button
                        className="small-btn check-btn mark-as-completed-btn"
                        onClick={() => {
                          handleProgress();
                          setIsCompleted(100);
                        }}
                      >
                        <Icon icon="icon-park-outline:check-one" /> Mark as
                        completed
                      </Button>
                    ))}
                </Grid>
                <Grid item>
                  <Space size={15}>
                    <Button
                      className="small-btn text-color-grey px-20"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="small-btn primary-btn px-20"
                      htmlType="submit"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Grid>
              </Grid>
            </form>
          </Box>
          <ConfirmationPopup
            isOpen={isConfirmationModalOpen}
            setIsOpen={setIsConfirmationModalOpen}
            title={confirmationTitle}
            handleSaveData={handleSaveData}
            handleSaveTimeline={handleSaveTimeline}
            handleAlignment={handleAlignment}
            mode={currentMode}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddMilestoneModal;
