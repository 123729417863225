import React, { useState } from "react";
import { Avatar, Input, Tag } from "antd";
import clsx from "clsx";
import { ReactComponent as AiIcon } from "../../../../assets/images/aiIcon.svg";
import { Icon } from "@iconify/react";
import "../../../CSS/QuestionsModal.css";

const QuestionsModal = ({
  aiQuestionValue,
  setAiQuestionValue,
  handleSubmitQuestion,
  isLoading,
}) => {
  const [isSuggestionBoxOpen, setIsSuggestionBoxOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [tagsSuggestionList, setTagsSuggestionList] = useState([
    "Recommend resources for developing leadership skills",
    "Suggest ways to improve networking skills to unlock career growth",
    "Introduce me to a framework for strategic career planning",
    "Recommend approaches to solicit constructive feedback in my current job",
    "Suggest daily habits that can boost professional growth",
  ]);
  return (
    <>
      <Input
        className={clsx(
          aiQuestionValue && "question-modal-asking-input",
          "question-modal-ask-input"
        )}
        id="footer-ai-input"
        value={aiQuestionValue}
        disabled={isLoading}
        prefix={<AiIcon className="question-modal-ask-pre-avatar" />}
        suffix={
          <Avatar
            className="question-modal-ask-send-avatar"
            shape="square"
            size="small"
            icon={<Icon icon="tabler:send" id="footer-ai-send-icon" />}
            onClick={() => {
              aiQuestionValue && setIsSuggestionBoxOpen(false);
              handleSubmitQuestion(aiQuestionValue);
            }}
            disabled={isLoading}
          />
        }
        placeholder={`Ask youe AI anything…`}
        onChange={(e) => {
          setIsSuggestionBoxOpen(e.target.value ? true : false);
          setAiQuestionValue(e.target.value);
        }}
      />
      {isSuggestionBoxOpen && (
        <div className="question-ai-suggestion-container">
          {tagsSuggestionList?.map((item) => (
            <Tag
              bordered={false}
              style={{ cursor: "pointer" }}
              id="suggestion-tags"
              onClick={(e) => {
                setIsSuggestionBoxOpen(false);
                setAiQuestionValue(item);
              }}
            >
              {item}
            </Tag>
          ))}
        </div>
      )}
    </>
  );
};

export default QuestionsModal;
