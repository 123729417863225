import React, { createContext, useContext, useEffect, useState } from "react";

const ActiveProfile = createContext();

export function ActiveNameProvider({ children }) {
  const [activeNav, setActiveNav] = useState(() => {
    const savedValue = localStorage.getItem("activeNav");
    return savedValue || "My Profile";
  });

  useEffect(() => {
    localStorage.setItem("activeNav", activeNav);
  }, [activeNav]);

  return (
    <ActiveProfile.Provider value={{ activeNav, setActiveNav }}>
      {children}
    </ActiveProfile.Provider>
  );
}

export function useActiveProfile() {
  return useContext(ActiveProfile);
}
