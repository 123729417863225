import React from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { ReactComponent as LogoGray } from "../../../assets/images/youe_logo_gray.svg";
import { Space } from "antd";

const ProfileHeader = ({ profiledata }) => {
  return (
    <div className="userHeader">
      <Grid container spacing={{ lg: 4, md: 3, xs: 2 }}>
        <Grid item>
          <div className="profilepage-Rectangle-profile-image">
            <span className="profilepage-image">
              {profiledata?.profile_image && (
                <img
                  className="user-profile"
                  alt=""
                  src={profiledata?.profile_image}
                />
              )}
            </span>
          </div>
        </Grid>
        <Grid item>
          <p className="profilepage-Your-Name">
            {profiledata.first_name}&nbsp;{profiledata.last_name}
            {/* Your Name */}
          </p>
          <Space size={10}>
            {profiledata?.location && (
              <span className="profilepage-Berlin-Germany">
                <Icon icon="carbon:location" />
                {profiledata.location}
              </span>
            )}
            {profiledata?.email && (
              <span className="profilepage-Berlin-Germany">
                <Icon icon="pepicons-pencil:letter" />
                {profiledata.email}
              </span>
            )}

            {profiledata?.member_since && (
              <span className="profilepage-Berlin-Germany">
                <Icon icon="heroicons:check-badge-20-solid" />
                Since {profiledata.member_since} on
                <LogoGray
                  height={"11px"}
                  width={"37px"}
                  className="days-in-logo"
                />
              </span>
            )}
          </Space>
          {profiledata.current_role && profiledata.current_company && (
            <span className="profilepage-Senior-Product-Desig">
              <Icon
                icon="ri:meta-line"
                style={{
                  marginTop: "-3px",
                  backgroundColor: "#d3d3d3",
                  color: "#d3d3d3",
                  padding: "1px",
                  borderRadius: "3px",
                  marginBottom: "-2px",
                  marginRight: 6,
                  fontSize: 18,
                }}
              />
              {profiledata.current_role} at {profiledata.current_company}
              {profiledata.current_experience && (
                <>
                  <span className="divider-dot"></span>
                  <span className="text-style-1">
                    {profiledata.current_experience}
                  </span>
                </>
              )}
            </span>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileHeader;
