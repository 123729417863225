import React from "react";
import Logo from "../../../assets/images/youe_logo.svg";
import { useNavigate } from "react-router-dom";
import "./LandingPageHeader.css";

const LandingPageHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="get-started">
        Now in beta! Become a founding member for $49/yr (normally $99).{" "}
        <span>Get Started</span>
      </div>
      <div className="landing-header-wrapper">
        <div className="container p-0 d-flex justify-content-between align-items-center">
          <span className="logo">
            <img src={Logo} alt="logo" />
          </span>
          <div className="landing-btn-wrapper">
            <button
              className="landing-btn join-btn"
              onClick={() => navigate("/email-signup")}
            >
              Join
            </button>
            <button
              className="landing-btn login-btn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageHeader;
