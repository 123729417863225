import React, { useEffect, useState } from "react";
import { Tag, Tooltip } from "antd";

const MultiSelectTag = ({ options, getSelectedTag, selctedTags }) => {
  const [selectedTags, setSelectedTags] = useState(selctedTags || []);

  useEffect(() => {
    getSelectedTag(selectedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  return (
    <div className="option_pannel">
      {options.map((option) => (
        <>
          {option.length > 18 ? (
            <Tooltip title={option} rootClassName="option-tooltip">
              <Tag
                key={option}
                onClick={() => handleTagClick(option)}
                className={
                  selectedTags.includes(option) ? "selected_option" : ""
                }
              >
                {option}
              </Tag>
            </Tooltip>
          ) : (
            <Tag
              key={option}
              onClick={() => handleTagClick(option)}
              className={selectedTags.includes(option) ? "selected_option" : ""}
            >
              {option}
            </Tag>
          )}
        </>
      ))}
    </div>
  );
};

export default MultiSelectTag;
