import React from "react";
import Logo from "../../../assets/images/youe_logo.svg";
import threadIcon from "../../../assets/images/icon_threads.png";
import youtubeIcon from "../../../assets/images/icon_youTube.png";
import xIcon from "../../../assets/images/icon_x.png";
import instaIcon from "../../../assets/images/icon_instagram.png";
import linkedinIcon from "../../../assets/images/icon_linkedIn.png";
import { useNavigate } from "react-router-dom";
import "./LandingPageFooter.css";
import { Grid } from "@mui/material";

const LandingPageFooter = () => {
  return (
    <>
      <div className="landing-footer">
        <div className="container p-0">
          <img src={Logo} alt="" className="flogo" />
          <p className="details-text">Design your perfect career</p>
          <Grid container spacing={3} className="footer-form-content">
            <Grid xs={6} className="col-basiswidth">
              <p className="discription-text pe-4">
                Youe is the only AI-powered career growth platform that brings
                certainty to the process of career advancement. Set milestones,
                create actions, identify critical enablers and track your
                progress. <br /> With compelling insights revealed – and a
                holistic vision of your career pathway – you’ll be inspired and
                motivated to stay focused.
              </p>
            </Grid>
            <Grid xs={6}>
              <p className="details-text primary-color-title mb-3">
                Subscribe to our free newsletter
              </p>
              <div className="subcribe-form">
                <input type="text" placeholder="Email" />
                <button className="join-btn">Subscribe</button>
              </div>
            </Grid>
          </Grid>
          <div>
            <a
              href="mailto:support@youe.co"
              className="details-text support-link"
            >
              support@youe.co
            </a>
          </div>
          <Grid
            container
            spacing={3}
            className="copyright-content"
            alignItems={"flex-end"}
          >
            <Grid xs={8}>
              <div className="fcontrol-links">
                <p className="flink">© 2023 Youe Labs Pty Ltd.</p>
                <a>Privacy</a>
                <a>Platform Privacy Notice</a>
                <a>Terms of Use</a>
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="social-icon-list text-end">
                <a>
                  <img src={threadIcon} alt="" className="social-icon" />
                </a>
                <a>
                  <img src={youtubeIcon} alt="" className="social-icon" />
                </a>
                <a>
                  <img src={xIcon} alt="" className="social-icon" />
                </a>
                <a>
                  <img src={instaIcon} alt="" className="social-icon" />
                </a>
                <a>
                  <img src={linkedinIcon} alt="" className="social-icon" />
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageFooter;
