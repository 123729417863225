import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button, Space } from "antd";
import React from "react";

const SubscriptionCancelModal = ({
  open,
  setOpen,
  cancelSub,
  cancelSubscription,
  currentPlan,
  disableAutoRenew,
  handleCancel,
}) => {
  const handleClose = () => {
    handleCancel();
    // setOpen(false);
  };
  const handleYes = () => {
    cancelSub ? cancelSubscription() : disableAutoRenew();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap samll_modal">
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            className="mb-3"
          >
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                youe
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>

          <div className="subscription-modal-content">
            {cancelSub ? (
              <>
                <h3 className="wrapTitle mb-0">
                  Are you sure that you want to cancel your
                  <span> Youe {currentPlan && currentPlan}</span> subscription
                  now?
                </h3>
              </>
            ) : (
              <>
                <h3 className="wrapTitle mb-0">
                  Are you sure that you want to disable Auto-Renew for your
                  subscription?
                </h3>
              </>
            )}
          </div>

          <Space className="w-100 justify-content-end">
            <Button className="small-btn" onClick={handleYes}>
              {cancelSub ? "Yes, cancel" : "Yes, disable"}
            </Button>
            <Button className="small-btn primary-btn" onClick={handleClose}>
              No
            </Button>
          </Space>
        </Box>
      </Modal>
    </div>
  );
};

export default SubscriptionCancelModal;
