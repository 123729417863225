import React from "react";
import { Icon } from "@iconify/react";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";
import "../../CSS/Avatar.css";

const Avatar = ({
  labelText,
  optionalClassName,
  isDropOpen,
  handleDropClick,
}) => {
  const renderIcon = () => {
    if (optionalClassName.includes("first_label")) {
      return (
        <svg
          width="24"
          height="23"
          viewBox="0 0 24 23"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              d="M0 10.824c0-2.99 2.388-5.412 5.333-5.412h13.334c2.945 0 5.333 2.423 5.333 5.412v6.764C24 20.577 21.612 23 18.667 23H5.333C2.388 23 0 20.577 0 17.588v-6.764zm5.333-2.706c-1.472 0-2.666 1.211-2.666 2.706v6.764c0 1.495 1.194 2.706 2.666 2.706h13.334c1.472 0 2.666-1.211 2.666-2.706v-6.764c0-1.495-1.194-2.706-2.666-2.706H5.333zM9.333 2.706C8.597 2.706 8 3.312 8 4.059v1.353H5.333V4.059c0-2.242 1.791-4.059 4-4.059h5.334c2.209 0 4 1.817 4 4.059v1.353H16V4.059c0-.747-.597-1.353-1.333-1.353H9.333z"
              fill="#292D34"
            />
            <path
              stroke="#292D34"
              stroke-width="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.667 8.118 8 6.764h2.666l8-6.764"
            />
          </g>
        </svg>
      );
    } else if (optionalClassName.includes("last_label")) {
      return <Icon icon="ph:star-fill" />;
    } else {
      return <UserIcon />;
    }
  };
  return (
    <div
      className={`avtar_block ${optionalClassName ? optionalClassName : ""}`}
    >
      <div className="avatar-box">{renderIcon()}</div>
      {labelText && (
        <div className="side_label">
          <span>{labelText}</span>
        </div>
      )}
      {optionalClassName.includes("last_label") && (
        <Icon
          icon="ph:caret-up-bold"
          className={`drop_arrow ${isDropOpen ? "drop_rotate" : ""}`}
          onClick={handleDropClick}
        />
      )}
    </div>
  );
};

export default Avatar;
