import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Button, Col, Row, Popover, Tooltip } from "antd";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { ReactComponent as FlagIcon } from "../../../assets/images/flag.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";
import MilestoneCard from "./MilestoneCard";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg";
import JourneryInfo from "../../BeforeLogin/JourneryInfo";
import "../../CSS/nodeMap.css";

const SingleMilestone = ({
  targetRole,
  type,
  nodemapData,
  uniqueYears,
  milestoneRolesData,
  intermediateRolesData,
  setIsAddMilestoneModalOpen,
  setIsAddIntermediateRoleModalOpen,
  setIsAddTargetedRoleModalOpen,
  setMilestoneModalData,
  setIntermediateModalData,
  setTargetModalData,
  setIsAdd,
  nodemapDataId,
  getNodemapDataList,
  index,
  mainData,
  currentRoleYear,
  currentIndex,
  allUniqueYears,
}) => {
  const [expanded, setExpanded] = useState("");
  const [threeExpanded, setThreeExpanded] = useState("");

  const date = moment(nodemapData[0]?.end_date, "DD/MM/YYYY").format(
    "YYYY-MM-DD"
  );
  const formattedDate = moment(date).format("DD MMM");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleThreeChange = (panel) => {
    if (threeExpanded === panel) {
      setThreeExpanded(null);
    } else {
      setThreeExpanded(panel);
    }
  };

  const handleRespectiveOpenModal = (element) => {
    setIsAdd(false);
    switch (type) {
      case "intermediate_role":
        setIsAddIntermediateRoleModalOpen(true);
        setIntermediateModalData(element);
        break;
      case "milestone":
        setIsAddMilestoneModalOpen(true);
        setMilestoneModalData(element);
        break;
      case "targeted_role":
        setIsAddTargetedRoleModalOpen(true);
        setTargetModalData(element);
        break;
      default:
        break;
    }
  };

  const getColumnSpan = (panel) => {
    if (threeExpanded === "1") {
      return panel === "1" ? 24 : 0;
    } else if (threeExpanded === "2") {
      return panel === "2" ? 24 : 0;
    } else if (threeExpanded === "3") {
      return panel === "3" ? 24 : 0;
    }
    return 8;
  };

  return (
    <>
      {mainData?.length > 0 && (
        <div className="nodemap-option-wrpper">
          <div
            className={`nodemap-option-icon-wrap  
            ${nodemapData?.length !== 1 && "two-milestone-line"}
            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 1 &&
              mainData[index + 1][0]?.progress === 100 &&
              type !== "targeted_role"
                ? "left-complete completed right-complete"
                : ""
            }
  
            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 2 &&
              mainData[index + 1][0]?.progress === 100 &&
              type !== "targeted_role"
                ? "left-complete"
                : ""
            }

            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 2 &&
              mainData[index + 1][1]?.progress === 100 &&
              type !== "targeted_role"
                ? "completed"
                : ""
            }

            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 3 &&
              mainData[index + 1][0]?.progress === 100 &&
              type !== "targeted_role"
                ? "left-complete"
                : ""
            }
            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 3 &&
              mainData[index + 1][1]?.progress === 100 &&
              type !== "targeted_role"
                ? "completed"
                : ""
            }
            ${
              mainData[index + 1] &&
              mainData[index + 1]?.length === 3 &&
              mainData[index + 1][2]?.progress === 100 &&
              type !== "targeted_role"
                ? "right-complete"
                : ""
            }    
            ${
              mainData[index + 1]?.length > 1
                ? "milestone-flow"
                : type === "targeted_role"
                ? "star-milestone-flow"
                : ""
            } ${
              mainData[index + 1]?.length === 2
                ? "milestone2"
                : mainData[index + 1]?.length === 3
                ? "milestone3"
                : ""
            } 
            `}
          >
            {mainData[index + 1]?.length === 3 && (
              <div className="right-border"></div>
            )}
            {nodemapData?.length === 2 && (
              <>
                <div className="multiple-milestone-flag">
                  <div
                    className={`nodemap-option-icon ${
                      nodemapData[0].progress === 100 ? "icon-complete" : ""
                    }`}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={`${nodemapData[0]?.progress}`}
                      className="milestone-progress-circle"
                    />

                    <div className="milestone-progress-icon">
                      <FlagIcon />
                    </div>
                  </div>
                </div>
              </>
            )}
            {nodemapData?.length === 3 && (
              <>
                <div className="multiple-milestone-flag right-line-border">
                  <div
                    className={`nodemap-option-icon ${
                      nodemapData[0]?.progress === 100 ? "icon-complete" : ""
                    }`}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={`${nodemapData[0]?.progress}`}
                      className="milestone-progress-circle"
                    />

                    <div className="milestone-progress-icon">
                      <FlagIcon />
                    </div>
                  </div>
                  <div
                    className={`nodemap-option-icon ${
                      nodemapData[2]?.progress === 100 ? "icon-complete" : ""
                    }`}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={`${nodemapData[2]?.progress}`}
                      className="milestone-progress-circle"
                    />

                    <div className="milestone-progress-icon">
                      <FlagIcon />
                    </div>
                  </div>
                </div>
              </>
            )}

            <Popover
              rootClassName="journeryinfo-drop-down"
              placement="right"
              content={
                type !== "milestone" && (
                  <JourneryInfo
                    infoData={
                      type === "intermediate_role"
                        ? nodemapData[0]?.intermediate_role
                        : nodemapData[0]?.targeted_role
                    }
                  />
                )
              }
              trigger="click"
              arrow={false}
            >
              <div
                className={`nodemap-option-icon ${
                  type === "intermediate_role" ? "user-icon" : ""
                }
                ${
                  nodemapData.length === 1 && nodemapData[0]?.progress === 100
                    ? "icon-complete"
                    : ""
                }

                ${
                  (nodemapData.length === 2 || nodemapData.length === 3) &&
                  nodemapData[1]?.progress === 100
                    ? "icon-complete"
                    : ""
                }

                
                
                ${type === "targeted_role" ? "star-milestone-option" : ""}`}
              >
                {type === "intermediate_role" ? (
                  <>
                    <CircularProgress
                      variant="determinate"
                      value={`${nodemapData[0]?.progress}`}
                      className="milestone-progress-circle"
                    />

                    <div className="milestone-progress-icon">
                      <UserIcon />
                    </div>
                  </>
                ) : type === "targeted_role" ? (
                  <Box className="star-progress-wrap">
                    <CircularProgress
                      variant="determinate"
                      value={`${nodemapData[0]?.progress}`}
                      className="star-progress-circle"
                    />

                    <div className="star-progress-icon">
                      <Icon icon="ph:star-fill" />
                    </div>
                  </Box>
                ) : (
                  <>
                    <CircularProgress
                      variant="determinate"
                      value={`${
                        nodemapData.length === 2 || nodemapData.length === 3
                          ? nodemapData[1]?.progress
                          : nodemapData[0]?.progress
                      }`}
                      className="milestone-progress-circle"
                    />

                    <div className="milestone-progress-icon">
                      <FlagIcon />
                    </div>
                  </>
                )}
              </div>
            </Popover>
            {type === "targeted_role" && (
              <p className="targeted-role end-role">{targetRole}</p>
            )}
          </div>
          <div
            className={`change-year-collapse ${
              type === "intermediate_role" ? "intermediate-role-collapse" : ""
            }`}
          >
            <p className="year-title">
              {/* {allUniqueYears[currentIndex - 1] === uniqueYears
                  ? ""
                  : uniqueYears} */}
              {currentRoleYear === allUniqueYears[currentIndex] && !currentIndex
                ? ""
                : allUniqueYears[currentIndex - 1] === uniqueYears
                ? ""
                : uniqueYears}
            </p>
            <div className="nodemap-collapse-wrpper">
              <p className="map-date">{formattedDate}</p>
              {nodemapData?.length !== 3 ? (
                <Grid container spacing={2}>
                  {nodemapData?.map((element, index) => (
                    <Grid item xs={nodemapData?.length === 2 ? 6 : 12}>
                      <MuiAccordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        className="custom-accordion-wrapper"
                      >
                        <MuiAccordionSummary
                          expandIcon={false}
                          aria-controls="panel1d-content"
                          className="collapse-item"
                        >
                          <div
                            className={`collapse-icon ${
                              type === "intermediate_role"
                                ? "user-icon"
                                : type === "targeted_role"
                                ? "star-milestone-option"
                                : ""
                            }
                            ${
                              element?.progress === 100 ? "completed" : ""
                            }                        
                            `}
                          >
                            {type === "intermediate_role" ? (
                              <UserIcon />
                            ) : type === "targeted_role" ? (
                              <Icon icon="ph:star-fill" />
                            ) : (
                              <FlagIcon />
                            )}
                          </div>
                          {element?.title.length >= 16 &&
                          nodemapData?.length > 1 ? (
                            <Tooltip
                              placement="top"
                              rootClassName="intermediate-tooltip"
                              title={element?.title}
                            >
                              <p>{element?.title}</p>
                            </Tooltip>
                          ) : (
                            <p>{element?.title}</p>
                          )}

                          <Icon
                            icon="mingcute:down-line"
                            className="collapse-arrow"
                          />
                        </MuiAccordionSummary>
                        <Button
                          disabled={element?.progress === 100 ? true : false}
                          className="action-btn action-adit-btn"
                          onClick={() => handleRespectiveOpenModal(element)}
                        >
                          <Edit />
                          Edit
                        </Button>
                        <MuiAccordionDetails className="custom-accordion-details">
                          <MilestoneCard
                            nodemapData={element}
                            nodemapDataId={nodemapDataId}
                            getNodemapDataList={getNodemapDataList}
                            index={index}
                            nodemapLength={nodemapData?.length}
                            haveMilestoneNumber={
                              milestoneRolesData.includes(element?.title)
                                ? milestoneRolesData.findIndex(
                                    (elm) => elm === element?.title
                                  )
                                : false
                            }
                            haveIntermediateNumber={
                              intermediateRolesData.includes(element?.title)
                                ? intermediateRolesData.findIndex(
                                    (elm) => elm === element?.title
                                  )
                                : false
                            }
                          />
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Row gutter={10} className="w-100">
                  {nodemapData?.map((element, index) => (
                    <Col span={getColumnSpan((index + 1).toString())}>
                      <MuiAccordion
                        expanded={threeExpanded === (index + 1).toString()}
                        onChange={() =>
                          handleThreeChange((index + 1).toString())
                        }
                        className="custom-accordion-wrapper"
                      >
                        <MuiAccordionSummary
                          expandIcon={false}
                          aria-controls="panel1d-content"
                          className="collapse-item"
                        >
                          <div
                            className={`collapse-icon 
                            ${
                              type === "targeted_role"
                                ? "star-milestone-option"
                                : ""
                            }
                            ${element?.progress === 100 ? "completed" : ""}
                            `}
                          >
                            {type === "intermediate_role" ? (
                              <UserIcon />
                            ) : type === "targeted_role" ? (
                              <Icon icon="ph:star-fill" />
                            ) : (
                              <FlagIcon />
                            )}
                          </div>
                          {element?.title.length >= 16 &&
                          nodemapData?.length > 1 ? (
                            <Tooltip
                              placement="top"
                              rootClassName="intermediate-tooltip"
                              title={element?.title}
                            >
                              <p>{element?.title}</p>
                            </Tooltip>
                          ) : (
                            <p>{element?.title}</p>
                          )}

                          <Icon
                            icon="mingcute:down-line"
                            className="collapse-arrow"
                          />
                        </MuiAccordionSummary>
                        <Button
                          className="action-btn action-adit-btn"
                          disabled={element?.progress === 100 ? true : false}
                          onClick={() => handleRespectiveOpenModal(element)}
                        >
                          <Edit />
                          Edit
                        </Button>
                        <MuiAccordionDetails className="custom-accordion-details">
                          <MilestoneCard
                            nodemapData={element}
                            nodemapDataId={nodemapDataId}
                            getNodemapDataList={getNodemapDataList}
                            index={index}
                          />
                          {threeExpanded === "1" && (
                            <Col span={24} className="p-0 pt-2">
                              <div
                                className="collapse-arrow-slide ms-auto"
                                onClick={() => handleThreeChange("2")}
                              >
                                <Icon icon="pajamas:arrow-right" />
                              </div>
                            </Col>
                          )}
                          {threeExpanded === "2" && (
                            <Row>
                              <Col span={12} className="p-0 pt-2">
                                <div
                                  className="collapse-arrow-slide"
                                  onClick={() => handleThreeChange("1")}
                                >
                                  <Icon icon="pajamas:arrow-left" />
                                </div>
                              </Col>
                              <Col span={12} className="p-0 pt-2">
                                <div
                                  className="collapse-arrow-slide ms-auto"
                                  onClick={() => handleThreeChange("3")}
                                >
                                  <Icon icon="pajamas:arrow-right" />
                                </div>
                              </Col>
                            </Row>
                          )}
                          {threeExpanded === "3" && (
                            <Col span={24} className="p-0 pt-2">
                              <div
                                className="collapse-arrow-slide"
                                onClick={() => handleThreeChange("2")}
                              >
                                <Icon icon="pajamas:arrow-left" />
                              </div>
                            </Col>
                          )}
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleMilestone;
