import React, { useEffect } from "react";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { useStepContext } from "../BeforeLogin/Context/activeStepContext";

const BottomBtnFooter = ({ onSubmit, providedPrevStep }) => {
  const { step, setStep } = useStepContext();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onSubmit]);

  const nextHandler = () => {
    onSubmit();
  };

  const backHandler = () => {
    if (providedPrevStep) {
      setStep(providedPrevStep);
    } else {
      setStep((prevStep) => prevStep - 1);
    }

  };

  return (
    <div className="bottom_action_btn">
      <div>
        {step !== 4 && (
          <button className="back_btn" onClick={backHandler}>
            {" "}
            <WestIcon /> Back{" "}
          </button>
        )}
      </div>
      <div className="next_btn_grp">
        <span className="press_enter">
          Press ENTER <SubdirectoryArrowLeftIcon />
        </span>
        <button onClick={() => nextHandler()}>
          Next <EastIcon />
        </button>
      </div>
    </div>
  );
};

export default BottomBtnFooter;
