import React from "react";

const Footer = ({ step }) => {
  return (
    <div className="footer-con">
      {step === 1 ? (
        <span className="footer_text">
          By continuing, you agree to our <br />{" "}
          <a
            href="https://mountain-vulture-6f0.notion.site/Terms-and-Conditions-3c35f42cab974ec4ad4d7ff3ecad0e7d"
            target="blank"
          >
            Terms of Service
          </a>{" "}
          &{" "}
          <a
            href="https://mountain-vulture-6f0.notion.site/Privacy-Policy-44158dcee5634111a4ceeef552beb591"
            target="blank"
          >
            Privacy Policy
          </a>
        </span>
      ) : (
        <span className="otp-footer">{/* © 2023 Project Momentum Ltd */}</span>
      )}
    </div>
  );
};

export default Footer;
