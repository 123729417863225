import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import CoachingMentoringImg from "../../../assets/images/Coaching-Mentoring.png";

import { Grid } from "@mui/material";

const CoachingMentoring = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <p className="tabs-content-title primary-content-title">Coming Soon</p>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          When you really need it, get support that’s tailored to your needs.
        </p>
        <p className="details-text">
          Whether it's strategic advice or help overcoming hurdles, our network
          of professionals will assist you with on-demand coaching, tailored to
          your career aspirations and needs.
        </p>
        <button className="join-btn">Become a member</button>
      </Grid>
      <Grid item xs={6} className="text-end">
        <img src={CoachingMentoringImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default CoachingMentoring;
