import React, { createContext, useContext, useState } from "react";

const LogoutContext = createContext();

export function LogoutProvider({ children }) {
  const [isLogout, setIsLogout] = useState(false);

  return (
    <LogoutContext.Provider value={{ isLogout, setIsLogout }}>
      {children}
    </LogoutContext.Provider>
  );
}

export function useLogoutContext() {
  return useContext(LogoutContext);
}
