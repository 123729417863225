import React, { useEffect, useState } from "react";
import Avatar from "../Dashboard/Componenets/Avatar";
import BottomBtnFooter from "../CustomComponents/BottomBtnFooter";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import JourneryInfo from "./JourneryInfo";
import { useStepContext } from "./Context/activeStepContext";
import { useLoadingContext } from "./Context/loadingContext";
import OnbordingLoader from "./OnbordingLoader";
import { toast } from "react-toastify";
import { useStorageContext } from "./Context/storageContext";

const RunwayRecommendations = ({ progressChange }) => {
  const { storageDetail } = useStorageContext();
  const { setIsLoading } = useLoadingContext();
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [runway, setRunway] = useState([]);
  const [message, setMessage] = useState("");
  const [responseError, setResponseError] = useState("");
  const [progress, setProgress] = useState(3);
  // eslint-disable-next-line no-unused-vars
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [openJourneryInfoIndex, setOpenJourneryInfoIndex] = useState(-1);
  const [userPrevStep, setUserPrevStep] = useState(null);
  const [selectedData, setSelectedData] = useState();

  const { setStep } = useStepContext();
  let timer = {};
  useEffect(() => {
    getRunwayRecommendationById();
    getQuestionThreeData();

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRunwayRecommendationById = () => {
    setIsLoading(true);
    const userId = storageDetail?.user_id || "";
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/runway-recommendation/${userId}`
    ).then((response) => {
      if (response.status === "S") {
        setRunway(response?.response?.runway_recommendation?.career_paths);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleDropClick = (index) => {
    setIsDropOpen((prevState) => !prevState);
    setOpenJourneryInfoIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handleSelectedDiv = (e, data, index) => {
    // setIsLoading(true);
    if (e.target.classList.contains("drop_arrow")) {
      e.stopPropagation();
      return;
    }
    setSelectedData(data);
    setSelectedJourney(index);
  };

  const onSubmit = () => {
    if (selectedData) {
      setIsLoaderOn(true);
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(timer);
            return 94;
          }
          return prevProgress + 3;
        });
      }, 1900);
      const selectedRunwayData = {
        user_id: storageDetail?.user_id,
        custom_runway: false,
        selected_user_journey: selectedData && selectedData,
      };
      postMethod(
        `${process.env.REACT_APP_API_URL}onboarding/selected-runway/`,
        selectedRunwayData
      ).then((response) => {
        if (response.status === "S") {
          setMessage(response.response.message);
          setStep((prevStep) => prevStep + 2);
          setProgress(100);
          setResponseError("");
          setIsLoading(false);
          setIsLoaderOn(false);
        } else {
          setIsLoaderOn(false);
          setResponseError("Something went wrong");
        }
      });
    } else {
      toast.warning(
        "Please select the user journey or go to setup custom runway.",
        { hideProgressBar: true, autoClose: 1000, toastId: "selectWarning" }
      );
    }
  };

  const handleSetupNow = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const getQuestionThreeData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === false
        ) {
          // clear_vision_for_next_career_runway == false
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            setUserPrevStep(9); // Intermediate
          } else {
            // response?.response?.questionnaire?.career_runway_duration < 24
            setUserPrevStep(8); // Q3
          }
        } else {
          // clear_vision_for_next_career_runway == true
          setUserPrevStep(10); // Q4
        }
      }
    });
  };

  return (
    <>
      <div className="journery_pannel">
        {isLoaderOn ? (
          <OnbordingLoader progress={progress} responseError={responseError} />
        ) : (
          <>
            <div className="journey-data-wrapper">
              <div className="text-center">
                <h2 className="heading_text">
                  Select a career runway that fits your ambition
                </h2>
              </div>
              {message && <div>{message}</div>}
              <div className="journery_pannel_list">
                {runway?.map((item, index) => {
                  return (
                    <div
                      className={`single_journery_block ${
                        selectedJourney === index + 1 ? "selected" : ""
                      }`}
                      onClick={(e) => handleSelectedDiv(e, item, index + 1)}
                    >
                      <h3>{`0${index + 1}`}</h3>
                      <div className="journery_thread">
                        <Avatar
                          labelText={item?.current_role?.role}
                          optionalClassName="has_side_label first_label"
                        />
                        {item?.intermediate_roles?.map((element, index) => (
                          <Avatar
                            key={index}
                            labelText={element?.role}
                            optionalClassName="has_side_label"
                          />
                        ))}
                        <div className="end_journery">
                          <Avatar
                            labelText={item?.targeted_role?.role}
                            optionalClassName="has_side_label last_label"
                            isDropOpen={index === openJourneryInfoIndex}
                            handleDropClick={() => handleDropClick(index)}
                          />
                          {index === openJourneryInfoIndex && (
                            <JourneryInfo infoData={item?.targeted_role} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="single_journery_block custom_runway">
                  <h3>Create a custom runway</h3>
                  <button
                    className="btn primary-btn"
                    onClick={() => handleSetupNow()}
                  >
                    Setup now
                  </button>
                </div>
              </div>
            </div>
            <div className="journey-data-footer">
              <BottomBtnFooter
                progressChange={progressChange}
                onSubmit={onSubmit}
                providedPrevStep={userPrevStep}
                // providedPrevStep= {intermediate / Q3 / Q4}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RunwayRecommendations;
