import React from "react";

const JourneryInfo = ({ infoData }) => {
  return (
    <>
      <div className="journery_info_card">
        <ul>
          <li>
            <span className="title">Sector</span>
            <span className="info">{infoData?.sector}</span>
          </li>
          <li>
            <span className="title">Function</span>
            <span className="info">{infoData?.function}</span>
          </li>
          <li>
            <span className="title">Company type</span>
            <span className="info">{infoData?.company_type}</span>
          </li>
          <li>
            <span className="title">Seniority</span>
            <span className="info">{infoData?.rank}</span>
          </li>
          <li>
            <span className="title">Domain</span>
            <span className="info">{infoData?.domain}</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default JourneryInfo;
