import React from "react";
import { Avatar, Card, Row, Space, Tag, Typography } from "antd";
import { ReactComponent as Users } from "../../../assets/images/users.svg";
import "../../CSS/EducationCard.css";

const { Meta } = Card;

const EducationCard = () => (
  <Card
    className="education-card-container"
    cover={
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />
    }
  >
    <Row
      wrap={false}
      justify={"space-between"}
      align={"middle"}
      className="education-card-top-header"
    >
      <Tag bordered={false}>Workshop</Tag>
      <Typography.Paragraph>August 8, 2023</Typography.Paragraph>
    </Row>
    <Meta
      className="education-card-meta-container"
      title="Mastering the maze: navigating organizational politic"
      description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore…"
    />
    <Space
      size={6}
      align="center"
      style={{ marginTop: "10px", marginLeft: "5px" }}
      className="w-100"
    >
      <Avatar className="education-card-author-avatar"></Avatar>
      <Typography className="education-card-author-title">
        Dr. Julian Beaumont
      </Typography>
    </Space>
    <Space
      size={10}
      align="center"
      style={{ marginTop: "14px" }}
      className="w-100"
    >
      <Avatar
        shape="square"
        className="education-card-footer-avatar"
        icon={<Users />}
      ></Avatar>
      <Space size={4} align="left" direction="vertical">
        <Typography className="education-card-footer-heading">
          Who is this ideal for?
        </Typography>
        <Typography className="education-card-footer-title">
          Mid-Senior Level leaders
        </Typography>
      </Space>
    </Space>
  </Card>
);
export default EducationCard;
