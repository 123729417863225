import React, { useContext, useState } from "react";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { postMethod } from "../Api'sCall/Apicalling";
import { OnBoardingContext } from "./Context/onBoardingContext";
import LinkedinBtn from "../CustomComponents/LinkedinBtn";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useStorageContext } from "./Context/storageContext";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "var(--primary-color)",
    }}
    spin
  />
);

const CareerOverview = () => {
  const { storageDetail } = useStorageContext();
  const [apiError, setApiError] = React.useState();
  const [isFocus, setFocus] = React.useState({
    linkedin_url: "",
    linkedin_data: "",
    resume: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { setStep } = useContext(OnBoardingContext);

  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  const validationSchema = Yup.object().shape({
    linkedin_url: Yup.string().matches(regex, "Enter valid link"),
    linkedin_data: Yup.mixed().test(
      5000000,
      "File size exceeds the maximum limit.",
      (value) => !value || (value && value.size <= 5000000)
    ),
    resume: Yup.mixed()
      .required("Resume is required")
      .test(
        2000000,
        "Max file size - 2 mb",
        (value) => !value || (value && value.size <= 2000000)
      ),
  });

  const { handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("resume", data.resume);
    formData.append("user_id", storageDetail?.user_id);

    postMethod(
      `${process.env.REACT_APP_API_URL}onboarding/upload-resume/`,
      formData
    ).then((response) => {
      if (response.status === "S") {
        setStep((prevStep) => prevStep + 1);
        setIsLoading(false);
      } else {
        setApiError(response.errorData.response.data.message);
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      <div className="career_login_block">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ width: "100%" }}>
            <div className="otp-sub-text">
              <div className="section_heading">
                <h2>Career Overview</h2>
              </div>
            </div>
            <div className="career-container">
              <div className="desktop_elem w-100">
                <LinkedinBtn />
              </div>
              <div className="mobile_elem w-100">
                <Controller
                  control={control}
                  name="linkedin_url"
                  render={({
                    field: { value, name },
                    fieldState: { error },
                  }) => (
                    <div className="linkedin-input-con">
                      <Icon
                        icon={"lucide:linkedin"}
                        className={
                          error
                            ? "failure-icon link-icon"
                            : value && isFocus?.linkedin_url
                            ? "sucess-icon link-icon"
                            : "link-icon"
                        }
                      />
                      <Icon
                        icon={"akar-icons:link-chain"}
                        className={
                          error
                            ? "failure-icon chain-icon"
                            : value && isFocus?.linkedin_url
                            ? "sucess-icon chain-icon"
                            : "chain-icon"
                        }
                      />
                      <input
                        type="text"
                        className={
                          error
                            ? "outline-failure career-input career-input-e"
                            : value && isFocus?.linkedin_url
                            ? "outline-success career-input"
                            : "career-input"
                        }
                        placeholder="LinkedIn Profile URL"
                        onChange={({ target: { value } }) => {
                          setValue(name, value, { shouldValidate: true });
                          setFocus((prev) => ({ ...prev, [name]: true }));
                        }}
                        onBlur={() => {
                          setFocus((prev) => ({ ...prev, [name]: false }));
                        }}
                        name={name}
                      ></input>
                      <div className="error-custom-career">
                        {error?.message}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="linkedin_data"
                  render={({
                    field: { value, name },
                    fieldState: { error },
                  }) => (
                    <div
                      onClick={() => {
                        document.getElementById("linkedin_data").click();
                      }}
                      onBlur={() => {
                        setFocus((prev) => ({ ...prev, [name]: true }));
                      }}
                      className={
                        error
                          ? "outline-failure upload-resume"
                          : value && isFocus?.linkedin_data
                          ? "outline-success upload-resume"
                          : "upload-resume"
                      }
                    >
                      <input
                        type="file"
                        hidden={true}
                        accept=".doc,.docx,application/pdf"
                        id="linkedin_data"
                        onChange={({ target: { files } }) => {
                          setValue(name, files[0], { shouldValidate: true });
                          setFocus((prev) => ({ ...prev, [name]: true }));
                          document.getElementById("linkedin_data").blur();
                        }}
                        onBlur={() => {
                          setFocus((prev) => ({ ...prev, [name]: false }));
                        }}
                      />
                      <label htmlFor="">
                        {!value ? (
                          <div className="download-con">
                            <span className="download-icon">
                              <Icon icon="lucide:download" />
                            </span>
                            <span className="download-text">Upload your</span>
                            <div className="linkedin-con">
                              <span className="download-icon-linkedin">
                                <Icon icon="devicon-plain:linkedin-wordmark" />
                              </span>
                            </div>
                            <span className="download-text">data</span>
                          </div>
                        ) : (
                          <div className="uploaded-file">
                            <Icon
                              icon="mingcute:download-2-line"
                              className="downloaded-icon"
                            />
                            {value?.name}
                          </div>
                        )}
                      </label>
                      <div className="error-custom-career-file">
                        {error?.message}
                      </div>
                    </div>
                  )}
                />
                <div className="question-con">
                  <span className="download-icon">
                    <Icon icon="ph:question-bold" />
                  </span>
                  <span className="Where-can-I-find-the">
                    Where can I find the data?{" "}
                    <span
                      className="text-style-1"
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/help/linkedin/answer/a1339364/download-your-account-data?lang=en"
                        );
                      }}
                    >
                      here
                    </span>
                  </span>
                </div>
              </div>
              <div className="divider"></div>
              <Controller
                control={control}
                name="resume"
                render={({ field: { value, name }, fieldState: { error } }) => (
                  <div
                    className={
                      error || apiError
                        ? "outline-failure upload-resume"
                        : value && isFocus?.resume
                        ? "outline-success upload-resume"
                        : "upload-resume"
                    }
                  >
                    <input
                      type="file"
                      id="resume"
                      accept=".doc,.docx,application/pdf"
                      hidden={true}
                      onChange={({ target: { files } }) => {
                        setApiError();
                        setValue(name, files[0], { shouldValidate: true });
                        setFocus((prev) => ({ ...prev, [name]: true }));
                      }}
                      onBlur={() => {
                        setFocus((prev) => ({ ...prev, [name]: false }));
                      }}
                    ></input>
                    <label htmlFor="resume" className="me-2">
                      {!value ? (
                        <div className="download-con">
                          <span className="download-icon">
                            <Icon icon="lucide:download" />
                          </span>
                          <span className="resume_file_text">
                            Upload your Resume
                          </span>
                        </div>
                      ) : (
                        <div
                          className={
                            apiError
                              ? "uploaded-file file-error"
                              : "uploaded-file"
                          }
                        >
                          <Icon
                            icon="mingcute:download-2-line"
                            className="downloaded-icon"
                          />
                          {value?.name}
                        </div>
                      )}
                    </label>
                    <div className="erro-text">
                      {apiError ? apiError : error?.message}
                    </div>
                    {isLoading && <Spin indicator={antIcon} />}
                  </div>
                )}
              />
              <button
                type="submit"
                className="primary_btn"
                disabled={isLoading}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CareerOverview;
