import React from "react";
import { Grid, Paper } from "@mui/material";
import { Icon } from "@iconify/react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Input } from "antd";
import { ReactComponent as BankCard } from "../../../assets/images/bank-card.svg";
import { ReactComponent as Users } from "../../../assets/images/user-group.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/link.svg";
import { ReactComponent as Hourglass } from "../../../assets/images/hourglass.svg";
import { ReactComponent as Setting } from "../../../assets/images/settings.svg";

const Support = () => {
  const columns = [
    {
      date: "21/08/23",
      id: 31321,
      subject: "Title",
      status: "Pending",
      estimate: "24 hours",
    },
    {
      date: "21/08/23",
      id: 31321,
      subject: "Title",
      status: "Completed",
      estimate: "24 hours",
    },
    {
      date: "21/08/23",
      id: 31321,
      subject: "Title",
      status: "Completed",
      estimate: "24 hours",
    },
    {
      date: "21/08/23",
      id: 31321,
      subject: "Title",
      status: "Completed",
      estimate: "24 hours",
    },
  ];

  const headColumns = [
    { label: "Date" },
    {
      label: "Ticket ID",
    },
    {
      label: "Subject",
    },
    {
      label: "Status",
    },
    {
      label: "Estimated Response Time",
    },
  ];
  const supportArticles = [
    {
      icon: <Icon icon="mingcute:flash-line" color="#7ebdff" />,
      bgColor: "rgb(126, 189, 255, .15)",
      title: "Getting Started",
      disc: "Sort description",
    },
    {
      icon: <Setting />,
      bgColor: "rgb(244, 245, 246)",
      title: "Account",
      disc: "Sort description",
    },
    {
      icon: <BankCard />,
      bgColor: "rgb(54, 216, 69, 0.15)",
      title: "Billing",
      disc: "Sort description",
    },
    {
      icon: <LinkIcon />,
      bgColor: "rgb(240, 126, 255, 0.15)",
      title: "Integrations",
      disc: "Sort description",
    },
    {
      icon: <Icon icon="fa-solid:fingerprint" color="#ad79ff" />,
      bgColor: "rgb(173, 121, 255, 0.15)",
      title: "Trust & Safety",
      disc: "Sort description",
    },
    {
      icon: <Icon icon="octicon:question-16" color="#ffba70" />,
      bgColor: "rgb(255, 186, 112, 0.15)",
      title: "FAQ",
      disc: "Sort description",
    },
    {
      icon: <Users />,
      bgColor: "rgb(255, 114, 40, 0.15)",
      title: "Community",
      disc: "Sort description",
    },
    {
      icon: <Icon icon="mingcute:palette-2-line" color="#ff6f6f" />,
      bgColor: "rgb(255, 111, 111, 0.15)",
      title: "Interfaces",
      disc: "Sort description",
    },
  ];
  return (
    <>
      <div className="card-outer-wrap">
        <div className="peferral-warpper">
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              textAlign={{ md: "left", xs: "center" }}
            >
              <p className="wrapTitle mb-0">
                Experience an issue or have a question? Our team is ready to
                assist.
              </p>
            </Grid>
            <Grid
              item
              lg={8}
              md={5}
              xs={12}
              textAlign={{ md: "right", xs: "center" }}
            >
              <Button className="small-btn primary-btn">
                Submit Support Ticket
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="card-outer-wrap">
        <div className="card-warpper">
          <div className="card-header pb-4 mb-4 pt-1 px-3">
            <Grid container spacing={2}>
              <Grid item lg={8.5} md={7} xs={12}>
                <p className="wrapTitle mb-0">Support Articles</p>
                <p className="card-description">
                  Browse our comprehensive collection of articles to find
                  solutions, tips, and insights tailored to your needs.
                </p>
              </Grid>
              <Grid item lg={3.5} md={5} xs={12} className="skill_role_side">
                <Input
                  className="custom_input"
                  placeholder="Search for Support Tickets…"
                />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={1}>
            {supportArticles &&
              supportArticles.map((arti, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <div className="article-wrap">
                    <div
                      className="article-icon"
                      style={{
                        backgroundColor: arti.bgColor,
                      }}
                    >
                      {arti.icon}
                    </div>
                    <h3 className="wrapTitle mb-0">{arti.title}</h3>
                    <p className="regular-title">{arti.disc}</p>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>

      <div className="card-outer-wrap">
        <div className="card-warpper">
          <p className="wrapTitle">Support Tickets</p>

          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            className="common-table-wrapper"
          >
            <TableContainer sx={{ maxHeight: 480 }}>
              <Table
                stickyHeader
                border="0"
                aria-label="sticky table"
                className="common-table"
              >
                <TableHead>
                  <TableRow>
                    {headColumns.map((column, index) => (
                      <>
                        {index === headColumns.length - 1 ? (
                          <TableCell align="right">{column.label}</TableCell>
                        ) : (
                          <TableCell>{column.label}</TableCell>
                        )}
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {columns.map((cel) => (
                    <TableRow>
                      <TableCell>{cel.date}</TableCell>
                      <TableCell>{cel.id}</TableCell>
                      <TableCell width={"100%"}>{cel.subject}</TableCell>
                      <TableCell>
                        <span
                          className={
                            cel.status === "Pending"
                              ? "pending-tag"
                              : "completed-tag"
                          }
                        >
                          {cel.status === "Pending" ? (
                            <Hourglass />
                          ) : (
                            <Icon icon="iconamoon:check" />
                          )}
                          {cel.status}
                        </span>
                      </TableCell>
                      <TableCell align="right">{cel.estimate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Support;
