import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import ExtraMotivationImg from "../../../assets/images/extra-motiviation.png";

import { Grid } from "@mui/material";

const ExtraMotivation = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <p className="tabs-content-title primary-content-title">Coming Soon</p>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          Use AI-backed guidance to help nudge you to your next milestone and
          identify the key steps you need to take.
        </p>
        <p className="details-text">
          Engage with fellow members in your industry or those who share your
          interests to exchange experiences, seek guidance, and explore career
          possibilities through our community.
        </p>

        <button className="join-btn">Get Started</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img src={ExtraMotivationImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default ExtraMotivation;
