import React, { useEffect, useState } from "react";
import RangeSlider from "../../CustomComponents/RangeSlider/RangeSlider";
import MultiSelectTag from "../../CustomComponents/MultiSelectTag ";
import CustomSideSection from "../../CustomComponents/CustomSideSection";
import BottomBtnFooter from "../../CustomComponents/BottomBtnFooter";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import { useStepContext } from "../Context/activeStepContext";
import { useLoadingContext } from "../Context/loadingContext";
import { useStorageContext } from "../Context/storageContext";

const QuestionsOne = () => {
  const multiSelectOptions = [
    "Visualise my career journey",
    "Personalise my career journey",
    "Engage with the community",
    "Set clear goals",
    "Mentoring and coaching",
    "Execute on my plan",
  ];
  const { storageDetail } = useStorageContext();
  const [values, setValues] = useState();
  const [optionError, setOptionError] = useState("");
  const { step, setStep } = useStepContext();
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-1/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setValues(
          response.response.questionnaire !== null
            ? response.response.questionnaire
            : {
                satisfaction_with_current_role: 5,
                confidence_about_next_career_runway: 5,
                hoping_to_get_out_of_youe: [],
              }
        );
      } else {
        setValues({
          satisfaction_with_current_role: 5,
          confidence_about_next_career_runway: 5,
          hoping_to_get_out_of_youe: [],
        });
      }
    });
  }, []);

  const getValue = (question, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [question]: value,
    }));
  };
  const getSelectedTag = (tag) => {
    setValues((prevValues) => ({
      ...prevValues,
      hoping_to_get_out_of_youe: tag,
    }));
    setOptionError("");
  };

  const onSubmit = () => {
    if (values.hoping_to_get_out_of_youe.length > 0) {
      setIsLoading(true);
      postMethod(
        `${process.env.REACT_APP_API_URL}onboarding/questionnaire-1/`,
        {
          user_id: storageDetail?.user_id,
          ...values,
        }
      ).then((response) => {
        if (response.status === "S") {
          setStep((prevStep) => prevStep + 1);
          setIsLoading(false);
        } else {
        }
      });
    } else {
      setOptionError("Please select at least one option.");
    }
  };

  return (
    <>
      <div className="section_wrapper align-content-start">
        <CustomSideSection
          step={step}
          heading={"Personalised For You"}
          subHeading={
            "Your answers here help us tailor your experience on youe to help you design your perfect career"
          }
        />
        <div className="user_from_data">
          <div className="question_data_blocks">
            <div className="que_block">
              <h2> How satisfied are you with your current role?</h2>
              <div className="start_end_points">
                <span>Extremely unsatisfied</span>
                <span>Extremely satisfied</span>
              </div>
              {values && (
                <RangeSlider
                  question="satisfaction_with_current_role"
                  getValue={getValue}
                  defaultRange={values.satisfaction_with_current_role}
                />
              )}
            </div>
            <div className="que_block center_que">
              <h2>How confident do you feel about your next career runway?</h2>
              <div className="start_end_points">
                <span>Extremely unsatisfied</span>
                <span>Extremely satisfied</span>
              </div>
              {values && (
                <RangeSlider
                  question="confidence_about_next_career_runway"
                  getValue={getValue}
                  defaultRange={values.confidence_about_next_career_runway}
                />
              )}
            </div>
            <div className="que_block option_block">
              <h2> What are you hoping to get out of youe?</h2>
              {values && (
                <>
                  {optionError && (
                    <span className="error-msg-step ms-0 mb-2 d-block">
                      {optionError}
                    </span>
                  )}
                  <MultiSelectTag
                    options={multiSelectOptions}
                    getSelectedTag={getSelectedTag}
                    selctedTags={values.hoping_to_get_out_of_youe}
                  />
                </>
              )}
            </div>
          </div>
          <BottomBtnFooter onSubmit={onSubmit} />
        </div>
      </div>
    </>
  );
};
export default QuestionsOne;
