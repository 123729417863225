import { Tag } from "antd";
import React, { useEffect, useState } from "react";

const SingleSelectTag = ({ options, getTagValue, value }) => {
  const [selectedTag, setSelectedTag] = useState(value);

  useEffect(() => {
    setSelectedTag(value);
  }, [value]);

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    getTagValue(tag);
  };

  return (
    <div className="stream_option">
      {options.map((option) => (
        <Tag
          key={option}
          onClick={() => handleTagClick(option)}
          className={selectedTag.includes(option) ? "selected" : ""}
        >
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </Tag>
      ))}
    </div>
  );
};

export default SingleSelectTag;
