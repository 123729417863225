import React from "react";
import { Card, Row, Tag, Typography } from "antd";
import "../../CSS/LearnCard.css";
import moment from "moment";
const { Meta } = Card;

const LearnCard = ({ cardData }) => (
  <Card
    className="learn-card-container"
    cover={
      <img
        alt="example"
        src={
          "https://geekflare.com/wp-content/uploads/2023/03/Placeholder-Image-Generator--1200x385.png"
        }
        onClick={() =>
          window.open(cardData?.resource_link, "_blank", "noreferrer")
        }
      />
    }
  >
    <Row
      justify={"space-between"}
      align={"middle"}
      className="learn-card-top-header"
    >
      {cardData?.tag?.length > 0 ? (
        cardData?.tag?.map((item) => <Tag bordered={false}>{item}</Tag>)
      ) : (
        <Tag bordered={false}>{"Coaching"}</Tag>
      )}
      <Typography.Paragraph>
        {cardData?.created_at
          ? moment(cardData?.created_at, "YYYY-MM-DD").format("MMMM DD, YYYY")
          : "August 10, 2023"}
      </Typography.Paragraph>
    </Row>
    <Meta
      className="learn-card-meta-container"
      title={cardData?.title ? cardData?.title : "Post Headline Title"}
      description={
        cardData?.description
          ? cardData?.description
          : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore…"
      }
    />
  </Card>
);
export default LearnCard;
