import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import { Input, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import BottomBtnFooter from "../../CustomComponents/BottomBtnFooter";
import CustomSideSection from "../../CustomComponents/CustomSideSection";
import { useStepContext } from "../Context/activeStepContext";
import { useProfileContext } from "../Context/profileSetupContext";
import { useLoadingContext } from "../Context/loadingContext";
import { useStorageContext } from "../Context/storageContext";

const QuestionsTwoNew = ({ email }) => {
  const { profileData, setProfileData } = useProfileContext();
  const { setIsLoading } = useLoadingContext();
  // eslint-disable-next-line no-unused-vars
  const [values, setValues] = useState();
  const [isFocus, setFocus] = useState({
    sector: "",
    company_type: "",
    function: "",
    role: "",
    domain: "",
    rank: "",
  });
  const { storageDetail } = useStorageContext();
  const [industryOptions, setIndustryOptions] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [industryVal, setIndustryVal] = useState("");
  const [functionVal, setFunctionVal] = useState("");
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [rankOptions, setRankOptions] = useState([]);
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [selectOpenState, setSelectOpenState] = useState({
    sector: false,
    company_type: false,
    function: false,
    role: false,
    rank: false,
    domain: false,
  });
  const { step, setStep } = useStepContext();

  const initialIndustryVal = profileData?.sector;
  const initialFunctionVal = profileData?.function;
  useEffect(() => {
    setIsLoading(true);
    getMethod(`${process.env.REACT_APP_API_URL}industries`).then((response) => {
      if (response.status === "S") {
        setIndustryOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}functions`).then((response) => {
      if (response.status === "S") {
        setFunctionOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialIndustryVal || industryVal) {
      setIsLoading(true);
      //make it dynamic once get the data now passing Technology
      getMethod(
        `${process.env.REACT_APP_API_URL}industries/?q=${industryVal}`
      ).then((response) => {
        if (response.status === "S") {
          setCompanyTypeOptions(response?.response?.result?.company_type);
          setFunctionOptions(response?.response?.result?.functions);
          setIsLoading(false);
        } else {
          setCompanyTypeOptions([]);
          setFunctionOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndustryVal, industryVal]);

  useEffect(() => {
    if (initialFunctionVal || functionVal) {
      //make it dynamic once get the data now passing IT
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}functions/?q=${functionVal}`
      ).then((response) => {
        if (response.status === "S") {
          setDomainsOptions(response?.response?.result?.domains);
          setRankOptions(response?.response?.result?.rank);
          setIsLoading(false);
        } else {
          setDomainsOptions([]);
          setRankOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFunctionVal, functionVal]);

  const validationSchema = Yup.object().shape({
    sector: Yup.string()
      .required("Industry is required")
      .notOneOf(["Select Industry"], "Please select a Industry")
      .nullable(),
    company_type: Yup.string()
      .required("Company Type is required")
      .notOneOf(["Select Company Type"], "Please select a company type")
      .nullable(),
    function: Yup.string()
      .required("Function is required")
      .notOneOf(["Select Function"], "Please select a function")
      .nullable(),
    role: Yup.string()
      .required("Role is required")
      .trim()
      .matches(/[a-zA-Z]+/, "Role must contain at least one alphabet character")
      .min(1, "Role must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    domain: Yup.string()
      .required("Domain is required")
      .notOneOf(["Select domain"], "Please select a domain")
      .nullable(),
    rank: Yup.string()
      .required("Seniority is required")
      .notOneOf(["Select Seniority"], "Please select a seniority")
      .nullable(),
  });

  const toggleSelectOpen = (name) => {
    setSelectOpenState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const getArrowIcon = (name) => {
    return selectOpenState[name]
      ? "iconamoon:arrow-up-2"
      : "iconamoon:arrow-down-2";
  };

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sector: "Select Industry",
      company_type: "Select Company Type",
      function: "Select Function",
      // role: "Select Role",
      rank: "Select Seniority",
      domain: "Select domain",
    },
  });

  useEffect(() => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-2/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setValues(
          response.response.questionnaire !== null
            ? response.response.questionnaire
            : profileData
        );
        setProfileData(response.response.questionnaire);
        reset({
          sector: response?.response?.questionnaire?.sector || null,
          company_type: response?.response?.questionnaire?.company_type || null,
          function: response?.response?.questionnaire?.function || null,
          role: response?.response?.questionnaire?.role || null,
          rank: response?.response?.questionnaire?.rank || null,
          domain: response?.response?.questionnaire?.domain || null,
        });
      } else {
        setValues({
          sector: "",
          company_type: "",
          function: "",
          role: "",
          rank: "",
          domain: "",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setProfileData(data);
    setIsLoading(true);
    postMethod(`${process.env.REACT_APP_API_URL}onboarding/questionnaire-2/`, {
      user_id: storageDetail?.user_id,
      career_runway_role_description: { ...data },
    }).then((response) => {
      if (response.status === "S") {
        setIsLoading(false);
        setStep((prevStep) => prevStep + 1);
      } else {
      }
    });
  };
  const checkingAll = () => {
    const values = profileData;
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && !values[key]) {
        return true;
      }
    }
    return false;
  };

  const sector = ["Sector 1", "Sector 2", "Sector 3"];

  return (
    <>
      <div className="section_wrapper">
        <CustomSideSection
          step={step}
          heading={"Personalised For You"}
          subHeading={
            "Your answers here help us tailor your experience on youe to help you design your perfect career"
          }
        />
        <div className="user_from_data height_auto">
          <div className="career_stream_info">
            <div className="signin-con">
              <div className="heading">
                <h2 className="star-title">
                  What best describes your current employment?
                </h2>
                <p className="form-regular-title">
                  Your data has been automatically populated from your resume.
                  <br className="d-xxl-block d-none" />
                  Please review and make any necessary corrections.
                </p>
              </div>
              {!checkingAll() && (
                <div className="entries-appear-to-be-incorrect">
                  Your entries appear to be incorrect. Please review and update
                  the form.
                </div>
              )}
              <div className="signin-divide">
                <div className="signin-field-label">
                  <label>Role</label>
                  <Controller
                    control={control}
                    name="role"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Input
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.role
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                          }}
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          value={value}
                        />
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="signin-field-label">
                  <label>Industry</label>
                  <Controller
                    control={control}
                    name="sector"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            companyTypeOptions?.length !== 0
                              ? value
                              : "Select Industry"
                          }
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.sector
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            toggleSelectOpen("sector");
                          }}
                          suffixIcon={
                            <Icon
                              icon={getArrowIcon("sector")}
                              className={
                                selectOpenState["sector"]
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Industry"
                          placement="bottomLeft"
                          options={industryOptions?.map((sec) => ({
                            label: sec,
                            value: sec,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                            setIndustryVal(value);
                          }}
                        ></Select>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div
                  className={`signin-field-label ${
                    companyTypeOptions?.length === 0 ? "is-disabled" : ""
                  }`}
                >
                  <label>Company Type</label>
                  <Controller
                    control={control}
                    name="company_type"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={
                            companyTypeOptions?.length === 0 ? true : false
                          }
                          value={value}
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.company_type
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            toggleSelectOpen("company_type");
                          }}
                          suffixIcon={
                            <Icon
                              icon={getArrowIcon("company_type")}
                              className={
                                selectOpenState["company_type"]
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Sector"
                          placement="bottomLeft"
                          options={companyTypeOptions?.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                          }}
                        ></Select>
                        {checkingAll() && companyTypeOptions?.length !== 0 && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="signin-field-label">
                  <label>Function</label>
                  <Controller
                    control={control}
                    name="function"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            domainsOptions?.length !== 0
                              ? value
                              : "Select Function"
                          }
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.function
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            toggleSelectOpen("function");
                          }}
                          suffixIcon={
                            <Icon
                              icon={getArrowIcon("function")}
                              className={
                                selectOpenState["function"]
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Function"
                          placement="bottomLeft"
                          options={functionOptions?.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                            setFunctionVal(value);
                          }}
                        ></Select>
                        {checkingAll() && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div
                  className={`signin-field-label ${
                    domainsOptions?.length === 0 ? "is-disabled" : ""
                  }`}
                >
                  <label>Domain</label>
                  <Controller
                    control={control}
                    name="domain"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={domainsOptions?.length === 0 ? true : false}
                          value={value}
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.domain
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            toggleSelectOpen("domain");
                          }}
                          suffixIcon={
                            <Icon
                              icon={getArrowIcon("domain")}
                              className={
                                selectOpenState["domain"]
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Seniority"
                          placement="bottomLeft"
                          options={domainsOptions?.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                          }}
                        ></Select>
                        {checkingAll() && domainsOptions?.length !== 0 && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div
                  className={`signin-field-label ${
                    rankOptions?.length === 0 ? "is-disabled" : ""
                  }`}
                >
                  <label>Seniority</label>
                  <Controller
                    control={control}
                    name="rank"
                    rules={{
                      required: true,
                      message: "required",
                    }}
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className="input-con-signin">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          disabled={rankOptions?.length === 0 ? true : false}
                          sx={{ m: 1, width: "4.4cm", height: "35px" }}
                          className={
                            error
                              ? !checkingAll()
                                ? "outline-failure-shadow-none select-design-signin-ant"
                                : "outline-failure select-design-signin-ant"
                              : value && isFocus?.rank
                              ? "outline-success select-design-signin-ant"
                              : "select-design-signin-ant"
                          }
                          popupClassName="custom-select-dropdown"
                          error={error?.message}
                          onDropdownVisibleChange={() => {
                            toggleSelectOpen("rank");
                          }}
                          suffixIcon={
                            <Icon
                              icon={getArrowIcon("rank")}
                              className={
                                selectOpenState["rank"]
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          placeholder="Select Seniority"
                          placement="bottomLeft"
                          options={rankOptions?.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          onChange={(value) => {
                            setFocus((prev) => ({ ...prev, [name]: true }));
                            setValue(name, value, { shouldValidate: true });
                          }}
                        ></Select>
                        {checkingAll() && rankOptions?.length !== 0 && (
                          <div className="error-custom-signin">
                            {error?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>

                <button className="primary_btn mobile_elem">Continue</button>
              </div>
            </div>
          </div>
          <BottomBtnFooter onSubmit={handleSubmit(onSubmit)} />
        </div>
      </div>
    </>
  );
};

export default QuestionsTwoNew;
