import React, { useEffect, useState } from "react";
import { getMethod } from "../Api'sCall/Apicalling";
import NodeMap from "./nodeMap/NodeMap";
import PricingModal from "./profile/PricingModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoadingContext } from "../BeforeLogin/Context/loadingContext";
import { toast } from "react-toastify";
import { useStorageContext } from "../BeforeLogin/Context/storageContext";
import { useStepContext } from "../BeforeLogin/Context/activeStepContext";

function DashboardSingleJourney() {
  const { storageDetail } = useStorageContext();
  const { setStep } = useStepContext();
  const [payment, setPayment] = useState(
    localStorage.getItem("payment") ? localStorage.getItem("payment") : true
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (!payment) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("dashboard-body-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("dashboard-body-hidden");
    }
  }, [payment]);

  useEffect(() => {
    setIsLoading(true);
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}`
      ).then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } else {
      navigate("/login");
      setStep(1);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname === "/payment/success") {
      localStorage.setItem("payment", true);
      navigate("/dashboard");
    } else if (pathname === "/payment/failure") {
      localStorage.setItem("payment", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("payment")]);

  useEffect(() => {
    getCurrentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentPlan = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/current-plan/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          if (localStorage.getItem("subscribe") === "true") {
            setPayment(false);
            localStorage.setItem("subscribe", false);
          } else {
            setPayment(true);
          }
          setIsLoading(false);
        } else {
          setPayment(false);
          setIsLoading(false);
        }
      });
    } else {
    }
  };

  const checkSubscription = (message) => {
    toast.warning(message, {
      hideProgressBar: true,
      autoClose: 2000,
    });
  };

  return (
    <>
      <div className="dashboard-view">
        <>
          <NodeMap />
          {!payment && (
            <PricingModal
              setPayment={setPayment}
              checkSubscription={checkSubscription}
            />
          )}
        </>
      </div>
    </>
  );
}

export default DashboardSingleJourney;
