import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Input, Select, DatePicker, Space } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getMethod, patchTripdata } from "../../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";
import ConfirmationPopup from "./ConfirmationPopup";
import "./nodemapModals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const editReasonsOptions = [
  { value: "Changed Priorities", label: "Changed Priorities" },
  { value: "New Opportunities", label: "New Opportunities" },
  { value: "Job Market Changes", label: "Job Market Changes" },
  { value: "renaming", label: "Renaming" },
];

const selectSector = ["Sector 1", "Sector 2", "Sector 3"];

const AddTargetedRoleModal = ({
  isOpen,
  handleModalOpenClose,
  isAdd,
  modalData,
  nodemapId,
  setNodemapData,
}) => {
  const { storageDetail } = useStorageContext();
  const [isCompleted, setIsCompleted] = useState(0);
  const [apiError, setApiError] = React.useState();
  const { setIsLoading } = useLoadingContext();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentMode, setCurrentMode] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [tempData, setTempData] = useState({});

  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const reasonValidation = isTitleChanged
    ? { reason: yup.string().required("Reason for edit is required") }
    : {};

  const validationSchemaForEdit = yup.object().shape({
    ...reasonValidation,
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Minimum 1 characters required")
      .max(100, "Maximum 100 characters allowed"),
    date_range: yup
      .array()
      .of(yup.date().required("Date is required"))
      .required("Date is required")
      .test(
        "is-not-same",
        "Start and end dates should not be the same",
        (value) => {
          if (value && value.length === 2) {
            const [startDate, endDate] = value;

            // Compare the timestamps of the dates
            return startDate.getTime() !== endDate.getTime();
          } else {
            return true;
          }
        }
      ),
    // reason: yup.string().required("Reason for edit is required"),
    sector: yup
      .string()
      .required("Industry is required")
      .notOneOf(["Select Industry"], "Please select a industry")
      .nullable(),
    domain: yup
      .string()
      .required("Domain is required")
      .notOneOf(["Select Domain"], "Please select a domain")
      .nullable(),
    company_type: yup
      .string()
      .required("Company type is required")
      .notOneOf(["Select Company Type"], "Please select a company type")
      .nullable(),
    function: yup
      .string()
      .required("Function is required")
      .notOneOf(["Select Function"], "Please select a function")
      .nullable(),
    rank: yup
      .string()
      .required("Seniority is required")
      .notOneOf(["Select Seniority"], "Please select a seniority")
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    getFieldState,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchemaForEdit),
  });

  useEffect(() => {
    if (getFieldState("title").isDirty) {
      setIsTitleChanged(true);
    } else {
      setIsTitleChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("title")]);

  useEffect(() => {
    if (!isAdd && modalData) {
      // Edit case
      setIsCompleted(modalData?.progress);
      reset({
        ...modalData.intermediate_role,
        title: modalData.title,
        start_date: modalData.start_date,
        end_date: modalData.end_date,
        date_range: [
          dayjs(modalData.start_date, dateFormat),
          dayjs(modalData.end_date, dateFormat),
        ],
        milestone_id: modalData.milestone_id,
        generate_milestone: "no",
        ...modalData.targeted_role,
      });
    }
  }, [isOpen, modalData, reset, isAdd]);

  const handleResetClose = () => {
    setIsCompleted(0);
    handleModalOpenClose(false);
    reset();
  };

  const handleProgress = () => {
    setIsLoading(true);
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/milestone-progress/${nodemapId}/`,
      {
        milestone_id: modalData.milestone_id || "",
        progress: 100,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const getData = () => {
    const user_id = storageDetail?.user_id;
    getMethod(
      `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        let res = Object.values(
          response?.response?.nodemap.reduce((acc, cur) => {
            if (!acc[cur?.end_date]) {
              acc[cur?.end_date] = [{ ...cur }];
            } else {
              acc[cur?.end_date].push(cur);
            }
            return acc;
          }, {})
        );
        setNodemapData(res);
      }
    });
  };

  const handleSaveFinal = (generateMilestones) => {
    setIsLoading(true);
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/edit-north-star/${nodemapId}/`,
      {
        ...tempData,
        generate_milestone: generateMilestones ? "yes" : "no",
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const handleEdit = (submittedForm) => {
    const [start_date, end_date] = submittedForm.date_range;

    let apiData = {
      milestone_id: modalData.milestone_id,
      title: submittedForm.title,
      start_date: dayjs(start_date).format("DD/MM/YYYY"),
      end_date: dayjs(end_date).format("DD/MM/YYYY"),
      // reason: submittedForm.reason,
      ...(isAdd
        ? { reason: submittedForm.reason }
        : isTitleChanged && { reason: submittedForm.reason }),
      role_details: {
        sector: submittedForm.sector,
        domain: submittedForm.domain,
        function: submittedForm.function,
        company_type: submittedForm.company_type,
        rank: submittedForm.rank,
      },
    };

    delete apiData["date_range"];
    setTempData(apiData);
    if (getFieldState("title").isDirty || getFieldState("date_range").isDirty) {
      if (apiData?.reason === "renaming") {
        // If user is changing title, start date or end date with reason renaming.
        handleEditTargedtedRole(apiData);
      } else if (apiData?.reason && apiData?.reason !== "renaming") {
        // If user is changing title, start date or end date with reason not renaming.
        setCurrentMode("regularConfirmation");
        const title = (
          <p className="confirmation-dic">
            Would you like <b style={{ fontWeight: 800 }}>youe AI</b> to
            generate milestones?
          </p>
        );
        setConfirmationTitle(title);
        setIsConfirmationModalOpen(true);
      } else {
        // If user is changing start date or end date
        handleEditTargedtedRole(apiData);
      }
    } else {
      //If user changes fields other than title and dates.
      setIsLoading(true);
      patchTripdata(
        `${process.env.REACT_APP_API_URL}nodemap/edit-north-star/${nodemapId}/`,
        {
          ...apiData,
          generate_milestone: "no",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            getData();
            handleResetClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const handleEditTargedtedRole = (apiData) => {
    setIsLoading(true);
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/edit-north-star/${nodemapId}/`,
      {
        ...apiData,
        generate_milestone: "no",
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };
  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <div className="collapse-icon star-milestone-option">
                    <Icon icon="ph:star-fill" />
                  </div>
                  <p> Edit North Star</p>
                </div>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleResetClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <div className="erro-text">{apiError && apiError}</div>
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="">Title</label>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="Short Term Goal 03"
                        />
                      )}
                    />
                    {errors.title && (
                      <p className="custom-modal-error">
                        {errors.title.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Date</label>
                      <Controller
                        name="date_range"
                        control={control}
                        render={({ field }) => (
                          <RangePicker
                            {...field}
                            format={"DD/MM/YYYY"}
                            className="custom_input startdatepicker-control"
                            rootClassName="range-datepicker"
                          />
                        )}
                      />
                      {errors?.date_range && (
                        <p className="custom-modal-error">
                          {errors?.date_range?.message}
                        </p>
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Industry</label>
                      <Controller
                        name="sector"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Industry"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={selectSector.map((sec) => ({
                              label: sec.replace(/\b\w/g, (l) =>
                                l.toUpperCase()
                              ),
                              value: sec,
                            }))}
                          />
                        )}
                      />
                      {errors?.sector && (
                        <p className="custom-modal-error">
                          {errors?.sector?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Company Type</label>
                      <Controller
                        name="company_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Company Type"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={selectSector.map((sec) => ({
                              label: sec.replace(/\b\w/g, (l) =>
                                l.toUpperCase()
                              ),
                              value: sec,
                            }))}
                          />
                        )}
                      />
                      {errors?.company_type && (
                        <p className="custom-modal-error">
                          {errors?.company_type?.message}
                        </p>
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Function</label>
                      <Controller
                        name="function"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Function"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={selectSector.map((sec) => ({
                              label: sec.replace(/\b\w/g, (l) =>
                                l.toUpperCase()
                              ),
                              value: sec,
                            }))}
                          />
                        )}
                      />
                      {errors?.function && (
                        <p className="custom-modal-error">
                          {errors?.function?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Domain</label>
                      <Controller
                        name="domain"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Domain"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={selectSector.map((sec) => ({
                              label: sec.replace(/\b\w/g, (l) =>
                                l.toUpperCase()
                              ),
                              value: sec,
                            }))}
                          />
                        )}
                      />
                      {errors?.domain && (
                        <p className="custom-modal-error">
                          {errors?.domain?.message}
                        </p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Seniority</label>
                      <Controller
                        name="rank"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Seniority"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={selectSector.map((sec) => ({
                              label: sec.replace(/\b\w/g, (l) =>
                                l.toUpperCase()
                              ),
                              value: sec,
                            }))}
                          />
                        )}
                      />
                      {errors?.rank && (
                        <p className="custom-modal-error">
                          {errors?.rank?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  {isTitleChanged && (
                    <Grid item xs={12}>
                      <div className="select-field">
                        <label htmlFor="">{`Reason for edit`}</label>
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="select-design-signin-ant"
                              placeholder="Select Reason"
                              dropdownStyle={{ zIndex: 2000 }}
                              options={editReasonsOptions}
                            />
                          )}
                        />
                        {errors?.reason && (
                          <p className="custom-modal-error">
                            {errors?.reason?.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {isCompleted === 100 ? (
                    <Button className="small-btn check-btn completed-btn">
                      <Icon icon="icon-park-outline:check-one" /> Completed
                    </Button>
                  ) : (
                    <Button
                      className="small-btn check-btn mark-as-completed-btn"
                      onClick={() => {
                        handleProgress();
                        setIsCompleted(100);
                      }}
                    >
                      <Icon icon="icon-park-outline:check-one" /> Mark as
                      completed
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Space size={15}>
                    <Button
                      className="small-btn text-color-grey px-20"
                      onClick={handleResetClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="small-btn primary-btn px-20"
                      type="submit"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Grid>
              </Grid>
            </form>
          </Box>
          <ConfirmationPopup
            isOpen={isConfirmationModalOpen}
            setIsOpen={setIsConfirmationModalOpen}
            title={confirmationTitle}
            handleSaveData={handleSaveFinal}
            mode={currentMode}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddTargetedRoleModal;
