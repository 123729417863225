import React, { createContext, useContext, useState } from "react";
const ActiveTab = createContext();
export function ActiveTabProvider({ children }) {
  const [activeTab, setActiveTab] = useState(
    "Clarify your next career pathway"
  );

  return (
    <ActiveTab.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTab.Provider>
  );
}
export function useActiveTab() {
  return useContext(ActiveTab);
}
