import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import StartMovingImg from "../../../assets/images/start-moving.png";
import { Grid } from "@mui/material";

const StartMoving = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          With our AI-driven and intelligent platform, Youe will help you stay
          on track so you can make the best decisions to drive impactful change
          in your career. And just as you’re helped by the community of your
          peers, you can empower others through support and feedback.
        </p>
        <button className="join-btn">Get Started</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img
          src={StartMovingImg}
          alt=""
          className="img-fluid minus-align-img"
        />
      </Grid>
    </Grid>
  );
};

export default StartMoving;
