import React, { useEffect, useState } from "react";

import { Slider } from "antd";
import "./RangeSlider.css";

const trackColor = "#F4F5F6";
const sliderStyle = {
  width: "99%",
  height: "10px",
  background: "none",
  borderRadius: "5px",
  marginTop: "20px",
};
const railStyle = {
  background: trackColor,
  borderRadius: "5px",
};

const dotStyle = {
  borderColor: trackColor,
  backgroundColor: trackColor,
  height: "15px",
  width: "2px",
  marginTop: "-3px", // Adjust dot positioning
  borderRadius: "0",
};

const RangeSlider = ({
  question,
  getValue,
  maxRange,
  toolTipWidth,
  defaultRange,
  value,
}) => {
  const [sliderValue, setSliderValue] = useState();

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const onAfterChange = (value) => {
    setSliderValue(value);
    getValue(question, value);
  };
  const customTooltipFormatter = (value) => {
    return `${value} months`;
  };

  const tooltipFormatter = (value) => {
    return `${value}`;
  };

  const formatter = maxRange === 60 ? customTooltipFormatter : tooltipFormatter;

  // const tooltip = document.getElementsByClassName("ant-slider-tooltip")[0];
  // const tooltipwarp = document.getElementsByClassName("duration-slider")[0];
  // if (tooltip && tooltipwarp) {
  //   tooltipwarp.appendChild(tooltip);
  // }

  return (
    <Slider
      className="transparent-slider"
      value={sliderValue}
      rootClassName="range-slider-wrapper"
      defaultValue={defaultRange}
      max={maxRange ? maxRange : 10}
      step={maxRange === 60 ? 3 : 1}
      dots={true}
      style={sliderStyle}
      railStyle={railStyle}
      dotStyle={dotStyle}
      tooltip={{
        open: true,
        placement: ["top"],
        formatter,
      }}
      onAfterChange={onAfterChange}
    />
  );
};

export default RangeSlider;
