import React, { useEffect, useState } from "react";
import moment from "moment";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, DatePicker, Input, Select, Tag } from "antd";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "../../../../assets/images/delete.png";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import "./modals.css";
import "../../../CSS/Welcome.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { Option } = Select;
const { MonthPicker } = DatePicker;

const EditWorkExperienceModal = ({
  data,
  setProfileData,
  profiledata,
  isAdd,
  currentModalData,
  setCurrentModalData,
  isOpen,
  handleModalOpenClose,
  industryOptions,
  functionOptions,
  getIndustry,
}) => {
  const [iconState, setIconState] = useState({
    dropdown1: false,
    dropdown2: false,
  });
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [otherSkillError, setOtherSkillError] = useState(null);
  const { storageDetail } = useStorageContext();
  const validationSchema = yup.object().shape({
    role: yup
      .string()
      .required("Role is required")
      .trim()
      .matches(/[a-zA-Z]+/, "Role must contain at least one alphabet character")
      .min(1, "Role must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    company: yup
      .string()
      .required("Company is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Company must contain at least one alphabet character"
      )
      .min(1, "Company must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    industry: yup.string().required("Industry is required").nullable(),
    function: yup
      .string()
      .required("Function is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Function must contain at least one alphabet character"
      )
      .min(1, "Function must contain at least one character")
      .max(100, "Maximum 100 characters allowed")
      .nullable(),

    start_date: yup
      .date()
      .required("Start Date is required")
      .test(
        "is-future-date",
        "Start date cannot be in the future",
        function (value) {
          const startDate = new Date(value);

          const currentDate = new Date();

          return !startDate || startDate <= currentDate;
        }
      ),
    end_date: yup
      .date()
      .nullable()
      .when("start_date", (start_date, schema) =>
        schema.test(
          "is-after-start-date",
          "End date must be after the start date",
          function (end_date) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);
            return !endDate || endDate >= startDate;
          }
        )
      ),
    tech_skills: yup.array().min(1, "At least one skill is required"),
  });

  useEffect(() => {
    setTags(currentModalData?.tech_skills || []);
  }, [currentModalData]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isAdd === true) {
      reset({
        role: "",
        company: "",
        industry: "",
        function: "",
        tech_skills: [],
      });
    } else {
      reset({
        role: currentModalData.role,
        company: currentModalData.company,
        industry: currentModalData.industry,
        function: currentModalData.function,
        tech_skills: currentModalData.tech_skills || [],
      });
      getIndustry(currentModalData.industry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleResetClose = () => {
    reset();
    handleModalOpenClose(false);
    setCurrentModalData({});
    getIndustry("");
  };

  const tagHandleClose = (removedTag) => {
    const newTags = [...tags].filter((tag) => tag !== removedTag);
    setTags(newTags);
    setValue("tech_skills", newTags);
    trigger("tech_skills");
  };

  const getData = (type, editedFormData) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}my-profile/experience/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          if (type === "edit") {
            const profiledataCopy = { ...profiledata };
            const editIndex = profiledata.experience.findIndex(
              (item) => item.id === currentModalData.id
            );
            profiledataCopy.experience[editIndex] = editedFormData;
            setProfileData(profiledataCopy);
          } else {
            setProfileData({
              ...profiledata,
              experience: response?.response?.experience || [],
            });
          }
          handleResetClose();
        } else {
        }
      });
    }
  };

  const handleEdit = (editData) => {
    const user_id = storageDetail?.user_id;
    let apiData = {
      ...editData,
      start_date: moment(editData.start_date).format("MMMM YYYY"),
      end_date: moment(editData.end_date).format("MMMM YYYY"),
      tech_skills: tags,
    };
    if (user_id) {
      if (isAdd) {
        postMethod(
          `${process.env.REACT_APP_API_URL}my-profile/experience/${user_id}/`,
          { experience: apiData }
        ).then((response) => {
          if (response.status === "S") {
            // setIsLoading(false);
            // reset();
            getData();
            handleResetClose();
          } else {
            // setApiError(response.errorData.response.currentModalData.message);
            // setIsLoading(false);
          }
        });
      } else {
        apiData = {
          ...apiData,
          id: currentModalData.id,
        };
        patchTripdata(
          `${process.env.REACT_APP_API_URL}my-profile/edit-experience/${user_id}/${currentModalData.id}/`,
          { updated_experience: apiData }
        ).then((response) => {
          if (response.status === "S") {
            getData("edit", apiData);
            handleResetClose();
          }
        });
      }
    }
  };

  const handleDelete = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      deleteTrip(
        `${process.env.REACT_APP_API_URL}my-profile/delete-experience/${user_id}/${currentModalData.id}/`
      ).then((response) => {
        if (response.status === "S") {
          reset();
          getData();
        }
      });
    }
  };

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    if (/[a-zA-Z]/.test(e.target.value)) {
      setOtherSkillError(null);
    } else if (e.target.value === "") {
      setOtherSkillError(null);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      if (
        e.target.value.trim().length >= 1 &&
        e.target.value.trim().length <= 50
      ) {
        const newTags = [...tags].filter(
          (tag) => tag.toLowerCase() !== inputValue.toLowerCase()
        );

        if (/[a-zA-Z]/.test(inputValue)) {
          if (tags.length >= 5) {
            setOtherSkillError("Maximum 5 skills are allowed");
          } else {
            setTags([...newTags, inputValue]);
            setValue("tech_skills", [...newTags, inputValue]);
            trigger("tech_skills");
            setInputValue("");
          }
        } else {
          setOtherSkillError(
            "Other Skills must contain at least one alphabet character"
          );
        }
      } else {
        setOtherSkillError("Maximum 50 character allowed");
      }
    }
  };

  const toggleDropdown = (dropdown) => {
    setIconState((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  let startDateMonthName,
    startDateYear,
    endDateMonthName,
    endDateYear = null;

  if (
    !isAdd &&
    currentModalData &&
    Object.keys(currentModalData).length &&
    currentModalData?.start_date &&
    currentModalData.isWorkExperience
  ) {
    [startDateMonthName, startDateYear] =
      currentModalData?.start_date.split(" ");
    [endDateMonthName, endDateYear] = currentModalData?.end_date.split(" ");
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h6" component="h2" className="modal-title">
                  {isAdd ? "Add" : "Edit"} Work Experience
                </Typography>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleResetClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="currentRole">Role</label>
                      <Controller
                        name="role"
                        defaultValue={currentModalData.role}
                        control={control}
                        render={({ field }) => (
                          <Input
                            className="custom_input"
                            {...field}
                            defaultValue={currentModalData.role}
                          />
                        )}
                      />
                      {errors.role && (
                        <p className="custom-modal-error">
                          {errors.role.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="">Company</label>
                    <Controller
                      name="company"
                      defaultValue={currentModalData.company}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          defaultValue={currentModalData.company}
                        />
                      )}
                    />
                    {errors.company && (
                      <p className="custom-modal-error">
                        {errors.company.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="industry">Industry</label>
                      <Controller
                        name="industry"
                        defaultValue={currentModalData.industry}
                        control={control}
                        render={({ field, value }) => (
                          <Select
                            className="select-design-signin-ant"
                            {...field}
                            placeholder="Select industry"
                            defaultValue={currentModalData.industry}
                            dropdownStyle={{ zIndex: 2000 }}
                            onDropdownVisibleChange={() =>
                              toggleDropdown("dropdown2")
                            }
                            onChange={(value) => {
                              getIndustry(value);
                              setValue("industry", value);
                            }}
                            value={value}
                            suffixIcon={
                              <Icon
                                icon={
                                  iconState.dropdown2
                                    ? "iconamoon:arrow-up-2"
                                    : "iconamoon:arrow-down-2"
                                }
                                className={
                                  iconState.dropdown2
                                    ? "dorp-down-icon dd-opened"
                                    : "dorp-down-icon"
                                }
                              />
                            }
                          >
                            {industryOptions?.map((option) => (
                              <Option value={option}>{option}</Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.industry && (
                        <p className="custom-modal-error">
                          {errors.industry.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="">Function</label>
                    <Controller
                      name="function"
                      defaultValue={currentModalData.function}
                      control={control}
                      render={({ field, value }) => (
                        <Select
                          className="select-design-signin-ant"
                          {...field}
                          placeholder="Select current role"
                          defaultValue={currentModalData.role}
                          dropdownStyle={{ zIndex: 2000 }}
                          onChange={(value) => {
                            setValue("function", value);
                          }}
                          onDropdownVisibleChange={() =>
                            toggleDropdown("dropdown1")
                          }
                          value={value}
                          suffixIcon={
                            <Icon
                              icon={
                                iconState.dropdown1
                                  ? "iconamoon:arrow-up-2"
                                  : "iconamoon:arrow-down-2"
                              }
                              className={
                                iconState.dropdown1
                                  ? "dorp-down-icon dd-opened"
                                  : "dorp-down-icon"
                              }
                            />
                          }
                        >
                          {functionOptions.map((option) => (
                            <Option key={option} value={option}>
                              {option}
                            </Option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.function && (
                      <p className="custom-modal-error">
                        {errors.function.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="start_date">Start Date</label>
                    <Controller
                      name="start_date"
                      control={control}
                      defaultValue={
                        !isAdd && startDateMonthName && startDateYear
                          ? dayjs(
                              startDateYear +
                                " " +
                                moment().month(startDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && startDateMonthName && startDateYear
                              ? dayjs(
                                  startDateYear +
                                    " " +
                                    moment()
                                      .month(startDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors.start_date && (
                      <p className="custom-modal-error">
                        {errors.start_date.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="end_date">End Date</label>
                    <Controller
                      name="end_date"
                      control={control}
                      defaultValue={
                        !isAdd && endDateMonthName && endDateYear
                          ? dayjs(
                              endDateYear +
                                " " +
                                moment().month(endDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && endDateMonthName && endDateYear
                              ? dayjs(
                                  endDateYear +
                                    " " +
                                    moment()
                                      .month(endDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors.end_date && (
                      <p className="custom-modal-error">
                        {errors.end_date.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="tags-wrap">
                      <label
                        htmlFor="tech_skills"
                        className="tech_skills_label"
                      >
                        Technical Skills
                      </label>
                      <div className="tags-group">
                        <Controller
                          name="tech_skills"
                          control={control}
                          render={({ field }) => (
                            <>
                              {tags &&
                                tags.map((tag, index) => (
                                  <Tag
                                    key={index}
                                    {...field}
                                    value={tags}
                                    className="tag-item technical-skills"
                                    closable
                                    // onClose={() => tagHandleClose(tag)}
                                    onClose={(e) => {
                                      e.preventDefault();
                                      tagHandleClose(tag);
                                    }}
                                  >
                                    {tag}
                                  </Tag>
                                ))}
                            </>
                          )}
                        />
                      </div>
                      {/* {tags.length === 1 ? (
                      <p className="custom-modal-help">
                        Can't remove all skills, At least one Technical Skill is
                        required
                      </p>
                    ) : null} */}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="otherSkills">Other Skills</label>
                    <Input
                      className="custom_input"
                      placeholder="Type in other technical skills"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyPress}
                    />
                    {otherSkillError && (
                      <p className="custom-modal-error">{otherSkillError}</p>
                    )}

                    {tags.length === 0 && errors.tech_skills && (
                      <p className="custom-modal-error">
                        {errors.tech_skills.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {!isAdd && (
                    <Button className="btn danger-btn" onClick={handleDelete}>
                      <img
                        src={DeleteIcon}
                        alt="delete icon"
                        className="delete-icon"
                      />{" "}
                      Delete
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    className="btn primary primary-btn"
                    type="submit"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default EditWorkExperienceModal;
