import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import { Radio, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import BottomBtnFooter from "../../CustomComponents/BottomBtnFooter";
import CustomSideSection from "../../CustomComponents/CustomSideSection";
import RangeSlider from "../../CustomComponents/RangeSlider/RangeSlider";
import SingleSelectTag from "../../CustomComponents/SingleSelectTag";
import { useStepContext } from "../Context/activeStepContext";
import { useProfileContext } from "../Context/profileSetupContext";
import { useLoadingContext } from "../Context/loadingContext";
import { useStorageContext } from "../Context/storageContext";

const responseObjFormat = {
  clear_vision_for_next_career_runway: false,
  role_at_end_of_career_runway: {
    sector: "",
    company_type: "",
    function: "",
    domain: "",
    rank: "",
  },
  current_career_choice: 1,
  career_stream_of_interest: "",
  career_runway_duration: 3,
};
const radioOptions = [
  { label: "Current career trajectory", value: 1 },
  { label: "Career transition", value: 2 },
];

const singleSelectOptions = ["yes", "no"];
const validationSchema = [
  Yup.object().shape({
    career_runway_duration: Yup.number()
      .min(3, "Minimum 3 is allowed")
      .required("Runway duartion is required"),
    role_at_end_of_career_runway: Yup.object().shape({
      sector: Yup.string()
        .required("Industry is required")
        .notOneOf(["Select Industry"], "Please select a Industry")
        .nullable(),
      company_type: Yup.string()
        .required("Company Type is required")
        .notOneOf(["Select Company Type"], "Please select a company type")
        .nullable(),
      function: Yup.string()
        .required("Function is required")
        .notOneOf(["Select Function"], "Please select a function")
        .nullable(),
      domain: Yup.string()
        .required("Domain is required")
        .notOneOf(["Select Domain"], "Please select a domain")
        .nullable(),
      rank: Yup.string()
        .required("Seniority is required")
        .notOneOf(["Select Seniority"], "Please select a seniority")
        .nullable(),
    }),
  }),
  Yup.object().shape({
    career_stream_of_interest: Yup.string()
      .required("Career stream is required")
      .notOneOf(["Select career stream"], "Please select a career stream")
      .nullable(),
  }),
  Yup.object().shape({
    career_runway_duration: Yup.number()
      .min(3, "Minimum 3 is allowed")
      .required("Runway duartion is required"),
    career_stream_of_interest: Yup.string().optional().nullable(),
  }),
];

const QuestionsThreeNew = () => {
  const [radio, setRadio] = useState(1);
  const { profileData, setProfileData } = useProfileContext();
  const { setIsLoading } = useLoadingContext();
  const [open, setOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState("yes");
  const { step, setStep } = useStepContext();
  const [isFocus, setFocus] = useState({
    sector: "",
    company_type: "",
    function: "",
    domain: "",
    rank: "",
    career_stream_of_interest: "",
  });
  const { storageDetail } = useStorageContext();
  const [industryOptions, setIndustryOptions] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [industryVal, setIndustryVal] = useState("");
  const [functionVal, setFunctionVal] = useState("");
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [rankOptions, setRankOptions] = useState([]);
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [careerOptions, setCareerOptions] = useState([]);
  const [selectOpenState, setSelectOpenState] = useState({
    sector: false,
    company_type: false,
    function: false,
    domain: false,
    rank: false,
  });
  const initialIndustryVal = profileData?.role_at_end_of_career_runway?.sector;
  const initialFunctionVal =
    profileData?.role_at_end_of_career_runway?.function;

  useEffect(() => {
    setIsLoading(true);
    getMethod(`${process.env.REACT_APP_API_URL}industries`).then((response) => {
      if (response.status === "S") {
        setIndustryOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}functions`).then((response) => {
      if (response.status === "S") {
        setFunctionOptions(response?.response?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}career-stream`).then(
      (response) => {
        if (response.status === "S") {
          setCareerOptions(response?.response?.result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryVal || initialIndustryVal) {
      //make it dynamic once get the data now passing Technology
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}industries/?q=${industryVal}`
      ).then((response) => {
        if (response.status === "S") {
          setCompanyTypeOptions(response?.response?.result?.company_type);
          setFunctionOptions(response?.response?.result?.functions);
          setIsLoading(false);
        } else {
          setCompanyTypeOptions([]);
          setFunctionOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryVal, initialIndustryVal]);

  useEffect(() => {
    if (functionVal || initialFunctionVal) {
      //make it dynamic once get the data now passing IT
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}functions/?q=${functionVal}`
      ).then((response) => {
        if (response.status === "S") {
          setDomainsOptions(response?.response?.result?.domains);
          setRankOptions(response?.response?.result?.rank);
          setIsLoading(false);
        } else {
          setDomainsOptions([]);
          setRankOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionVal, initialFunctionVal]);

  const toggleSelectOpen = (name) => {
    setSelectOpenState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const getArrowIcon = (name) => {
    return selectOpenState[name]
      ? "iconamoon:arrow-up-2"
      : "iconamoon:arrow-down-2";
  };

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      selectedTag === "yes"
        ? validationSchema[0]
        : selectedTag === "no" && radio === 2
        ? validationSchema[1]
        : validationSchema[2]
    ),
    defaultValues: {
      current_career_choice: 1,
      career_runway_duration: 3,
      career_stream_of_interest: "Select career stream",
      role_at_end_of_career_runway: {
        sector: null, // "Select Sector",
        company_type: null, // "Select Company Type",
        function: null, // "Select Function",
        domain: null, // "Select Role",
        rank: null, // "Select Seniority"
      },
    },
  });
  const watchAllFields = watch();

  const getTagValue = (tag) => {
    setSelectedTag(tag);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setRadio(response?.response?.questionnaire?.current_career_choice || 1);
        reset({
          current_career_choice:
            response?.response?.questionnaire?.current_career_choice || 1,
          career_runway_duration:
            response?.response?.questionnaire?.career_runway_duration || 3,
          career_stream_of_interest:
            response?.response?.questionnaire?.career_stream_of_interest ||
            null,
          role_at_end_of_career_runway:
            response.response.questionnaire
              .clear_vision_for_next_career_runway ||
            response.response.questionnaire
              .clear_vision_for_next_career_runway === null
              ? response?.response?.questionnaire?.role_at_end_of_career_runway
              : {
                  sector: null,
                  company_type: null,
                  function: null,
                  domain: null,
                  rank: null,
                },
        });
        const career_vision_tag =
          response.response.questionnaire.clear_vision_for_next_career_runway ||
          response.response.questionnaire
            .clear_vision_for_next_career_runway === null
            ? "yes"
            : "no";
        setSelectedTag(career_vision_tag);
        const updatedResponse = {
          ...response.response.questionnaire,
          clear_vision_for_next_career_runway: career_vision_tag,
        };
        setProfileData(updatedResponse);
      } else {
        // setValues((prevValues) => ({
        //   ...prevValues,
        //   ...responseObjFormat,
        // }));
      }
    });
  };

  const onSubmit = (data) => {
    const formValues = getValues();
    let apiObj = {
      clear_vision_for_next_career_runway:
        selectedTag && selectedTag === "yes" ? true : false,
      career_runway_duration: data?.career_runway_duration || 3,
      role_at_end_of_career_runway:
        responseObjFormat.role_at_end_of_career_runway,
      current_career_choice: data?.current_career_choice || 1,
    };

    if (selectedTag === "yes") {
      apiObj = {
        ...apiObj,
        role_at_end_of_career_runway: data?.role_at_end_of_career_runway,
        current_career_choice: "",
        career_stream_of_interest: "",
      };
    } else {
      // selectedTag === "no"
      if (radio === 1) {
        // if (selectedTag === "no" && values.current_career_choice === 1)
        apiObj = {
          ...apiObj,
          career_stream_of_interest: "",
        };
      } else {
        // if (selectedTag === "no" && values.current_career_choice === 2)
        apiObj = {
          ...apiObj,
          career_stream_of_interest: formValues?.career_stream_of_interest,
        };
      }
    }
    setProfileData(formValues);
    setIsLoading(true);
    postMethod(`${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/`, {
      user_id: storageDetail?.user_id,
      ...apiObj,
    }).then((response) => {
      if (response.status === "S") {
        setIsLoading(false);
        if (apiObj.clear_vision_for_next_career_runway === true) {
          // if clear_vision ="yes"
          if (apiObj.career_runway_duration >= 24) {
            // Intermediate
            setStep(9);
          } else {
            // Q4
            setStep(10);
          }
        } else if (apiObj.clear_vision_for_next_career_runway === false) {
          if (apiObj.career_runway_duration >= 24) {
            // Intermediate
            setStep(9);
          } else {
            // if clear_vision ="no" => Runway recommendation
            setStep(11);
          }
        }
      } else {
      }
    });
  };

  const checkingAll = () => {
    const values = profileData;
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && !values[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div className="section_wrapper align-content-start">
        <CustomSideSection
          step={step}
          heading={"Personalised For You"}
          subHeading={
            "Your answers here help us tailor your experience on youe to help you design your perfect career"
          }
        />
        <div className="user_from_data">
          <div className="career_stream_info tags_blocks">
            <h2 className="question_heading star-title">
              Do you have a clear vision for your next career runway?
            </h2>
            <SingleSelectTag
              options={singleSelectOptions}
              getTagValue={getTagValue}
              value={selectedTag}
            />

            {selectedTag === "yes" && (
              <>
                <div className="career_exploring">
                  <h3 className="mt-0 mb-xxl-4 mb-3">
                    What best describes the role at the end of your next career
                    runway?
                  </h3>
                  <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item md={6} xs={12}>
                      <div className="signin-field-label">
                        <label>Industry</label>
                        <Controller
                          control={control}
                          name="role_at_end_of_career_runway.sector"
                          value={
                            watchAllFields?.role_at_end_of_career_runway?.sector
                          }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                placeholder="Select Industry"
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  watchAllFields?.role_at_end_of_career_runway
                                    ?.sector
                                }
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.role_at_end_of_career_runway
                                        ?.sector
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  toggleSelectOpen("sector");
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={getArrowIcon("sector")}
                                    className={
                                      selectOpenState["sector"]
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placement="bottomLeft"
                                options={industryOptions.map((sec) => ({
                                  label: sec,
                                  value: sec,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                  setIndustryVal(value);
                                }}
                              ></Select>
                              {checkingAll() && (
                                <div className="error-custom-signin">
                                  {error?.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div
                        className={`signin-field-label ${
                          companyTypeOptions?.length === 0 ? "is-disabled" : ""
                        }`}
                      >
                        <label>Company Type</label>
                        <Controller
                          control={control}
                          name="role_at_end_of_career_runway.company_type"
                          value={
                            watchAllFields?.role_at_end_of_career_runway
                              ?.company_type
                          }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                {...register(
                                  "role_at_end_of_career_runway.company_type"
                                )}
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  watchAllFields?.role_at_end_of_career_runway
                                    ?.company_type
                                }
                                disabled={
                                  companyTypeOptions?.length === 0
                                    ? true
                                    : false
                                }
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.role_at_end_of_career_runway
                                        ?.company_type
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  toggleSelectOpen("company_type");
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={getArrowIcon("company_type")}
                                    className={
                                      selectOpenState["company_type"]
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placeholder="Select Company Type"
                                placement="bottomLeft"
                                options={companyTypeOptions?.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                }}
                              ></Select>
                              {checkingAll() &&
                                companyTypeOptions?.length !== 0 && (
                                  <div className="error-custom-signin">
                                    {error?.message}
                                  </div>
                                )}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className="signin-field-label">
                        <label>Function</label>
                        <Controller
                          control={control}
                          name="role_at_end_of_career_runway.function"
                          value={
                            watchAllFields?.role_at_end_of_career_runway
                              ?.function
                          }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  watchAllFields?.role_at_end_of_career_runway
                                    ?.function
                                }
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.role_at_end_of_career_runway
                                        ?.function
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  toggleSelectOpen("function");
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={getArrowIcon("function")}
                                    className={
                                      selectOpenState["function"]
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placeholder="Select Function"
                                placement="bottomLeft"
                                options={functionOptions?.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                  setFunctionVal(value);
                                }}
                              ></Select>
                              {checkingAll() && (
                                <div className="error-custom-signin">
                                  {error?.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div
                        className={`signin-field-label ${
                          domainsOptions?.length === 0 ? "is-disabled" : ""
                        }`}
                      >
                        {/*label Role changed => Domain */}
                        <label>Domain</label>
                        <Controller
                          control={control}
                          name="role_at_end_of_career_runway.domain"
                          value={
                            watchAllFields?.role_at_end_of_career_runway?.domain
                          }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                disabled={
                                  domainsOptions?.length === 0 ? true : false
                                }
                                value={
                                  watchAllFields?.role_at_end_of_career_runway
                                    ?.domain
                                }
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.role_at_end_of_career_runway
                                        ?.domain
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  toggleSelectOpen("domain");
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={getArrowIcon("domain")}
                                    className={
                                      selectOpenState["domain"]
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placeholder="Select Domain"
                                placement="bottomLeft"
                                options={domainsOptions?.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                }}
                              ></Select>
                              {checkingAll() &&
                                domainsOptions?.length !== 0 && (
                                  <div className="error-custom-signin">
                                    {error?.message}
                                  </div>
                                )}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div
                        className={`signin-field-label ${
                          rankOptions?.length === 0 ? "is-disabled" : ""
                        }`}
                      >
                        <label>Seniority</label>
                        <Controller
                          control={control}
                          name="role_at_end_of_career_runway.rank"
                          value={
                            watchAllFields?.role_at_end_of_career_runway?.rank
                          }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                disabled={
                                  rankOptions?.length === 0 ? true : false
                                }
                                value={
                                  watchAllFields?.role_at_end_of_career_runway
                                    ?.rank
                                }
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.role_at_end_of_career_runway
                                        ?.rank
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  toggleSelectOpen("rank");
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={getArrowIcon("rank")}
                                    className={
                                      selectOpenState["rank"]
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placeholder="Select Seniority"
                                placement="bottomLeft"
                                options={rankOptions?.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                }}
                              ></Select>
                              {checkingAll() && rankOptions?.length !== 0 && (
                                <div className="error-custom-signin">
                                  {error?.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

            {selectedTag === "no" && (
              <>
                <div className="career_exploring">
                  <h3 className="mt-0 mb-4">
                    Do you wish to continue your current career trajectory or
                    explore a different career path?
                  </h3>
                  <div className="radio-btns-wrapper">
                    {
                      <Controller
                        name="current_career_choice"
                        control={control}
                        // defaultValue={values?.current_career_choice}
                        render={({ field }) => (
                          <Radio.Group
                            options={radioOptions}
                            // defaultValue={values?.current_career_choice}
                            onChange={(e) => {
                              // setValue("current_career_choice", e.target.value);
                              field.onChange(e.target.value);
                              setRadio(e.target.value);
                            }}
                            value={field.value}
                            className="radio-item"
                          />
                        )}
                      />
                    }
                  </div>
                  {watchAllFields?.current_career_choice === 2 && (
                    <div className="career-stream">
                      <div className="signin-field-label">
                        <label>Select a career stream of interest</label>
                        <Controller
                          control={control}
                          name="career_stream_of_interest"
                          // defaultValue={
                          //   profileData?.career_stream_of_interest ||
                          //   "Select career stream"
                          // }
                          rules={{
                            required: true,
                            message: "required",
                          }}
                          render={({
                            field: { value, name },
                            fieldState: { error },
                          }) => (
                            <div className="input-con-signin">
                              <Select
                                labelid="demo-simple-select-label"
                                id="demo-simple-select"
                                // defaultValue={
                                //   profileData?.career_stream_of_interest ||
                                //   "Select career stream"
                                // }
                                value={value}
                                sx={{ m: 1, width: "4.4cm", height: "35px" }}
                                className={
                                  error
                                    ? !checkingAll()
                                      ? "outline-failure-shadow-none select-design-signin-ant"
                                      : "outline-failure select-design-signin-ant"
                                    : value &&
                                      isFocus?.profileData
                                        ?.career_stream_of_interest
                                    ? "outline-success select-design-signin-ant"
                                    : "select-design-signin-ant"
                                }
                                popupClassName="custom-select-dropdown"
                                error={error?.message}
                                onDropdownVisibleChange={() => {
                                  setOpen(!open);
                                }}
                                suffixIcon={
                                  <Icon
                                    icon={
                                      open
                                        ? "iconamoon:arrow-up-2"
                                        : "iconamoon:arrow-down-2"
                                    }
                                    className={
                                      open
                                        ? "dorp-down-icon dd-opened"
                                        : "dorp-down-icon"
                                    }
                                  />
                                }
                                onBlur={() => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: false,
                                  }));
                                }}
                                placeholder="Select career stream"
                                placement="bottomLeft"
                                options={careerOptions.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                onChange={(value) => {
                                  setFocus((prev) => ({
                                    ...prev,
                                    [name]: true,
                                  }));
                                  setValue(name, value, {
                                    shouldValidate: true,
                                  });
                                }}
                              ></Select>
                              {checkingAll() && (
                                <div className="error-custom-signin">
                                  {error?.message}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="duration-slider" style={{ overflow: "hidden" }}>
              <h3 className="range-slider-title">
                Select a duration for your next career runway
              </h3>
              {watchAllFields.career_runway_duration === 0 && (
                <span className="error-custom-signin">
                  Please select a duration
                </span>
              )}
              <div className="start_end_month">
                <span>60+ Months</span>
              </div>
              <Controller
                name="career_runway_duration"
                question="career_runway_duration"
                control={control}
                // defaultValue={profileData?.career_runway_duration}
                render={({ field, name }) => (
                  <RangeSlider
                    maxRange={60}
                    getValue={(elm, val) => setValue(field.name, val)}
                    // defaultRange={field.value || 3}
                    onChange={(val) => {
                      // setValue("career_runway_duration", val);
                      field.onChange(val);
                    }}
                    value={watchAllFields.career_runway_duration}
                  />
                )}
              />
            </div>
          </div>
          <BottomBtnFooter onSubmit={handleSubmit(onSubmit)} />
        </div>
      </div>
    </>
  );
};
export default QuestionsThreeNew;
