import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { Avatar, Button, Card, Popover, Space, Typography } from "antd";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
// import required modules
import { Navigation, EffectCards } from "swiper/modules";
import { ReactComponent as LogoAi } from "../../../assets/images/youe-ai.svg";
import { ReactComponent as StarAi } from "../../../assets/images/star_ai.svg";
import { ReactComponent as Filter } from "../../../assets/images/filter.svg";
import QuestionsModal from "../Componenets/QuestionsModal/QuestionsModal";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import "../../CSS/footerButton.css";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const FooterButton = () => {
  const { storageDetail } = useStorageContext();
  const swiperRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [isQueCardOpen, setIsQueCardOpen] = useState(false);
  const [aiQuestionValue, setAiQuestionValue] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const handleCommingSoon = () => {
    setComingSoonVisible(true);
  };
  const handleRemoveCommingSoon = () => {
    setComingSoonVisible(false);
  };

  const getConversationHistoryList = () => {
    try {
      // setIsLoading(true);
      const userId = storageDetail?.user_id || "";
      const url = `${process.env.REACT_APP_API_URL}youe-ai/conversation-history/${userId}/`;
      getMethod(url).then((response) => {
        if (response.status === "S") {
          setIsQueCardOpen(true);
          setOpen(true);
          setConversationData(response?.response?.response[0]);
          // setIsLoading(false);
        } else {
          setIsQueCardOpen(false);
          setOpen(false);
          // setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmitQuestion = (promptData) => {
    const cardSlider = document.getElementsByClassName("ant-card-body");
    if (promptData && currentChatId) {
      setIsLoading(true);
      setAiQuestionValue("");
      postMethod(`${process.env.REACT_APP_API_URL}youe-ai/`, {
        user_id: storageDetail?.user_id,
        chat_id: currentChatId,
        prompt: promptData,
      }).then((response) => {
        if (response.status === "S") {
          getConversationHistoryList();
          setIsQueCardOpen(true);
          setIsLoading(false);
          // conversationData?.conversation_history?.length
          setTimeout(() => {
            swiperRef?.current?.slideTo(0);
          }, 500);
          if (cardSlider && cardSlider[0]) {
            cardSlider[0].scrollTop = cardSlider[0].scrollHeight;
          }
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const handleCloseChange = (e) => {
    document.addEventListener("click", (event) => {
      if (
        event.target.className === "swiper-button-prev" ||
        event.target.className === "swiper-button-next" ||
        event.target.className.baseVal === "question-modal-ask-pre-avatar" ||
        event.target.className
          .toString()
          .includes("question-modal-ask-send-avatar") ||
        event.target.className
          .toString()
          .includes("question-modal-ask-pre-avatar") ||
        event.target.className
          .toString()
          .includes("question-modal-ask-input") ||
        event.target.className === "question-ai-suggestion-container" ||
        event.target.className === "loader-container"
      ) {
        setIsQueCardOpen(true);
        setOpen(true);
      } else if (
        event.target.id === "footer-ai-input" ||
        event.target.id === "footer-ai-send-icon" ||
        event.target.id === "suggestion-tags"
      ) {
        setIsQueCardOpen(true);
        setOpen(true);
      } else {
        setAiQuestionValue("");
        setIsQueCardOpen(false);
        setOpen(false);
      }
    });

    if (e) {
      getConversationHistoryList();
      try {
        const userId = storageDetail?.user_id || "";
        const url = `${process.env.REACT_APP_API_URL}youe-ai/generate-chat-id/${userId}/`;
        getMethod(url)
          .then((response) => {
            if (response.status === "S") {
              setCurrentChatId(response?.response?.chat_id);
            } else {
            }
          })
          .catch((err) => console.log("err:", err));
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (isQueCardOpen) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("chatbot-body-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("chatbot-body-hidden");
    }
  }, [isQueCardOpen]);

  return (
    <div className="youeai-slider-wrapper fixed-footer">
      <div className="container">
        {isQueCardOpen && (
          <>
            <div style={{ position: "relative" }} id="youe-ai-card-container">
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                direction={"vertical"}
                effect={"cards"}
                grabCursor={true}
                loop={true}
                navigation={true}
                cardsEffect={{
                  perSlideOffset: 11,
                  rotate: 0,
                }}
                modules={[Navigation, EffectCards]}
                className="mySwiper"
              >
                {conversationData?.conversation_history?.length > 0 &&
                  conversationData?.conversation_history?.map((item) => (
                    <SwiperSlide>
                      <Grid container className="footer-button-ai-grid-wrapper">
                        <Grid item>
                          <Card className="footer-button-ai-card-container">
                            {item?.chat?.map((element, index) => (
                              <div className="msg-content">
                                <Typography.Title className="footer-button-ai-card-title">
                                  {element?.prompt}
                                </Typography.Title>
                                {isLoading &&
                                  index === item?.chat?.length - 1 && (
                                    <div className="dot-stage-loader">
                                      <div className="dot-pulse"></div>
                                    </div>
                                  )}
                                <Typography.Paragraph
                                  className="footer-button-ai-card-para"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {element?.response}
                                </Typography.Paragraph>
                                {/* <Row>
                                  <Tag
                                    bordered={false}
                                    style={{ cursor: "pointer" }}
                                    className="footer-button-ai-card-tag"
                                  >
                                    Action
                                    <Icon icon="gg:chevron-right" />
                                  </Tag>
                                </Row> */}
                                <Space
                                  size={6}
                                  style={{ marginTop: "52px" }}
                                  className="footer-fixed-label"
                                >
                                  <Avatar
                                    className="footer-button-ai-card-info-avatar"
                                    icon={<Icon icon="gg:info" vFlip={true} />}
                                  ></Avatar>
                                  <Typography.Title className="footer-button-ai-card-footer-text">
                                    AI responses can be inaccurate or
                                    misleading.
                                  </Typography.Title>
                                  <Typography.Title className="footer-button-ai-card-footer-nav">
                                    Learn More
                                  </Typography.Title>
                                </Space>
                              </div>
                            ))}
                          </Card>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="chatboard-overlay"></div>
          </>
        )}
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Button
              className="filter-btn common-btn"
              onMouseEnter={handleCommingSoon}
              onMouseLeave={handleRemoveCommingSoon}
            >
              <Icon icon="mingcute:flash-line" />
              Nudge
              <Filter className="filter-icon" />
            </Button>
            {comingSoonVisible && (
              <div className="comming-soon-nudge">Coming Soon</div>
            )}
          </Grid>
          <Grid item>
            <Popover
              overlayClassName={
                aiQuestionValue
                  ? "question-modal-asking-popup question-modal-ask-popup"
                  : "question-modal-ask-popup"
              }
              placement="topLeft"
              content={
                <QuestionsModal
                  aiQuestionValue={aiQuestionValue}
                  setAiQuestionValue={setAiQuestionValue}
                  handleSubmitQuestion={handleSubmitQuestion}
                  isLoading={isLoading}
                />
              }
              open={open}
              onOpenChange={(e) => handleCloseChange(e)}
              trigger="click"
              arrow={false}
            >
              <Button className="small-btn primary-btn d-flex gap-2 align-items-center common-btn">
                <StarAi />
                <LogoAi />
              </Button>
            </Popover>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FooterButton;
