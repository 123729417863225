import React, { useState, useEffect } from "react";
import FooterButton from "../profile/FooterButton";
import {
  Avatar,
  Button,
  Dropdown,
  Input,
  Pagination,
  Space,
  Tag,
  Typography,
} from "antd";
import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import LearnCard from "./LearnCard";
import EducationCard from "./EducationCard";
import { getMethod } from "../../Api'sCall/Apicalling";
import useDebounce from "../../hooks/useDebounce";
import { useLoadingContext } from "../../BeforeLogin/Context/loadingContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "../../CSS/LearnPage.css";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const initialPaginationInfo = {
  current: 1,
  perPage: 3,
  total: 0,
};
export default function LearnPage() {
  const { setIsLoading } = useLoadingContext();
  const { storageDetail } = useStorageContext();
  const [searchValue, setSearchValue] = useState("");
  const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
  const debouncedVal = useDebounce(searchValue, 500);
  const [selectedOverviewMenu, setSelectedOverviewMenu] = useState("all");
  const [educationLength] = useState(Array.from({ length: 10 }));
  const [overviewResourcesData, setOverviewResourcesData] = useState([]);
  const [overviewSelectedType, setOverviewSelectedType] = useState("All");
  const [personalizedRecommendatonsData, setPersonalizedRecommendatonsData] =
    useState([]);
  const [allTagsList, setAllTagsList] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const eduSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const overviewSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomOverviewPrevArrow />,
    nextArrow: <CustomOverviewNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const items = [
    {
      label: "All",
      key: "All",
    },
    {
      label: "Podcast",
      key: "Podcast",
    },
    {
      label: "Article",
      key: "Article",
    },
    {
      label: "Video",
      key: "Video",
    },
    {
      label: "Whitepaper",
      key: "Whitepaper",
    },
  ];

  useEffect(() => {
    getOverviewResourcesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVal]);

  useEffect(() => {
    getAllTagsList();
    getPersonalizedRecommendatonsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTagsList = () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}get-tags/`;
      getMethod(url).then((response) => {
        if (response.status === "S") {
          setAllTagsList(response?.response?.tags);
        } else {
          setAllTagsList([]);
        }
      });
    } catch (error) {}
  };

  const getPersonalizedRecommendatonsList = () => {
    try {
      const userId = storageDetail?.user_id || "";
      const url = `${process.env.REACT_APP_API_URL}personalized-recommendatons/${userId}/`;
      getMethod(url).then((response) => {
        if (response.status === "S") {
          setPersonalizedRecommendatonsData(response?.response);
        } else {
          setPersonalizedRecommendatonsData([]);
        }
      });
    } catch (error) {}
  };

  const getOverviewResourcesList = (
    paginationDetails = initialPaginationInfo,
    filter
  ) => {
    setIsLoading(true);
    try {
      const page = paginationDetails?.current || "1",
        perPage = paginationDetails?.perPage || "3";
      const url =
        searchValue || filter?.tag || filter?.content_type
          ? `${
              process.env.REACT_APP_API_URL
            }search/?per_page=${perPage}&page=${page}&q=${searchValue}&tag=${
              filter?.tag || ""
            }&content_type=${filter?.content_type || ""}`
          : `${process.env.REACT_APP_API_URL}resources/?per_page=${perPage}&page=${page}`;
      getMethod(url).then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          setPaginationInfo({
            ...paginationInfo,
            current: response?.response?.pagination_info?.current_page,
            total: response?.response?.pagination_info?.total_results,
          });
          setOverviewResourcesData(response?.response?.results);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {}
  };

  function CustomPrevArrow(props) {
    return (
      <button {...props} className="custom-arrow custom-prev-arrow">
        Previous
      </button>
    );
  }
  function CustomNextArrow(props) {
    return (
      <button {...props} className="custom-arrow custom-next-arrow">
        Next
      </button>
    );
  }

  function CustomOverviewPrevArrow(props) {
    return (
      <button
        {...props}
        className="learn-page-overview-custom-arrow custom-prev-arrow"
      >
        Previous
      </button>
    );
  }
  function CustomOverviewNextArrow(props) {
    return (
      <button
        {...props}
        className="learn-page-overview-custom-arrow custom-next-arrow"
      >
        Next
      </button>
    );
  }

  const handleOverviewSelection = (menu) => {
    setSelectedOverviewMenu(menu);
    getOverviewResourcesList(
      { ...paginationInfo },
      {
        tag: menu === "all" ? "" : menu,
        content_type:
          overviewSelectedType === "All" ? "" : overviewSelectedType,
      }
    );
  };

  const handlePageChange = (page) => {
    getOverviewResourcesList(
      { ...paginationInfo, current: page },
      {
        tag: selectedOverviewMenu === "all" ? "" : selectedOverviewMenu,
        content_type:
          overviewSelectedType === "All" ? "" : overviewSelectedType,
      }
    );
  };

  const handleSearchOverviewResources = (searchQuery) => {
    setSearchValue(searchQuery);
  };

  const handleContentTypeFilter = (key) => {
    setOverviewSelectedType(key);
    getOverviewResourcesList(
      { ...paginationInfo },
      {
        content_type: key === "All" ? "" : key,
        tag: selectedOverviewMenu === "all" ? "" : selectedOverviewMenu,
      }
    );
  };
  return (
    <div className="learn-page-view">
      <div className="learn-page-main_wrapper">
        <div className="container">
          <div className="learn-page-title">
            <Typography.Title level={1}>Learning Center</Typography.Title>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore.
            </p>
          </div>

          <div className="learn-page-rectangle-view">
            <div className="block-title">
              <Space size={10}>
                <Avatar
                  className="block-title-icon learn-page-person-avatar"
                  shape="square"
                  size="small"
                  icon={<Icon icon="tdesign:user" />}
                />
                <Typography className="learn-wrap-title">
                  Personalized Recommendations
                </Typography>
              </Space>
            </div>
            <div className="learn-page-row-mt">
              {personalizedRecommendatonsData?.length > 0 ? (
                <Slider {...settings} className="common-slider">
                  {personalizedRecommendatonsData?.map((item) => (
                    <LearnCard cardData={item} />
                  ))}
                </Slider>
              ) : (
                <div
                  className="learn-page-title"
                  style={{
                    height: "110px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p>Data not found</p>
                </div>
              )}
            </div>
          </div>
          <div className="learn-page-rectangle-view">
            <div className="block-title">
              <Space wrap size={10}>
                <Avatar
                  className="block-title-icon learn-page-education-avatar"
                  shape="square"
                  size="small"
                  icon={<Icon icon="tdesign:education" />}
                />
                <Typography className="learn-wrap-title">Education</Typography>
              </Space>
            </div>
            <div className="learn-page-row-mt">
              <Slider {...eduSettings} className="common-slider">
                {educationLength?.map((item) => (
                  <EducationCard />
                ))}
              </Slider>
            </div>
          </div>
          <div className="learn-page-rectangle-view">
            <div className="block-title">
              <Grid
                container
                rowSpacing={0}
                columnSpacing={2}
                alignItems={"center"}
              >
                <Grid item xs={7}>
                  <Space wrap size={10}>
                    <Avatar
                      className="block-title-icon learn-page-overview-avatar"
                      shape="square"
                      size="small"
                      icon={<Icon icon="radix-icons:dashboard" />}
                    />
                    <Typography className="learn-wrap-title">
                      Overview
                    </Typography>
                  </Space>
                </Grid>
                <Grid item xs={5} textAlign={"right"}>
                  <div className="btn-wrap">
                    <Button className="slider-btn prev-btn">Previous</Button>
                    <Button className="slider-btn next-btn">Next</Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="overview-tabs-wapper">
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item lg={7} xs={12}>
                  <Space size={10} wrap={true}>
                    <Tag
                      bordered={false}
                      className={
                        selectedOverviewMenu === "all"
                          ? "selected-learn-page-overview-tags"
                          : "learn-page-overview-tags"
                      }
                      onClick={() => handleOverviewSelection("all")}
                    >
                      All
                    </Tag>
                    {allTagsList?.length > 0 &&
                      allTagsList?.map((element) => (
                        <Tag
                          bordered={false}
                          className={
                            selectedOverviewMenu === element
                              ? "selected-learn-page-overview-tags"
                              : "learn-page-overview-tags"
                          }
                          onClick={() => handleOverviewSelection(element)}
                        >
                          {element}
                        </Tag>
                      ))}
                  </Space>
                </Grid>
                <Grid item lg={5} xs={12}>
                  <div className="podcasts-wrapper">
                    <Dropdown
                      menu={{
                        items,
                        onClick: ({ key }) => handleContentTypeFilter(key),
                      }}
                      trigger={["click"]}
                      className="learn-page-podcasts-tags"
                    >
                      <Space>
                        {overviewSelectedType}
                        <Icon icon="ep:arrow-down-bold" />
                      </Space>
                    </Dropdown>

                    <Input
                      className="custom-search-input"
                      prefix={
                        <Icon icon="carbon:search" className="search-icon" />
                      }
                      placeholder="Search for topics"
                      onChange={(e) =>
                        handleSearchOverviewResources(e.target.value)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {overviewResourcesData?.length > 0 ? (
              <div className="learn-page-row-mt">
                <Slider {...overviewSettings} className="common-slider">
                  {overviewResourcesData?.map((item) => (
                    <LearnCard cardData={item} />
                  ))}
                </Slider>
                <Pagination
                  className="learn-page-pagination-container"
                  current={paginationInfo?.current}
                  defaultPageSize={paginationInfo?.perPage}
                  onChange={handlePageChange}
                  total={paginationInfo?.total}
                />
              </div>
            ) : (
              <div
                className="learn-page-title"
                style={{
                  height: "110px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p>Data not found</p>
              </div>
            )}
          </div>
          <FooterButton />
        </div>
      </div>
    </div>
  );
}
