import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Input, Select, DatePicker, Space } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";
import { ReactComponent as UserIcon } from "../../../../assets/images/user.svg";
import "./nodemapModals.css";
import ConfirmationPopup from "./ConfirmationPopup";
import moment from "moment";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const editReasonsOptions = [
  { value: "Changed Priorities", label: "Changed Priorities" },
  { value: "New Opportunities", label: "New Opportunities" },
  { value: "Job Market Changes", label: "Job Market Changes" },
  { value: "renaming", label: "Renaming" },
];

const addReasonsOptions = [
  { value: "Changed Priorities", label: "Changed Priorities" },
  { value: "New Opportunities", label: "New Opportunities" },
  { value: "Job Market Changes", label: "Job Market Changes" },
];

const AddIntermediateRoleModal = ({
  isOpen,
  handleModalOpenClose,
  isAdd,
  modalData,
  nodemapId,
  setNodemapData,
  formattedCurrentRoleDate,
  formattedNorthStarDate,
  haveIntermediateNumber,
}) => {
  const { storageDetail } = useStorageContext();
  const [isCompleted, setIsCompleted] = useState(0);
  const { setIsLoading } = useLoadingContext();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEnable, setIsEnable] = useState("no");
  const [currentMode, setCurrentMode] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [tempData, setTempData] = useState({});
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [intermediatePlaceOptions, setIntermediatePlaceOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [industryVal, setIndustryVal] = useState("");
  const [functionVal, setFunctionVal] = useState("");
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [rankOptions, setRankOptions] = useState([]);
  const [domainsOptions, setDomainsOptions] = useState([]);

  useEffect(() => {
    getMethod(`${process.env.REACT_APP_API_URL}industries`).then((response) => {
      if (response.status === "S") {
        setIndustryOptions(response?.response?.result);
      } else {
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}functions`).then((response) => {
      if (response.status === "S") {
        setFunctionOptions(response?.response?.result);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (industryVal) {
      //make it dynamic once get the data now passing Technology
      getMethod(
        `${process.env.REACT_APP_API_URL}industries/?q=${industryVal}`
      ).then((response) => {
        if (response.status === "S") {
          setCompanyTypeOptions(response?.response?.result?.company_type);
          setFunctionOptions(response?.response?.result?.functions);
        } else {
          setCompanyTypeOptions([]);
          setFunctionOptions([]);
        }
      });
    }
  }, [industryVal]);

  useEffect(() => {
    if (functionVal) {
      //make it dynamic once get the data now passing IT

      getMethod(
        `${process.env.REACT_APP_API_URL}functions/?q=${functionVal}`
      ).then((response) => {
        if (response.status === "S") {
          setDomainsOptions(response?.response?.result?.domains);
          setRankOptions(response?.response?.result?.rank);
        } else {
          setDomainsOptions([]);
          setRankOptions([]);
        }
      });
    }
  }, [functionVal]);

  const reasonValidation = isTitleChanged
    ? { reason: yup.string().required("Reason for edit is required") }
    : {};
  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Minimum 1 characters required")
      .max(150, "Maximum 150 characters allowed"),
    date_range: yup
      .array()
      .of(yup.date().required("Date is required"))
      .required("Date is required")
      .test(
        "is-not-same",
        "Start and end dates should not be the same",
        (value) => {
          if (value && value.length === 2) {
            const [startDate, endDate] = value;

            // Compare the timestamps of the dates
            return startDate.getTime() !== endDate.getTime();
          } else {
            return true;
          }
        }
      ),
    place_between_id: yup
      .string()
      .required("Select entry to place under is required"),
    sector: yup
      .string()
      .required("Industry is required")
      .notOneOf(["Select Industry"], "Please select a industry")
      .nullable(),
    reason: yup.string().required("Reason for addition is required"),
    domain: yup
      .string()
      .required("Domain is required")
      .notOneOf(["Select Domain"], "Please select a domain")
      .nullable(),
    company_type: yup
      .string()
      .required("Company type is required")
      .notOneOf(["Select Company Type"], "Please select a company type")
      .nullable(),
    function: yup
      .string()
      .required("Function is required")
      .notOneOf(["Select Function"], "Please select a function")
      .nullable(),
    rank: yup
      .string()
      .required("Seniority is required")
      .notOneOf(["Select Seniority"], "Please select a seniority")
      .nullable(),
  });

  const validationSchemaForEdit = yup.object().shape({
    ...reasonValidation,
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Minimum 1 characters required")
      .max(100, "Maximum 100 characters allowed"),
    date_range: yup
      .array()
      .of(yup.date().required("Date is required"))
      .required("Date is required")
      .test(
        "is-not-same",
        "Start and end dates should not be the same",
        (value) => {
          if (value && value.length === 2) {
            const [startDate, endDate] = value;

            // Compare the timestamps of the dates
            return startDate.getTime() !== endDate.getTime();
          } else {
            return true;
          }
        }
      ),
    // reason: yup.string().required("Reason for edit is required"),
    sector: yup
      .string()
      .required("Industry is required")
      .notOneOf(["Select Industry"], "Please select a industry")
      .nullable(),
    domain: yup
      .string()
      .required("Domain is required")
      .notOneOf(["Select Domain"], "Please select a domain")
      .nullable(),
    company_type: yup
      .string()
      .required("Company type is required")
      .notOneOf(["Select Company Type"], "Please select a company type")
      .nullable(),
    function: yup
      .string()
      .required("Function is required")
      .notOneOf(["Select Function"], "Please select a function")
      .nullable(),
    rank: yup
      .string()
      .required("Seniority is required")
      .notOneOf(["Select Seniority"], "Please select a seniority")
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    getFieldState,
    watch,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: isAdd
      ? yupResolver(validationSchema)
      : yupResolver(validationSchemaForEdit),
  });

  useEffect(() => {
    getPlaceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodemapId]);

  const getPlaceOptions = () => {
    if (nodemapId) {
      getMethod(
        `${process.env.REACT_APP_API_URL}nodemap/get-entry/${nodemapId}/`
      ).then((response) => {
        if (response.status === "S") {
          setIntermediatePlaceOptions(response.response.response);
        } else {
        }
      });
    }
  };

  useEffect(() => {
    if (getFieldState("title").isDirty || isAdd) {
      setIsTitleChanged(true);
    } else {
      setIsTitleChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("title"), isAdd]);

  useEffect(() => {
    if (!isAdd && modalData) {
      // Edit case
      setIsCompleted(modalData?.progress);
      reset({
        ...modalData.intermediate_role,
        title: modalData.title,
        start_date: modalData.start_date,
        end_date: modalData.end_date,
        date_range: [
          dayjs(modalData.start_date, dateFormat),
          dayjs(modalData.end_date, dateFormat),
        ],
        milestone_id: modalData.milestone_id,
        update_timeline: "no",
        generate_milestone: "no",
      });
    } else {
      // Add case
      reset({
        title: "",
        start_date: "",
        end_date: "",
        milestone_id: "",
        update_timeline: "",
        generate_milestone: "",
      });
    }
  }, [isOpen, modalData, reset, isAdd]);

  const handleResetClose = () => {
    setIsCompleted(0);
    handleModalOpenClose(false);
    setIndustryVal("");
    setFunctionVal("");
    reset();
  };

  const handleProgress = () => {
    setIsLoading(true);
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/milestone-progress/${nodemapId}/`,
      {
        milestone_id: modalData?.milestone_id || "",
        progress: 100,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getPlaceOptions();
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const getData = () => {
    const user_id = storageDetail?.user_id;
    getMethod(
      `${process.env.REACT_APP_API_URL}nodemap/get-data/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        let res = Object.values(
          response?.response?.nodemap.reduce((acc, cur) => {
            if (!acc[cur?.end_date]) {
              acc[cur?.end_date] = [{ ...cur }];
            } else {
              acc[cur?.end_date].push(cur);
            }
            return acc;
          }, {})
        );
        setNodemapData(res);
      }
    });
  };

  const validateTitleAPIForEdit = (
    <p className="confirmation-dic">
      Your intermediate role title has changed. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to generate milestones or not?
    </p>
  );

  const validateTitleAPIflowForEdit = (data) => {
    const validateTitleAPI = (
      <p className="confirmation-dic">
        The intermediate role title is not aligned with your roadmap. Do you
        still want to save it?
      </p>
    );
    const values = getValues();
    setIsLoading(true);
    postMethod(
      `${process.env.REACT_APP_API_URL}nodemap/validate-intermediate/`,
      {
        nodemap_id: nodemapId || "",
        title: data?.title || values?.title || "",
        reason: data?.reason || values?.reason || "",
        milestone_id: modalData?.milestone_id,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          if (response?.response?.response?.matching === "Yes") {
            setCurrentMode("regularConfirmation");
            setConfirmationTitle(validateTitleAPIForEdit);
            setIsConfirmationModalOpen(true);
          } else {
            setIsLoading(false);
            setCurrentMode("alignmentConfirmation");
            setConfirmationTitle(validateTitleAPI);
            setIsConfirmationModalOpen(true);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  const handleEdit = (submittedForm) => {
    const [start_date, end_date] = submittedForm.date_range;
    let place_between =
      (intermediatePlaceOptions?.length &&
        intermediatePlaceOptions.filter(
          (milestone) => milestone.current_id === submittedForm.place_between_id
        )[0]) ||
      [];
    let apiData = {
      title: submittedForm.title,
      role_details: {
        sector: submittedForm.sector,
        domain: submittedForm.domain,
        function: submittedForm.function,
        company_type: submittedForm.company_type,
        rank: submittedForm.rank,
      },
      start_date: dayjs(start_date).format("DD/MM/YYYY"),
      end_date: dayjs(end_date).format("DD/MM/YYYY"),
      ...(isAdd
        ? { reason: submittedForm.reason }
        : isTitleChanged && { reason: submittedForm.reason }),
      place_between,
    };
    delete apiData["date_range"];
    setTempData(apiData);
    handleValidateTitle(apiData);
  };

  const validateTitle = (
    <p className="confirmation-dic">
      A new intermediate role has been added. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to update the timeline of the
      remaining milestones accordingly?
    </p>
  );

  const validateTitleRenaming = (
    <p className="confirmation-dic">
      A new intermediate role has been added. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to update the timeline of the
      remaining milestones accordingly?
    </p>
  );

  const handleValidateTitle = (data) => {
    const handleValidateTitle = (
      <p className="confirmation-dic">
        The intermediate role title is not aligned with your roadmap. Do you
        still want to save it?
      </p>
    );
    if (isAdd) {
      // ADD CASE
      setIsLoading(true);
      postMethod(
        `${process.env.REACT_APP_API_URL}nodemap/add-intermediate/validate-title/`,
        {
          nodemap_id: nodemapId || "",
          title: data?.title || "",
          reason: data?.reason || "",
        }
      )
        .then((response) => {
          if (response.status === "S") {
            setIsLoading(false);
            if (response?.response?.response?.matching === "Yes") {
              //IF USER TITLE IS ALIGN THEN ASK FOR to update the timeline of the remaining milestones accordingly.
              setCurrentMode("timeLineConfirmation");
              setConfirmationTitle(validateTitle);
              setIsConfirmationModalOpen(true);
            } else {
              setIsLoading(false);
              setCurrentMode("alignmentConfirmation");
              setConfirmationTitle(handleValidateTitle);
              setIsConfirmationModalOpen(true);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else if (isAdd === false && modalData && Object.keys(modalData).length) {
      // Edit case
      if (
        getFieldState("title").isDirty &&
        !getFieldState("date_range").isDirty
      ) {
        // If user is changing only the title
        if (data?.reason === "renaming") {
          // If REASON IS Renaming Save the title without any validation
          handleEditIntermediateAPI({
            ...data,
            reason: data.reason,
            title: data.title,
            nodemap_id: nodemapId,
            generate_milestone: "no", // default no for this case
            update_timeline: "no", // default no for this case
          });
        } else {
          // If REASON IS NOT Renaming Validate intermediate role title
          validateTitleAPIflowForEdit(data);
        }
      } else if (
        getFieldState("date_range").isDirty &&
        data?.reason === "renaming"
      ) {
        // If user is changing title, start date or end date with reason renaming.
        setCurrentMode("timeLineConfirmation");
        setConfirmationTitle(validateTitleRenaming);
        setIsConfirmationModalOpen(true);
      } else if (
        getFieldState("date_range").isDirty &&
        data?.reason &&
        data?.reason !== "renaming"
      ) {
        // If user is changing title, start date or end date with reason not renaming.
        validateTitleAPIflowForEdit(data);
      } else if (
        getFieldState("date_range").isDirty &&
        !getFieldState("title").isDirty
      ) {
        // If user is changing start date or end date
        setCurrentMode("timeLineConfirmation");
        setConfirmationTitle(validateTitleRenaming);
        setIsConfirmationModalOpen(true);
      } else {
        //If user changes fields other than title and dates.
        handleEditIntermediateAPI({
          ...data,
          nodemap_id: nodemapId,
          generate_milestone: "no", // default no for this case
          update_timeline: "no", // default no for this case
        });
      }
    } else {
      // no case
    }
  };

  const alignmentTitle = (
    <p className="confirmation-dic">
      A new intermediate role has been added. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to update the timeline of the
      remaining milestones accordingly?
    </p>
  );

  const alignmentTitleGenerate = (
    <p className="confirmation-dic">
      Your intermediate role title has changed. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to generate milestones or not?
    </p>
  );

  const handleAlignment = (isSubmit) => {
    if (isSubmit) {
      if (isAdd) {
        //In add mode IF USER WANT TO SAVE NON ALIGNMENT TITLE ASK FOR to update the timeline of the remaining milestones accordingly.
        setCurrentMode("timeLineConfirmation");
        setConfirmationTitle(alignmentTitle);
      } else {
        //In edit mode IF USER WANT TO SAVE NON ALIGNMENT TITLE ASK FOR to generate milestones or not
        setCurrentMode("regularConfirmation");
        setConfirmationTitle(alignmentTitleGenerate);
      }
      setIsConfirmationModalOpen(true);
    } else {
    }
  };

  const timeLineTitle = (
    <p className="confirmation-dic">
      A new intermediate role has been added. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to update the timeline of the
      remaining milestones accordingly?
    </p>
  );

  const timeLineConfirmTitle = (
    <p className="confirmation-dic">
      A new intermediate role has been added. Would you like{" "}
      <b style={{ fontWeight: 800 }}>youe AI</b> to update the timeline of the
      remaining milestones accordingly?
    </p>
  );

  const handleTimeLineModalOpen = (isEnable) => {
    setIsEnable(isEnable ? "yes" : "no");
    if (isAdd) {
      //ASK FOR to update the timeline of the remaining milestones accordingly.
      setCurrentMode("timeLineConfirmation");
      setConfirmationTitle(timeLineTitle);
      setTimeout(() => {
        setIsConfirmationModalOpen(true);
      }, 500);
    } else if (!getFieldState("date_range").isDirty) {
      handleEditIntermediateAPI({
        ...tempData,
        generate_milestone: isEnable ? "yes" : "no",
        update_timeline: "no", // default no for this case
      });
    } else {
      setCurrentMode("timeLineConfirmation");
      setConfirmationTitle(timeLineConfirmTitle);
      setTimeout(() => {
        setIsConfirmationModalOpen(true);
      }, 500);
    }
  };

  const handleSaveFinal = (updateTimeline) => {
    if (isAdd) {
      handleAddIntermediateAPI({
        ...tempData,
        nodemap_id: nodemapId,
        update_timeline: updateTimeline ? "yes" : "no",
      });
    } else if (!isAdd && modalData && Object.keys(modalData).length) {
      handleEditIntermediateAPI({
        ...tempData,
        nodemap_id: nodemapId,
        milestone_id: modalData?.milestone_id,
        generate_milestone: isEnable || "no",
        update_timeline: updateTimeline ? "yes" : "no",
      });
    }
  };

  const handleAddIntermediateAPI = (params) => {
    setIsLoading(true);
    postMethod(`${process.env.REACT_APP_API_URL}nodemap/add-intermediate/`, {
      ...params,
    })
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getPlaceOptions();
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const handleEditIntermediateAPI = (params) => {
    setIsLoading(true);
    delete params["place_between"];
    patchTripdata(
      `${process.env.REACT_APP_API_URL}nodemap/edit-intermediate/${nodemapId}/`,
      {
        ...params,
        milestone_id: modalData?.milestone_id,
        update_timeline: params?.update_timeline,
        generate_milestone: params?.generate_milestone,
      }
    )
      .then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          getPlaceOptions();
          getData();
          handleResetClose();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const disabledDates = (current) => {
    return (
      (current && current < moment(formattedCurrentRoleDate, "DD/MM/YYYY")) ||
      moment(formattedNorthStarDate, "DD/MM/YYYY") <= current
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <div className="collapse-icon border-yellow user-icon">
                    <UserIcon />
                  </div>
                  <p>
                    {" "}
                    {isAdd
                      ? "Add Intermediate Role"
                      : "Edit Intermediate Role"}{" "}
                    {haveIntermediateNumber !== false && !isAdd
                      ? `0${haveIntermediateNumber + 1}`
                      : ""}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleResetClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="">Title</label>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="Short Term Goal 03"
                        />
                      )}
                    />
                    {errors.title && (
                      <p className="custom-modal-error">
                        {errors.title.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Date</label>
                      <Controller
                        name="date_range"
                        control={control}
                        render={({ field }) => (
                          <RangePicker
                            {...field}
                            disabledDate={disabledDates}
                            format={"DD/MM/YYYY"}
                            className="custom_input startdatepicker-control"
                            rootClassName="range-datepicker"
                          />
                        )}
                      />
                      {errors?.date_range && (
                        <p className="custom-modal-error">
                          {errors?.date_range?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Industry</label>
                      <Controller
                        name="sector"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Industry"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={industryOptions.map((sec) => ({
                              label: sec,
                              value: sec,
                            }))}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              setIndustryVal(selectedOption);
                            }}
                          />
                        )}
                      />
                      {errors?.sector && (
                        <p className="custom-modal-error">
                          {errors?.sector?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div
                      className={`select-field ${
                        companyTypeOptions?.length === 0 ? "is-disabled" : ""
                      }`}
                    >
                      <label htmlFor="">Company Type</label>
                      <Controller
                        name="company_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Company Type"
                            disabled={
                              companyTypeOptions?.length === 0 ? true : false
                            }
                            dropdownStyle={{ zIndex: 2000 }}
                            options={companyTypeOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          />
                        )}
                      />
                      {errors?.company_type &&
                        companyTypeOptions?.length !== 0 && (
                          <p className="custom-modal-error">
                            {errors?.company_type?.message}
                          </p>
                        )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="">Function</label>
                      <Controller
                        name="function"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Function"
                            dropdownStyle={{ zIndex: 2000 }}
                            options={functionOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              setFunctionVal(selectedOption);
                            }}
                          />
                        )}
                      />
                      {errors?.function && (
                        <p className="custom-modal-error">
                          {errors?.function?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div
                      className={`select-field ${
                        domainsOptions?.length === 0 ? "is-disabled" : ""
                      }`}
                    >
                      <label htmlFor="">Domain</label>
                      <Controller
                        name="domain"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Domain"
                            disabled={
                              domainsOptions?.length === 0 ? true : false
                            }
                            dropdownStyle={{ zIndex: 2000 }}
                            options={domainsOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          />
                        )}
                      />
                      {errors?.domain && domainsOptions?.length !== 0 && (
                        <p className="custom-modal-error">
                          {errors?.domain?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className={`select-field ${
                        rankOptions?.length === 0 ? "is-disabled" : ""
                      }`}
                    >
                      <label htmlFor="">Seniority</label>
                      <Controller
                        name="rank"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="select-design-signin-ant"
                            placeholder="Select Seniority"
                            disabled={rankOptions?.length === 0 ? true : false}
                            dropdownStyle={{ zIndex: 2000 }}
                            options={rankOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          />
                        )}
                      />
                      {errors?.rank && rankOptions?.length !== 0 && (
                        <p className="custom-modal-error">
                          {errors?.rank?.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  {isTitleChanged && (
                    <Grid item xs={12}>
                      <div className="select-field">
                        <label htmlFor="">{`Reason for ${
                          isAdd ? "addition" : "edit"
                        }`}</label>
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="select-design-signin-ant"
                              placeholder="Select Reason"
                              dropdownStyle={{ zIndex: 2000 }}
                              options={
                                isAdd ? addReasonsOptions : editReasonsOptions
                              }
                            />
                          )}
                        />
                        {errors?.reason && (
                          <p className="custom-modal-error">
                            {errors?.reason?.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  )}
                  {isAdd && (
                    <Grid item xs={12}>
                      <div className="select-field">
                        <label htmlFor="">Select an entry to place under</label>
                        <Controller
                          name="place_between_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="select-design-signin-ant"
                              {...field}
                              placeholder="Select Place"
                              dropdownStyle={{ zIndex: 2000 }}
                            >
                              {intermediatePlaceOptions?.map((option) => (
                                <Option
                                  key={option.current_id}
                                  value={option.current_id}
                                >
                                  {option.current_title} to {option.next_title}
                                </Option>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.place_between_id && (
                          <p className="custom-modal-error">
                            {errors.place_between_id.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {!isAdd && (
                    <>
                      {isCompleted === 100 ? (
                        <Button className="small-btn check-btn completed-btn">
                          <Icon icon="icon-park-outline:check-one" /> Completed
                        </Button>
                      ) : (
                        <Button
                          className="small-btn check-btn mark-as-completed-btn"
                          onClick={() => {
                            handleProgress();
                            setIsCompleted(100);
                          }}
                        >
                          <Icon icon="icon-park-outline:check-one" /> Mark as
                          completed
                        </Button>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item>
                  <Space size={15}>
                    <Button
                      className="small-btn text-color-grey px-20"
                      onClick={handleResetClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="small-btn primary-btn px-20"
                      type="submit"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Grid>
              </Grid>
            </form>
          </Box>
          <ConfirmationPopup
            isOpen={isConfirmationModalOpen}
            setIsOpen={setIsConfirmationModalOpen}
            title={confirmationTitle}
            handleSaveData={handleTimeLineModalOpen}
            handleSaveTimeline={handleSaveFinal}
            handleAlignment={handleAlignment}
            mode={currentMode}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddIntermediateRoleModal;
