import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import WelcomeStepper from "./WelcomeStepper";
import "../CSS/Welcome.css";

const Welcome = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });
  const {} = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <div className="Welcome-signin-page">
        <WelcomeStepper />
      </div>
    </>
  );
};
export default Welcome;
