import React, { createContext, useContext, useState } from "react";

const ProfileHeaderData = createContext();

export function ProfileHeaderProvider({ children }) {
  const [profileDashboardData, setProfileDashboardData] = useState({});

  return (
    <ProfileHeaderData.Provider
      value={{ profileDashboardData, setProfileDashboardData }}
    >
      {children}
    </ProfileHeaderData.Provider>
  );
}

export function useProfileDashboardData() {
  return useContext(ProfileHeaderData);
}
