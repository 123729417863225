/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../CSS/ProfilePage.css";
import { Button, Input, Switch, Space, notification } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
  postMethod,
} from "../../Api'sCall/Apicalling";
import EditPasswordModal from "./editProfileModals/EditPasswordModal";
import EditEmailModal from "./editProfileModals/EditEmailModal";
import EditPhoneNumberModal from "./editProfileModals/EditPhoneNumberModal";
import { useLoadingContext } from "../../BeforeLogin/Context/loadingContext";
import ActionConfirmationPopup from "./actionConfirmation";
import { toast } from "react-toastify";
import { useUserBasicData } from "../Context/basicDetails";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const AccountSettings = ({ getBasiUserDetails }) => {
  const navigate = useNavigate();
  const { storageDetail } = useStorageContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [accountSettingData, setAccountSettingData] = useState({});
  const { setIsLoading } = useLoadingContext();
  const [profileImgError, SetProfileImageError] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { userBasicData, setUserBasicData } = useUserBasicData();
  const [comingSoonVisible, setComingSoonVisible] = useState({
    handleUser: false,
    requestData: false,
    linkedin: false,
  });

  const handleCommingSoon = (name) => {
    setComingSoonVisible((prevState) => ({ ...prevState, [name]: true }));
  };
  const handleRemoveCommingSoon = (name) => {
    setComingSoonVisible((prevState) => ({ ...prevState, [name]: false }));
  };

  const title = (
    <p className="confirmation-dic">
      Are you sure you want to delete your{" "}
      <b style={{ fontWeight: 800 }}>youe</b> account?
    </p>
  );

  const validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "First name must contain at least one alphabet character"
      )
      .test(
        "no-leading-trailing-spaces",
        "Initial and trailing spaces are not allowed",
        (value) => !/^\s|\s$/.test(value)
      )
      .min(1, "First name must contain at least one character")
      .max(35, "Maximum 35 characters allowed"),
    last_name: yup
      .string()
      .required("Last name is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Last name must contain at least one alphabet character"
      )
      .test(
        "no-leading-trailing-spaces",
        "Initial and trailing spaces are not allowed",
        (value) => !/^\s|\s$/.test(value)
      )
      .min(1, "Last name must contain at least one character")
      .max(35, "Maximum 35 characters allowed"),
    email: yup.string().optional(),
    password: yup.string().optional().nullable(),
    country_code: yup.string().optional(),
    phone_number: yup.string().optional(),
    "2fa": yup.boolean().optional(),
    linkedin: yup.boolean().optional(),
    profile_image: yup.mixed().nullable(),
    account_integrations: yup
      .object()
      .shape({
        linkedin: yup.boolean().optional(),
        Naukri: yup.boolean().optional(),
      })
      .optional(),
    communication_preferences: yup
      .object()
      .shape({
        account_notifications: yup.boolean().optional(),
        product_updates: yup.boolean().optional(),
        newsletter: yup.boolean().optional(),
      })
      .optional(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getData = () => {
    setIsLoading(true);
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}account-settings?id=${storageDetail?.user_id}`
      ).then((response) => {
        if (response.status === "S") {
          setAccountSettingData(response.response);
          reset({
            first_name: response.response.first_name,
            last_name: response.response.last_name,
            email: response.response.email,
            password: response.response.password,
            phone_number: response.response.phone_number,
            country_code: response.response.country_code,
            "2fa": response.response["2fa"] || false,
            account_notifications:
              response.response.communication_preferences.account_notifications,
            product_updates:
              response.response.communication_preferences.product_updates,
            newsletter: response.response.communication_preferences.newsletter,
          });
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      reset({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        country_code: "",
      });
    }
  };

  const getUserDetails = () => {
    const user_id = storageDetail?.user_id;
    getMethod(
      `${process.env.REACT_APP_API_URL}account-settings/username/?id=${user_id}`
    ).then((response) => {
      if (response.status === "S") {
        setUserBasicData(response.response);
      } else {
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("user_id", storageDetail?.user_id);
      postMethod(
        `${process.env.REACT_APP_API_URL}account-settings/update-profile-image/`,
        formData
      ).then((response) => {
        if (response.status === "S") {
          getData();
          getBasiUserDetails();
          getUserDetails();
        } else {
        }
      });
    }
  }, [selectedFile]);

  const handleFileChange = (e) => {
    SetProfileImageError("");
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      SetProfileImageError("File size exceeds 2 MB limit.");
    }
  };

  const removeHandler = () => {
    const user_id = storageDetail?.user_id;
    deleteTrip(
      `${process.env.REACT_APP_API_URL}account-settings/delete-profile-image/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        getData();
        getBasiUserDetails();
        getUserDetails();
      } else {
      }
    });
  };

  const onSavePersonalInfo = (editData) => {
    const user_id = storageDetail?.user_id;
    let apiParams = {
      ...editData,
      communication_preferences: {
        ...accountSettingData.communication_preferences,
      },
      account_integrations: {
        ...accountSettingData.account_integrations,
      },
    };
    delete apiParams["phoneObj"];
    if (user_id) {
      patchTripdata(
        `${process.env.REACT_APP_API_URL}account-settings/update-personal-info/?id=${user_id}`,
        apiParams
      ).then((response) => {
        if (response.status === "S") {
          getBasiUserDetails();
          getData();
          getUserDetails();
        }
      });
    }
  };

  const handleTwoFactor = (changed_pref_data) => {
    const user_id = storageDetail?.user_id;
    const value = changed_pref_data["2fa"];
    const data = { user_id, "2fa": value };
    if (user_id) {
      postMethod(
        `${process.env.REACT_APP_API_URL}account-settings/update-2fa/`,
        data
      ).then((response) => {
        if (response.status === "S") {
          getData();
        } else {
        }
      });
    }
  };

  const handleCommunicationPreference = (changed_pref_data) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      const communication_pref = {
        ...accountSettingData.communication_preferences,
        ...changed_pref_data,
      };
      patchTripdata(
        `${process.env.REACT_APP_API_URL}account-settings/update-communication-preferences/?id=${user_id}`,
        communication_pref
      ).then((response) => {
        if (response.status === "S") {
          getData();
        }
      });
    }
  };

  const callDeleteAPI = () => {
    const user_id = storageDetail?.user_id;
    deleteTrip(
      `${process.env.REACT_APP_API_URL}delete-account/${user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        // openNotificationWithIcon("success");
        toast.success("Account deleted successfully.");
        setTimeout(() => {
          localStorage.removeItem("user_id");
          navigate("/login");
        }, 2000);
      }
    });
  };

  const handleDelete = () => {
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="account-setting-wrapper">
      {contextHolder}
      <form id="account-setting" onSubmit={handleSubmit(onSavePersonalInfo)}>
        <div className="card-outer-wrap">
          <div className="card-warpper">
            <p className="wrapTitle">Personal Informations</p>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Grid
                  container
                  spacing={{ md: 2.5, xs: 2 }}
                  alignItems={"center"}
                >
                  <Grid item>
                    <div className="profilepage-Rectangle-profile-image">
                      <span className="profilepage-image">
                        {accountSettingData?.profile_image && (
                          <img
                            className="user-profile"
                            src={`${accountSettingData?.profile_image}`}
                            alt="profile"
                          />
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item>
                    <p className="wrapTitle upload-new-img">Upload new image</p>
                    <p
                      className={`'profilepage-Berlin-Germany' ${
                        profileImgError ? "image-upload-error" : ""
                      }`}
                    >
                      Max file size - 2 mb
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={8} lg={8} md={6} xs={12}>
                <Space size={15} wrap>
                  <div>
                    <Input
                      type="file"
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      id="fileInput"
                    />
                    <Button
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                      className="small-btn primary-btn"
                    >
                      Upload
                    </Button>
                  </div>
                  <Button onClick={removeHandler} className="small-btn">
                    Remove Image
                  </Button>
                </Space>
              </Grid>
            </Grid>
            <div className="personal-information-form">
              <Grid container spacing={3} className="skill_role_side">
                <Grid item lg={10} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={6} xs={12}>
                      <label htmlFor="first_name">First Name</label>
                      <Controller
                        name="first_name"
                        control={control}
                        defaultValue={
                          accountSettingData && accountSettingData?.first_name
                        }
                        render={({ field }) => (
                          <Input
                            {...field}
                            defaultValue={
                              accountSettingData &&
                              accountSettingData?.first_name
                            }
                            className="custom_input"
                            placeholder="First Name"
                          />
                        )}
                      />
                      {errors?.first_name && (
                        <p className="custom-modal-error">
                          {errors?.first_name.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <label htmlFor="last_name">Last Name</label>
                      <Controller
                        name="last_name"
                        control={control}
                        defaultValue={accountSettingData?.last_name}
                        render={({ field }) => (
                          <Input
                            defaultValue={accountSettingData?.last_name}
                            {...field}
                            className="custom_input"
                            placeholder="Last Name"
                          />
                        )}
                      />
                      {errors?.last_name && (
                        <p className="custom-modal-error">
                          {errors?.last_name.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item lg={10} md={12}>
                      <Grid container spacing={3}>
                        <Grid item lg={4} md={6} xs={12}>
                          <label htmlFor="">Email address</label>
                          <div className="edit-field">
                            <EditEmailModal
                              getData={getData}
                              prevEmail={accountSettingData?.email}
                              getBasiUserDetails={getBasiUserDetails}
                            />
                            <Controller
                              name="email"
                              readOnly
                              control={control}
                              value={accountSettingData?.email}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  readOnly
                                  value={accountSettingData?.email}
                                  className="custom_input read-only"
                                  placeholder="Email address"
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                          <label htmlFor="">Password</label>
                          <div className="edit-field">
                            <EditPasswordModal getData={getData} />
                            <Controller
                              name="password"
                              control={control}
                              value={
                                accountSettingData?.password
                                  ? accountSettingData?.password
                                  : ""
                              }
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="password"
                                  readOnly
                                  value={
                                    accountSettingData?.password
                                      ? accountSettingData?.password
                                      : ""
                                  }
                                  className="custom_input read-only"
                                  placeholder="Password"
                                />
                              )}
                            />
                            {/* {errors.password && (
                              <p className="custom-modal-error">
                                {errors.password.message}
                              </p>
                            )} */}
                          </div>
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                          <label htmlFor="phone_number">Phone Number</label>
                          <div className="edit-field">
                            <EditPhoneNumberModal
                              getData={getData}
                              defaultPhoneNumber={`${
                                accountSettingData?.country_code
                              }${accountSettingData?.phone_number?.toString()}`}
                            />
                            <Controller
                              name="phone_number"
                              readOnly
                              control={control}
                              value={`${accountSettingData?.country_code}${accountSettingData?.phone_number}`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  readOnly
                                  value={`${accountSettingData?.country_code}${accountSettingData?.phone_number}`}
                                  className="custom_input read-only"
                                  placeholder="Phone Number"
                                />
                              )}
                            />
                            {errors.phone_number && (
                              <p className="custom-modal-error">
                                {errors.phone_number.message}
                              </p>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      xs={12}
                      alignSelf={"flex-end"}
                      textAlign={"right"}
                    >
                      <Button
                        className="btn primary primary-btn"
                        htmlType="submit"
                        // type="submit"
                        form="account-setting"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="card-outer-wrap">
          <div className="card-warpper pb-4">
            <p className="wrapTitle">Security and Privacy Settings</p>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item md={7} xs={10}>
                <p className="wrapTitle mb-0">
                  Two Factor Authentication (2FA)
                </p>
                <p className="card-description">
                  For enhanced security, we highly recommend enabling two-factor
                  authentication (2FA) for your account. This adds an extra
                  layer of protection to ensure that only you can access your
                  account, even if someone knows your password.
                </p>
              </Grid>
              <Grid item md={5} xs={2}>
                <Controller
                  name="2fa"
                  control={control}
                  defaultValue={accountSettingData["2fa"] || false}
                  render={({ field }) => (
                    <Space className="w-100 justify-content-between">
                      <Switch
                        {...field}
                        className="default-switch-btn"
                        checked={field.value}
                        onChange={(booleanVal) => {
                          field.onChange(booleanVal);
                          handleTwoFactor({
                            "2fa": booleanVal,
                          });
                        }}
                      />
                      {errors["2fa"] && (
                        <p className="custom-modal-error">
                          {errors["2fa"].message}
                        </p>
                      )}
                    </Space>
                  )}
                />
              </Grid>
              <Grid item md={7} xs={12} alignSelf={"center"}>
                <p className="wrapTitle mb-0">Youe Data Privacy</p>
              </Grid>
              <Grid item md={5} xs={12}>
                <div className="softskills pt-0">
                  <span
                    className="softskill-item cursor-pointer"
                    onMouseEnter={() => handleCommingSoon("handleUser")}
                    onMouseLeave={() => handleRemoveCommingSoon("handleUser")}

                    // onClick={() =>
                    //   window.open(
                    //     "https://mountain-vulture-6f0.notion.site/How-we-handle-user-data-c05ab52aac5748fdb455d83435407979?pvs=4",
                    //     "_blank",
                    //     "noreferrer"
                    //   )
                    // }
                  >
                    {comingSoonVisible.handleUser && (
                      <div className="comming-soon-nudge">Coming Soon</div>
                    )}
                    How we handle user data?
                  </span>
                  <span
                    className="softskill-item cursor-pointer"
                    onMouseEnter={() => handleCommingSoon("requestData")}
                    onMouseLeave={() => handleRemoveCommingSoon("requestData")}
                  >
                    {comingSoonVisible.requestData && (
                      <div className="comming-soon-nudge">Coming Soon</div>
                    )}
                    Request Data
                  </span>
                </div>
              </Grid>
              <Grid item md={7} xs={12} alignSelf={"center"}>
                <p className="wrapTitle mb-0">Delete Account</p>
              </Grid>
              <Grid item md={5} xs={12}>
                <span
                  className="softskill-item delete-acc"
                  onClick={handleDelete}
                >
                  Delete Account
                </span>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className="card-outer-wrap">
              <div className="card-warpper pb-4">
                <p className="wrapTitle">Communication</p>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="account_notifications"
                      control={control}
                      defaultValue={
                        accountSettingData?.communication_preferences
                          ?.account_notifications || false
                      }
                      render={({ field }) => (
                        <Space className="w-100 justify-content-between">
                          <p className="wrapTitle mb-0">
                            Account Notifications
                          </p>
                          <Switch
                            {...field}
                            className="default-switch-btn"
                            checked={field.value}
                            onChange={(booleanVal) => {
                              field.onChange(booleanVal);
                              handleCommunicationPreference({
                                account_notifications: booleanVal,
                              });
                            }}
                          />
                          {errors.account_notifications && (
                            <p className="custom-modal-error">
                              {errors.account_notifications.message}
                            </p>
                          )}
                        </Space>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="product_updates"
                      control={control}
                      defaultValue={
                        accountSettingData?.communication_preferences
                          ?.product_updates || false
                      }
                      render={({ field }) => (
                        <Space className="w-100 justify-content-between">
                          <p className="wrapTitle mb-0">Product Updates</p>
                          <Switch
                            {...field}
                            className="default-switch-btn"
                            checked={field.value}
                            onChange={(booleanVal) => {
                              field.onChange(booleanVal);
                              handleCommunicationPreference({
                                product_updates: booleanVal,
                              });
                            }}
                          />
                        </Space>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="newsletter"
                      control={control}
                      defaultValue={
                        accountSettingData?.communication_preferences
                          ?.newsletter || false
                      }
                      render={({ field }) => (
                        <Space className="w-100 justify-content-between">
                          <p className="wrapTitle mb-0">Newsletter</p>
                          <Switch
                            {...field}
                            className="default-switch-btn"
                            checked={field.value}
                            onChange={(booleanVal) => {
                              field.onChange(booleanVal);
                              handleCommunicationPreference({
                                newsletter: booleanVal,
                              });
                            }}
                          />
                        </Space>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className="card-outer-wrap">
              <div
                className="card-warpper pb-4"
                onMouseEnter={() => handleCommingSoon("linkedin")}
                onMouseLeave={() => handleRemoveCommingSoon("linkedin")}
              >
                {comingSoonVisible.linkedin && (
                  <div className="comming-soon-nudge comming-soon-referral">
                    Coming Soon
                  </div>
                )}
                <p className="wrapTitle">Account Integrations</p>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="greybg-card">
                      <div>
                        <Space className="w-100 justify-content-between">
                          <p className="employee-role mb-0">LinkedIn</p>
                          <Controller
                            name="linkedin"
                            control={control}
                            defaultValue={
                              accountSettingData?.account_integrations
                                ?.linkedin || false
                            }
                            render={({ field }) => (
                              <Space className="w-100 justify-content-between">
                                <Switch
                                  {...field}
                                  className="default-switch-btn"
                                  checked={field.value}
                                  // onChange={(booleanVal) => {
                                  //   field.onChange(booleanVal);
                                  //   handleLinkedin({
                                  //     linkedin: booleanVal,
                                  //   });
                                  // }}
                                />
                                {errors.linkedin && (
                                  <p className="custom-modal-error">
                                    {errors.linkedin.message}
                                  </p>
                                )}
                              </Space>
                            )}
                          />
                        </Space>
                        <p className="card-description">
                          Integrate your LinkedIn to share achievements, sync
                          contacts, and boost your profile. Unlock professional
                          opportunities with ease.
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <ActionConfirmationPopup
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        title={title}
        onConfirm={callDeleteAPI}
      />
    </div>
  );
};
export default AccountSettings;
