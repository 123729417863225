import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../../assets/images/youe_logo.svg";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import Footer from "./Footer";
import OTPDesign from "./OTPDesign";
import { useStepContext } from "./Context/activeStepContext";
import LinkedinBtn from "../CustomComponents/LinkedinBtn";
import "../CSS/login.css";
import { useLogoutContext } from "./Context/logoutMessageContext";
import { AES, enc } from "crypto-js";
import { useStorageContext } from "./Context/storageContext";
import { useLoadingContext } from "./Context/loadingContext";

const Login = () => {
  const { setStep } = useStepContext();
  const { setIsLogout, isLogout } = useLogoutContext();
  const { storageDetail, setStorageDetail } = useStorageContext();
  const { setIsLoading } = useLoadingContext();
  const [apiError, setApiError] = useState();
  const [isFrom, setIsFrom] = useState("");
  const [isFocus, setFocus] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState();
  const emailFormat =
    /^[a-zA-Z]+(?:.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required")
      .matches(emailFormat, "Please provide a valid Email")
      .max(100, "Maximum 100 characters allowed"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must contain at least 8 characters")
      .max(40, "Maximum 40 characters allowed"),
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const { handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
      if (name == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  }

  useEffect(() => {
    const rememberedPassword = getCookie("password"),
      rememberedEmail = getCookie("userEmail");
    if (rememberedEmail && rememberedPassword) {
      setRememberMe(true);
      var bytes = AES.decrypt(rememberedPassword, "MySecreKey");
      var originalText = bytes.toString(enc.Utf8);
      setValue("email", rememberedEmail);
      setValue("password", originalText);
    } else {
      setRememberMe(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      setIsLoading(true);
      getMethod(
        `${process.env.REACT_APP_API_URL}onboarding-progress/${storageDetail?.user_id}/`
      ).then((response) => {
        // if (response.status === "S") {
        //   setStep(response.response.onboarding_step + 4);
        // }
        if (response.status === "S") {
          // sessionStorage.setItem("user_id", response.response.user_id);
          if (response?.response?.onboarding_status === 1) {
            // on-boarding incomplete
            // sessionStorage.setItem("user_id", response.response.user_id);
            const redirectStep = response?.response?.onboarding_step;

            [1, 2, 3, 4].includes(redirectStep) && setStep(redirectStep + 4);
            navigate("/email-signup");
            // setStep(redirectStep);
            if (redirectStep === 5 || redirectStep === 6) {
              getQuestionThreeData(redirectStep);
            } else if (redirectStep === 8) {
              setStep(11);
            } else if (redirectStep === 7) {
              setStep(redirectStep + 4);
            } else if (redirectStep === 0) {
              setStep(redirectStep + 4);
            }
          } else if (response.response.onboarding_status === 2) {
            navigate("/dashboard");
          }
        }
        setIsLoading(false);
      });
    } else {
      setStep(1);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentPlan = () => {
    const user_id = storageDetail.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/current-plan/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          localStorage.setItem("payment", true);
        } else {
          localStorage.setItem("payment", false);
        }
      });
    } else {
    }
  };

  const getIntermediateData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/intermediate-role/${storageDetail.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        setStep(11);
      } else {
        setStep(10);
      }
    });
  };

  const getQuestionThreeData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === true
        ) {
          // if clear_vision ="yes"
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            // setStep(9 + 1);
            getIntermediateData();
          } else {
            // Q4
            setStep(10 + 1);
          }
        } else if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === false
        ) {
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            // setStep(9);
            getIntermediateData();
          } else {
            // if clear_vision ="no" => Runway recommendation
            setStep(11);
          }
        }
      }
    });
  };

  const onSubmit = (data) => {
    postMethod(`${process.env.REACT_APP_API_URL}login/`, data).then(
      (response) => {
        if (response.status === "S") {
          setStorageDetail({ user_id: response.response.user_id });
          // sessionStorage.setItem("user_id", response.response.user_id);
          if (response?.response?.onboarding_status === 1) {
            // on-boarding incomplete
            setStorageDetail({ user_id: response.response.user_id });
            // sessionStorage.setItem("user_id", response.response.user_id);
            const redirectStep = response?.response?.redirect_step;
            [1, 2, 3, 4, 5].includes(redirectStep) && setStep(redirectStep + 3);
            navigate("/email-signup");
            if (redirectStep === 6) {
              getQuestionThreeData();
            } else if (redirectStep === 8) {
              setStep(11);
            } else if (redirectStep === 7) {
              setStep(redirectStep + 4);
            }
          } else if (
            response.response.onboarding_status === 2 &&
            response?.response?.two_fact_auth === false
          ) {
            setLoginData(response.response);
            getCurrentPlan();
            navigate("/dashboard");
          } else if (
            response?.response?.onboarding_status === 2 &&
            response?.response?.two_fact_auth === true
          ) {
            setLoginData(response.response);
            setIsFrom("login2FA");
            setStep(2);
          }
        } else {
          const error_msg = response.errorData.response.data.message;
          if (
            error_msg ===
            "This account does not exist or This Account is not Registered with Nudge2edge. "
          )
            setApiError("This account does not exist!.");
          else setApiError(error_msg);
        }
      }
    );
    if (rememberMe) {
      const secret = "MySecreKey";
      const encrypted = AES.encrypt(data?.password, secret).toString();
      document.cookie = `userEmail=${data?.email}`;
      document.cookie = `password=${encrypted}`;
    } else {
      document.cookie =
        "userEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (rememberMeValue === "true") {
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLogout(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loginData && (
        <div className="login-con login-con-hauto">
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div>
              {isLogout && (
                <div className="common-toaster">
                  <Icon
                    icon="material-symbols:logout"
                    className="toaster-icon"
                  />
                  You have been successfully logged out.
                </div>
              )}
              <div className="login-container">
                <div className="Welcome-back-to-con-main">
                  <div className="Welcome-back-to-con back-to-width-login">
                    <h2 className="">Welcome back to</h2>
                    <span className="logo">
                      <img src={Logo} alt="logo" />
                    </span>
                  </div>
                </div>
                <div className="login-field">
                  {apiError && (
                    <div className="error-custom-login backend-error">
                      {apiError}
                    </div>
                  )}
                  <Controller
                    control={control}
                    name="email"
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => {
                      return (
                        <div className={error ? "input-con-e" : "input-con"}>
                          <Icon
                            icon={"majesticons:mail-line"}
                            className={
                              error
                                ? "failure-icon icon"
                                : value && isFocus?.email
                                ? "sucess-icon icon"
                                : "icon"
                            }
                          />
                          <input
                            type="text"
                            name={name}
                            onBlur={() => {
                              setFocus((prev) => ({ ...prev, [name]: false }));
                            }}
                            onChange={({ target: { value } }) => {
                              setApiError("");
                              setValue(name, value, { shouldValidate: true });
                              setFocus((prev) => ({ ...prev, [name]: true }));
                            }}
                            value={value}
                            placeholder="Enter your email address"
                            className={
                              error
                                ? "outline-failure input-design"
                                : value && isFocus?.email
                                ? "outline-success input-design"
                                : "input-design"
                            }
                          ></input>
                          <div className="error-custom-login">
                            {error?.message}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="password"
                    render={({
                      field: { value, name },
                      fieldState: { error },
                    }) => (
                      <div className={error ? "input-con-e" : "input-con"}>
                        <Icon
                          icon={
                            showPassword
                              ? "iconamoon:eye"
                              : "octicon:eye-closed-16"
                          }
                          className={
                            error
                              ? "failure-icon eye-password"
                              : value && isFocus?.password
                              ? "sucess-icon eye-password"
                              : "eye-password"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        />

                        <Icon
                          icon={"majesticons:lock-line"}
                          className={
                            error
                              ? "failure-icon icon"
                              : value && isFocus?.password
                              ? "sucess-icon icon"
                              : "icon"
                          }
                        />
                        <input
                          type={showPassword ? "text" : "password"}
                          name={name}
                          onBlur={() => {
                            setFocus((prev) => ({ ...prev, [name]: false }));
                          }}
                          onChange={({ target: { value } }) => {
                            setApiError("");
                            setValue(name, value, { shouldValidate: true });
                            setFocus((prev) => ({ ...prev, [name]: true }));
                          }}
                          value={value}
                          placeholder="Enter password"
                          className={
                            error
                              ? "outline-failure input-design"
                              : value && isFocus?.password
                              ? "outline-success input-design"
                              : "input-design"
                          }
                        ></input>
                        <div className="error-custom-login">
                          {error?.message}
                        </div>
                      </div>
                    )}
                  />
                  <button type="submit" className="login-btn primary_btn">
                    Continue
                  </button>
                  <div className="remember">
                    <div className="remember-checkbox">
                      <div className="checkbox-wrapper-28 checkbox-svg-wrapper">
                        <input
                          id="tmp-28"
                          type="checkbox"
                          className="promoted-input-checkbox"
                          onChange={handleRememberMeChange}
                          checked={rememberMe}
                        />
                        <svg>
                          <use href="#checkmark-28" />
                        </svg>
                        <label for="tmp-28"></label>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                          className="checkicon"
                        >
                          <g fill="#FF7228" fill-rule="evenodd">
                            <path d="M12.707 6.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L8 9.586l3.293-3.293a1 1 0 0 1 1.414 0z" />
                          </g>
                        </svg>
                      </div>
                      <h6 className="remember-span1"> Remember me</h6>
                    </div>
                    <span
                      className="remember-span1-forgot"
                      onClick={() => navigate("/reset-password")}
                    >
                      Forgot Password
                    </span>
                  </div>
                  <div className="Welocme-line-login"></div>
                  <LinkedinBtn />
                  <div className="Welocme-line-login"></div>
                  <div className="Welcome-signin">
                    Do you have an account?{" "}
                    <span
                      className="signup"
                      onClick={() => {
                        navigate("/email-signup");
                      }}
                    >
                      Sign Up
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Footer step={1} />
        </div>
      )}
      {loginData && Object.keys(loginData).length > 0 && (
        <OTPDesign email={loginData?.email} isFrom={isFrom} />
      )}
    </>
  );
};

export default Login;
