import React, { useEffect } from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, DatePicker, Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import dayjs from "dayjs";
import DeleteIcon from "../../../../assets/images/delete.png";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import "../../../CSS/Welcome.css";
import "./modals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { MonthPicker } = DatePicker;

const EditAchievementsModal = ({
  data,
  setProfileData,
  profiledata,
  isAdd,
  currentModalData,
  setCurrentModalData,
  isOpen,
  handleModalOpenClose,
}) => {
  const { storageDetail } = useStorageContext();
  const handleResetClose = () => {
    reset();
    setCurrentModalData({});
    handleModalOpenClose(false);
  };

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Title must contain at least one alphabet character"
      )
      .min(1, "Title must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    from_organization: yup
      .string()
      .required("From is required")
      .trim()
      .matches(/[a-zA-Z]+/, "From must contain at least one alphabet character")
      .min(1, "From must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    exhibited_on: yup
      .date()
      .required("Exhibited Date is required")
      .test(
        "is-future-date",
        "Exhibited date cannot be in the future",
        function (value) {
          const startDate = new Date(value);

          const currentDate = new Date();

          return !startDate || startDate <= currentDate;
        }
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isAdd === true) {
      reset({ title: "", from_organization: "" });
    } else {
      reset({
        title: currentModalData.title,
        from_organization: currentModalData.from_organization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  let exhibitedOnMonthName,
    exhibitedOnYear = null;

  if (
    !isAdd &&
    currentModalData &&
    Object.keys(currentModalData).length > 0 &&
    currentModalData?.exhibited_on &&
    currentModalData.isAchievement
  ) {
    [exhibitedOnMonthName, exhibitedOnYear] =
      currentModalData.exhibited_on.split(" ");
  }

  const getData = (type = "", editedFormData) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}my-profile/achievements/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          if (type === "edit") {
            const profiledataCopy = { ...profiledata };
            const editIndex = profiledata.achievements_and_accolades.findIndex(
              (item) => item.id === currentModalData.id
            );
            profiledataCopy.achievements_and_accolades[editIndex] =
              editedFormData;
            setProfileData(profiledataCopy);
          } else {
            setProfileData({
              ...profiledata,
              achievements_and_accolades:
                response.response.achievements_and_accolades,
            });
          }
          handleResetClose();
        } else {
        }
      });
    }
  };

  const handleEdit = (editData) => {
    const user_id = storageDetail?.user_id;
    let apiData = {
      ...editData,
      exhibited_on: moment(editData.exhibited_on).format("MMMM YYYY"),
    };
    if (user_id) {
      if (isAdd) {
        postMethod(
          `${process.env.REACT_APP_API_URL}my-profile/achievements/${user_id}/`,
          { achievements_and_accolades: apiData }
        ).then((response) => {
          if (response.status === "S") {
            // setIsLoading(false);
            reset();
            getData();
          } else {
            // setApiError(response.errorData.response.currentModalData.message);
            // setIsLoading(false);
          }
        });
      } else {
        apiData = {
          ...apiData,
          id: currentModalData.id,
        };
        patchTripdata(
          `${process.env.REACT_APP_API_URL}my-profile/edit-achievements/${user_id}/${currentModalData.id}/`,
          { updated_achievements_and_accolades: apiData }
        ).then((response) => {
          if (response.status === "S") {
            getData("edit", apiData);
          }
        });
      }
    }
  };

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  const handleDelete = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      deleteTrip(
        `${process.env.REACT_APP_API_URL}my-profile/delete-achievements/${user_id}/${currentModalData.id}/`
      ).then((response) => {
        if (response.status === "S") {
          reset();
          getData();
        }
      });
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap">
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                {isAdd ? "Add" : "Edit"} Achievements and Accolades
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleResetClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onFinish)}>
            <div className="form-wrap">
              <Grid container spacing={2} className="skill_role_side">
                <Grid item xs={12}>
                  <label htmlFor="title">Title</label>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue={currentModalData.title}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="custom_input"
                        placeholder="Nominated for a Webby Award for “Best Career Podcast” for #BuildingCharacters"
                        defaultValue={currentModalData.title}
                      />
                    )}
                  />
                  {errors.title && (
                    <p className="custom-modal-error">{errors.title.message}</p>
                  )}
                </Grid>

                <Grid item md={8} sm={7} xs={12}>
                  <label htmlFor="from">From</label>
                  <Controller
                    name="from_organization"
                    control={control}
                    defaultValue={currentModalData.from_organization}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="custom_input"
                        placeholder="The Webby Awards"
                        defaultValue={currentModalData.from_organization}
                      />
                    )}
                  />
                  {errors.from_organization && (
                    <p className="custom-modal-error">
                      {errors.from_organization.message}
                    </p>
                  )}
                </Grid>
                <Grid item md={4} sm={5} xs={12}>
                  <label htmlFor="exhibited_on">Exhibited on</label>
                  <Controller
                    name="exhibited_on"
                    control={control}
                    defaultValue={
                      !isAdd && exhibitedOnMonthName && exhibitedOnYear
                        ? dayjs(
                            exhibitedOnYear +
                              " " +
                              moment().month(exhibitedOnMonthName).format("MM"),
                            "YYYY MM"
                          )
                        : dayjs()
                    }
                    render={({ field }) => (
                      <MonthPicker
                        {...field}
                        format="MMMM-YYYY"
                        picker="month"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        defaultValue={
                          !isAdd && exhibitedOnMonthName && exhibitedOnYear
                            ? dayjs(
                                exhibitedOnYear +
                                  " " +
                                  moment()
                                    .month(exhibitedOnMonthName)
                                    .format("MM"),
                                "YYYY MM"
                              )
                            : dayjs()
                        }
                        className="date-fielde"
                      />
                    )}
                  />
                  {errors.exhibited_on && (
                    <p className="custom-modal-error">
                      {errors.exhibited_on.message}
                    </p>
                  )}
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                {!isAdd && (
                  <Button className="btn danger-btn" onClick={handleDelete}>
                    <img
                      src={DeleteIcon}
                      alt="delete icon"
                      className="delete-icon"
                    />
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button className="btn primary primary-btn" htmlType="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditAchievementsModal;
