import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { Icon } from "@iconify/react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as MailIcon } from "../../../assets/images/mail.svg";
import { ReactComponent as FolderExport } from "../../../assets/images/folder-export.svg";
import { Button, Input, Space, Switch, Tooltip } from "antd";
import SubscriptionCancelModal from "./editProfileModals/SubscriptionCancelModal";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../BeforeLogin/Context/loadingContext";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const Billing = () => {
  const { storageDetail } = useStorageContext();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toasting, setToasting] = useState(false);
  const [cancelSub, setCancelSub] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [autoRenewal, setAutoRenewal] = useState(false);
  const [recentPaymentData, setRecentPaymentData] = useState();
  const [comingSoonVisible, setComingSoonVisible] = useState(false);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoadingContext();

  const handleCommingSoon = () => {
    setComingSoonVisible(true);
  };
  const handleRemoveCommingSoon = () => {
    setComingSoonVisible(false);
  };

  const getCurrentPlan = () => {
    setIsLoading(true);
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/current-plan/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setCurrentPlan(response.response);
          setAutoRenewal(response?.response?.auto_renew || false);
          setIsLoading(false);
        } else {
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  };

  const getData = () => {
    getCurrentPlan();
    getRecentPayments();
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecentPayments = () => {
    setIsLoading(true);
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}/payments/transaction-history/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setRecentPaymentData(response?.response?.transaction_history || []);
          setIsLoading(false);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  };

  const cancelSubscription = () => {
    postMethod(
      `${process.env.REACT_APP_API_URL}payments/update-subscription/`,
      {
        subscription_id: currentPlan?.subscription_id,
        status: "canceled",
      }
    ).then((response) => {
      if (response.status === "S") {
        getData();
      }
    });
    setOpen(false);
  };

  const handleModalCancel = () => {
    setOpen(false);
    // reset the vaues if user cancels the confirmation modal
    setAutoRenewal(currentPlan?.auto_renew || false);
  };

  const disableAutoRenew = () => {
    setOpen(false);
    autoRenew(false);
  };

  function handleSwitch(value) {
    setAutoRenewal(value);
    if (!value) {
      setOpen(true);
      setCancelSub(false);
    } else {
      autoRenew(value);
    }
  }

  function handleCancelSub() {
    setCancelSub(true);
    setOpen(true);
  }

  const handleSubscribe = () => {
    localStorage.removeItem("payment");
    localStorage.setItem("subscribe", true);
    navigate("/dashboard");
  };

  const headColumns = [
    { label: "Date" },
    {
      label: "Inovice ID",
    },
    {
      label: "Service",
    },
    {
      label: "Invoice",
    },
  ];

  const autoRenew = (autoRenewBool) => {
    postMethod(
      `${process.env.REACT_APP_API_URL}payments/update-auto-renewal/`,
      {
        subscription_id: currentPlan?.subscription_id,
        auto_renew: autoRenewBool || autoRenewal,
      }
    ).then((response) => {
      if (response.status === "S") {
        getData();
      }
    });
  };

  const handleToasting = () => {
    setToasting(true);
    setTimeout(() => {
      setToasting(false);
    }, 3000);
  };

  const handleEmailInvoice = (invoice) => {
    const user_id = storageDetail?.user_id;
    postMethod(`${process.env.REACT_APP_API_URL}payments/email-invoice/`, {
      invoice_id: invoice?.invoice_id,
      user_id,
    }).then((response) => {
      if (response.status === "S") {
        handleToasting();
        setErrorMessage(response.response.message);
      } else {
        handleToasting();
        setErrorMessage(response.errorData.response.data.message);
      }
    });
  };

  const handleDownloadInvoice = (invoice) => {
    getMethod(
      `${process.env.REACT_APP_API_URL}payments/download-invoice/${invoice?.invoice_id}/`
    ).then((response) => {
      if (response.status === "S") {
        const link = document.createElement("a");
        link.href = `${response.response.invoice}`;
        link.target = "_blank";
        // Change the downloaded file name if needed
        // link.download = 'document.pdf';
        link.click();
      } else {
        handleToasting();
        setErrorMessage(response.errorData.response.data.message);
      }
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div>
            <div className="card-outer-wrap">
              <div className="peferral-warpper pro-card">
                <div className="card-header">
                  <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item>
                      <h4 className="card-xl-title font-bold mb-0">
                        {currentPlan?.plan_name}
                      </h4>
                    </Grid>
                    <Grid item>
                      {currentPlan.subscription_status === "active" && (
                        <Space align="center">
                          <p className="regular-title m-0">Auto-Renew</p>
                          <Switch
                            defaultChecked={autoRenewal}
                            checked={autoRenewal}
                            onChange={handleSwitch}
                            className="default-switch-btn"
                          />
                        </Space>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={2}
                  justifyContent={"space-between"}
                >
                  <Grid item lg={8} xs={12}>
                    <p className="included-list-title">What’s included</p>
                    <ul className="option-list-group included-items">
                      {currentPlan?.features?.existing.map(
                        (existing_feature) => {
                          return (
                            <li className="list-item">
                              <Icon icon="iconamoon:check" />
                              {existing_feature}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <p className="included-list-title">Coming soon</p>
                    <ul className="option-list-group">
                      {currentPlan?.features?.coming_soon.map(
                        (comming_feature) => {
                          return (
                            <li className="list-item">
                              <Icon icon="iconamoon:check" />
                              {comming_feature}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <Space className="d-flex align-items-baseline">
                          <h2 className="price-title mb-0">
                            {currentPlan?.currency_symbol}
                            {currentPlan?.paid_amount}
                          </h2>
                          <div className="annually-amount">
                            <p>/Annually</p>
                          </div>
                        </Space>
                      </Grid>
                      <Grid item>
                        {currentPlan.subscription_status === "active" && (
                          <Button
                            className="small-btn"
                            onClick={handleCancelSub}
                          >
                            Cancel Subscription
                          </Button>
                        )}
                        {currentPlan.subscription_status === "canceled" && (
                          <Button
                            className="small-btn primary-btn"
                            onClick={handleSubscribe}
                          >
                            Subscribe
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="card-outer-wrap">
              <div
                className="peferral-warpper"
                onMouseEnter={handleCommingSoon}
                onMouseLeave={handleRemoveCommingSoon}
              >
                {comingSoonVisible && (
                  <div className="comming-soon-nudge comming-soon-referral">
                    Coming Soon
                  </div>
                )}
                <Grid container rowSpacing={3} columnSpacing={2}>
                  <Grid item lg={6} md={12} sm={6} xs={12}>
                    <p className="wrapTitle mb-0">Referral Program</p>
                    <p className="card-description">
                      Invite friends to join and earn credits! Track your
                      referrals and redeem credits for mentoring or coaching
                      sessions. Share, save, and learn together.
                    </p>
                  </Grid>
                  <Grid item lg={6} md={12} sm={6} xs={12}>
                    <div className="greybg-card">
                      <Space
                        align="start"
                        className="justify-content-between w-100"
                      >
                        <div>
                          <p className="regular-title mb-3 mt-0">Credits</p>
                          <h2 className="price-title mb-0">$15.00</h2>
                        </div>
                        <p className="regular-title mt-0">3 Claims</p>
                      </Space>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="skill_role_side">
                    <div className="link-input">
                      <Icon icon="ph:link" className="link-icon" />
                      <Input
                        className="custom_input link-input-field"
                        placeholder="https://youe.com/AS2ddaDFDS2"
                      />
                      <Button className="small-btn primary-btn">
                        Refer now
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="card-outer-wrap">
            <div className="card-warpper">
              <p className="wrapTitle">
                Recent Payments
                {errorMessage && toasting && (
                  <div className="common-toaster">
                    <Icon icon="gg:info" className="toaster-icon" />
                    {errorMessage}
                  </div>
                )}
              </p>
              <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="common-table-wrapper"
              >
                <TableContainer sx={{ maxHeight: 480 }}>
                  <Table
                    stickyHeader
                    border="0"
                    aria-label="sticky table"
                    className="common-table"
                  >
                    <TableHead>
                      <TableRow>
                        {headColumns.map((column, index) => (
                          <TableCell key={column.index}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentPaymentData &&
                        recentPaymentData.length > -1 &&
                        recentPaymentData.map((cel) => (
                          <TableRow>
                            <TableCell>{cel?.payment_date}</TableCell>
                            <TableCell>{cel?.invoice_number || "-"}</TableCell>
                            <TableCell>{cel?.service}</TableCell>
                            <TableCell>
                              <Tooltip
                                className="tooltip-area"
                                // placement="top"
                                rootClassName="tooltip-wrapper"
                                title="Email Invoice"
                              >
                                <span
                                  className="invoice-icon invoice-email-icon"
                                  onClick={() => handleEmailInvoice(cel)}
                                >
                                  <MailIcon />
                                </span>
                              </Tooltip>

                              <Tooltip
                                className="tooltip-area"
                                // placement="top"
                                rootClassName="tooltip-wrapper"
                                title="Download Invoice"
                              >
                                <span
                                  className="invoice-icon"
                                  onClick={() => handleDownloadInvoice(cel)}
                                >
                                  <FolderExport />
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      <SubscriptionCancelModal
        open={open}
        setOpen={setOpen}
        cancelSub={cancelSub}
        handleConfirmYes={cancelSub ? cancelSubscription : disableAutoRenew}
        disableAutoRenew={disableAutoRenew}
        cancelSubscription={cancelSubscription}
        handleCancel={handleModalCancel}
        currentPlan={currentPlan.plan_name}
      />
    </div>
  );
};

export default Billing;
