import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import "../CSS/loader.css";

const OnbordingLoader = ({ progress, responseError }) => {
  return (
    <div className="loader-bg">
      <div className="loader-wrap">
        <Box className="loader-item">
          <CircularProgress variant="determinate" value={progress} size={100} />
          <Typography variant="caption" component="p">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
        {responseError && progress === 90 && (
          <p className="personalised-error">{responseError}</p>
        )}
        <p className="personalised-title">
          Setting up your account and crafting a personalised roadmap
        </p>
      </div>
    </div>
  );
};

export default OnbordingLoader;
