import React, { useEffect } from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, DatePicker, Input } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "../../../../assets/images/delete.png";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import "../../../CSS/Welcome.css";
import "./modals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { MonthPicker } = DatePicker;

const EditProfessionalModal = ({
  data,
  setProfileData,
  profiledata,
  isAdd,
  currentModalData,
  setCurrentModalData,
  isOpen,
  handleModalOpenClose,
}) => {
  const { storageDetail } = useStorageContext();
  const validationSchema = yup.object().shape({
    association_name: yup
      .string()
      .required("Association is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Association must contain at least one alphabet character"
      )
      .min(1, "Association must contain at least one character")
      .max(100, "Maximum 100 characters allowed"),
    description: yup
      .string()
      .required("Association is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "Association must contain at least one alphabet character"
      )
      .min(1, "Association must contain at least one character")
      .max(150, "Maximum 150 characters allowed"),
    start_date: yup
      .date()
      .required("Start Date is required")
      .test(
        "is-future-date",
        "Start date cannot be in the future",
        function (value) {
          const startDate = new Date(value);

          const currentDate = new Date();

          return !startDate || startDate <= currentDate;
        }
      ),
    end_date: yup
      .date()
      .nullable()
      .when("start_date", (start_date, schema) =>
        schema.test(
          "is-after-start-date",
          "End date must be after the start date",
          function (end_date) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);
            return !endDate || endDate >= startDate;
          }
        )
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    // register, setValue, trigger,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isAdd === true) {
      reset({ association_name: "", description: "" });
    } else {
      reset({
        association_name: currentModalData.association_name,
        description: currentModalData.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleResetClose = () => {
    reset();
    setCurrentModalData({});
    handleModalOpenClose(false);
  };

  const getData = (type, editedFormData) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}my-profile/professional-association/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          if (type === "edit") {
            const profiledataCopy = { ...profiledata };
            const editIndex = profiledata.professional_association.findIndex(
              (item) => item.id === currentModalData.id
            );
            profiledataCopy.professional_association[editIndex] =
              editedFormData;
            setProfileData(profiledataCopy);
          } else {
            setProfileData({
              ...profiledata,
              professional_association:
                response.response.professional_association,
            });
          }
          handleResetClose();
        } else {
        }
      });
    }
  };

  const handleDelete = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      deleteTrip(
        `${process.env.REACT_APP_API_URL}my-profile/delete-professional-association/${user_id}/${currentModalData.id}/`
      ).then((response) => {
        if (response.status === "S") {
          reset();
          getData();
        }
      });
    }
  };

  const handleEdit = (editData) => {
    const user_id = storageDetail?.user_id;
    let apiData = {
      ...editData,
      start_date: moment(editData.start_date).format("MMMM YYYY"),
      end_date: moment(editData.end_date).format("MMMM YYYY"),
    };
    if (user_id) {
      if (isAdd) {
        postMethod(
          `${process.env.REACT_APP_API_URL}my-profile/professional-association/${user_id}/`,
          { professional_association: apiData }
        ).then((response) => {
          if (response.status === "S") {
            // setIsLoading(false);
            reset();
            getData();
          } else {
            // setApiError(response.errorData.response.currentModalData.message);
            // setIsLoading(false);
          }
        });
      } else {
        apiData = {
          ...apiData,
          id: currentModalData.id,
        };
        patchTripdata(
          `${process.env.REACT_APP_API_URL}my-profile/edit-professional-association/${user_id}/${currentModalData.id}/`,
          { updated_professional_association: apiData }
        ).then((response) => {
          if (response.status === "S") {
            getData("edit", apiData);
          }
        });
      }
    }
  };

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  let startDateMonthName,
    startDateYear,
    endDateMonthName,
    endDateYear = null;

  if (
    !isAdd &&
    currentModalData &&
    Object.keys(currentModalData).length &&
    currentModalData?.start_date &&
    currentModalData.isProfessional
  ) {
    [startDateMonthName, startDateYear] =
      currentModalData?.start_date.split(" ");
    [endDateMonthName, endDateYear] = currentModalData?.end_date.split(" ");
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h6" component="h2" className="modal-title">
                  {isAdd ? "Add" : "Edit"} Professional Associations
                </Typography>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleResetClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item xs={12}>
                    <label htmlFor="Association">Association</label>
                    <Controller
                      name="association_name"
                      defaultValue={currentModalData?.association_name}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="Association Name"
                          defaultValue={currentModalData?.association_name}
                        />
                      )}
                    />
                    {errors?.association_name && (
                      <p className="custom-modal-error">
                        {errors?.association_name.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="Description">Description</label>
                    <Controller
                      name="description"
                      defaultValue={currentModalData?.description}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="Description"
                          defaultValue={currentModalData?.description}
                        />
                      )}
                    />
                    {errors?.description && (
                      <p className="custom-modal-error">
                        {errors?.description.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="start_date">Start Date</label>
                    <Controller
                      name="start_date"
                      control={control}
                      defaultValue={
                        !isAdd && startDateMonthName && startDateYear
                          ? dayjs(
                              startDateYear +
                                " " +
                                moment().month(startDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && startDateMonthName && startDateYear
                              ? dayjs(
                                  startDateYear +
                                    " " +
                                    moment()
                                      .month(startDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors?.start_date && (
                      <p className="custom-modal-error">
                        {errors?.start_date.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="end_date">End Date</label>
                    <Controller
                      name="end_date"
                      control={control}
                      defaultValue={
                        !isAdd && endDateMonthName && endDateYear
                          ? dayjs(
                              endDateYear +
                                " " +
                                moment().month(endDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && endDateMonthName && endDateYear
                              ? dayjs(
                                  endDateYear +
                                    " " +
                                    moment()
                                      .month(endDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors?.end_date && (
                      <p className="custom-modal-error">
                        {errors?.end_date.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {!isAdd && (
                    <Button className="btn danger-btn" onClick={handleDelete}>
                      <img
                        src={DeleteIcon}
                        alt="delete icon"
                        className="delete-icon"
                      />
                      Delete
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button className="btn primary primary-btn" htmlType="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default EditProfessionalModal;
