import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStepContext } from "../BeforeLogin/Context/activeStepContext";
import { getMethod } from "../Api'sCall/Apicalling";

const LinkedinBtn = () => {
  const { step } = useStepContext();
  const { pathname } = useLocation();
  const [authUrl, setAuthUrl] = useState("");
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const handleCommingSoon = () => {
    setComingSoonVisible(true);
  };
  const handleRemoveCommingSoon = () => {
    setComingSoonVisible(false);
  };

  useEffect(() => {
    let type = "";
    if (pathname === "/email-signup") {
      type = "signup";
    } else {
      type = "signin";
    }
    if (type) {
      getMethod(
        `${process.env.REACT_APP_API_URL}linkedin-auth-url/?type=${type}`
      ).then((response) => {
        if (response.status === "S") {
          setAuthUrl(response.response.authorization_url);
        } else {
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkedin = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };
  return (
    <button
      className="signUp_linkedin_btn"
      type="button"
      onClick={
        !(step === 6 && pathname === "/email-signup")
          ? handleLinkedin
          : undefined
      }
      onMouseEnter={handleCommingSoon}
      onMouseLeave={handleRemoveCommingSoon}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="linkedin_icon"
      >
        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
      </svg>
      {step === 6 && pathname === "/email-signup" && comingSoonVisible && (
        <div className="comming-soon-nudge comming-soon-career">
          Coming Soon
        </div>
      )}
      {step === 1 && pathname === "/email-signup"
        ? "Sign up with LinkedIn"
        : step === 6 && pathname === "/email-signup"
        ? "Connect your LinkedIn account"
        : "Sign in with LinkedIn"}
    </button>
  );
};

export default LinkedinBtn;
