import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { useStepContext } from "./Context/activeStepContext";
import { useStorageContext } from "./Context/storageContext";

const OTPDesign = ({ email, isFrom }) => {
  const navigate = useNavigate();
  const { storageDetail } = useStorageContext();
  const { setStorageDetail } = useStorageContext();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [toasting, setToasting] = useState(false);
  const [apiError, setApiError] = useState();
  const [activeOTP, setActiveOTP] = useState(0);
  const [timer, setTimer] = useState(0);
  const { setStep } = useStepContext();

  let currentOTPIndex = 0;
  const inputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const { control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const resendHandler = () => {
    setOtp(new Array(6).fill(""));
    setApiError("");
    if (timer === 0) {
      if (isFrom === "login2FA") {
        postMethod(`${process.env.REACT_APP_API_URL}login/2fa/resend-otp/`, {
          email: email,
        }).then((response) => {
          if (response.status === "S") {
            setToasting(true);
            setTimeout(() => {
              setToasting(false);
            }, 2000);
          }
        });
      } else {
        postMethod(
          `${process.env.REACT_APP_API_URL}onboarding/email-verification/resend-otp/`,
          { email: email }
        ).then((response) => {
          if (response.status === "S") {
            setToasting(true);
            setTimeout(() => {
              setToasting(false);
            }, 2000);
          }
        });
      }
      setTimer(10);
    }
  };
  const handleChange = (e, index) => {
    setApiError();
    if (index !== currentOTPIndex) {
      currentOTPIndex = index;
    }
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) setActiveOTP(currentOTPIndex - 1);
    else setActiveOTP(currentOTPIndex + 1);
    setOtp(newOtp);
    if (newOtp.join("").length === 6) {
      otpSending(newOtp.join(""));
    }
  };

  const getCurrentPlan = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/current-plan/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          localStorage.setItem("payment", true);
        } else {
          localStorage.setItem("payment", false);
        }
      });
    } else {
    }
  };

  const otpSending = (OTP) => {
    if (isFrom && isFrom === "login2FA") {
      postMethod(`${process.env.REACT_APP_API_URL}login/2fa/verify-otp/`, {
        otp: OTP,
        email,
      }).then((response) => {
        if (response.status === "S") {
          if (response.response.status === "fail") {
            setApiError(response.response.message);
          } else {
            setStorageDetail({ user_id: response.response.user_id });
            // sessionStorage.setItem("user_id", response.response.user_id);
            getCurrentPlan();
            navigate("/dashboard");
            setApiError(response?.response?.message);
          }
        } else {
          let errorMsg = response.errorData.response.data;
          if (errorMsg?.message) {
            setApiError(response.errorData.response.data.message);
          } else {
            setApiError(response.errorData.response.data.status);
          }
        }
      });
    } else {
      postMethod(
        `${process.env.REACT_APP_API_URL}onboarding/email-verification/verify-otp/`,
        {
          otp: OTP,
          email,
        }
      ).then((response) => {
        if (response.status === "S") {
          if (response.response.status === "fail") {
            setApiError(response.response.message);
          } else {
            setStep((prevStep) => prevStep + 1);
            setApiError(response?.response?.message);
          }
        } else {
          let errorMsg = response.errorData.response.data;
          if (errorMsg?.message) {
            setApiError(response.errorData.response.data.message);
          } else {
            setApiError(response.errorData.response.data.status);
          }
        }
      });
    }
  };

  const handleOnKeyDown = ({ key, target, preventDefault }, index) => {
    currentOTPIndex = index;
    if (key === "Tab" || key === "ArrowRight") {
      setActiveOTP(currentOTPIndex + 1);
    }
    if ((key === "Backspace" && !target.value) || key === "ArrowLeft") {
      setActiveOTP(currentOTPIndex - 1);
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [activeOTP]);

  return (
    <div className="login-con">
      <form className="mw-100">
        <div className="otp_section">
          {toasting && (
            <div className="otp-toast">
              Email verification code resent. <br />
              Please check your inbox and follow the instructions.
            </div>
          )}
          {apiError && <div className="api-error-otp">{apiError}</div>}
          <div className="otp-sub-text">
            <div className="Email-Verification">
              <h2>Email Verification</h2>
            </div>
          </div>
          <div className="otp-subText">
            <div className="send_code_text">
              <p>
                We've sent a 6-character code to{" "}
                <span className="text-style-1-email">{email}</span>
                .
                <br />
                This code is time-sensitive, so please input it promptly.
              </p>
            </div>
          </div>

          <div className="otp_block">
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <div className="otp-container">
                  {otp.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <input
                          className={
                            apiError ? "otp-field-error otp-field" : "otp-field"
                          }
                          type="text"
                          id={`elem${index + 1}`}
                          maxLength="1"
                          onKeyDown={(e) => {
                            handleOnKeyDown(e, index);
                          }}
                          ref={index === activeOTP ? inputRef : null}
                          value={otp[index]}
                          onChange={(e) => handleChange(e, index)}
                          onFocus={(e) => e.target.select()}
                        />
                        {index === 2 && (
                          <div className="hypen-con" id="hypen-icon">
                            <Icon icon="material-symbols:remove" />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            />

            <span className="Didnt-receive-code">
              Didn't receive code?
              <span
                className={`text-style-1 ${timer > 0 ? "resend-disabled" : ""}`}
                onClick={() => {
                  resendHandler();
                }}
              >
                Resend
              </span>
            </span>
          </div>
          {timer > 0 && (
            <span className="resend-timer"> Resend in {timer} seconds</span>
          )}
        </div>
      </form>
    </div>
  );
};

export default OTPDesign;
