import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/images/youe_logo_black.svg";
import "../../CSS/pricingNew.css";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const PricingModal = ({ setPayment, checkSubscription }) => {
  const [open, setOpen] = useState(true);
  const { storageDetail } = useStorageContext();
  const [planData, setPlanData] = useState({});
  const [planid, setPlanid] = useState(null);
  // const [currentPlan, setCurrentPlan] = useState({});
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/plan-details/?id=${user_id}`
      ).then((response) => {
        if (response.status === "S") {
          setPlanData(response.response);
          setPlanid(response.response.plan_id);
        } else {
        }
      });
    }
    getCurrentPlan();
  }, []);

  const getCurrentPlan = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}payments/current-plan/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          // setCurrentPlan(response.response);
          const currPlan = response.response.subscription_status;
          if (currPlan === "active") {
            localStorage.setItem("payment", true);
          }
          // else {
          //   localStorage.setItem("payment", false);
          // }
        } else {
        }
      });
    } else {
    }
  };

  const subscribeHandler = () => {
    if (planid) {
      postMethod(
        `${process.env.REACT_APP_API_URL}payments/create-checkout-session/`,
        {
          user_id: storageDetail?.user_id,
          plan_id: planid,
        }
      ).then((response) => {
        if (response.status === "S") {
          const stripeCheckoutLink = response.response.payment_link;
          window.location.href = stripeCheckoutLink;
        } else {
          checkSubscription(response?.errorData?.response?.data?.message);
          setPayment(true);
        }
      });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
        disableEscapeKeyDown={true}
      >
        <div className="modal-overflow pricing-overflow">
          <Box className="modal-wrap pricing-modal">
            <Grid
              container
              justifyContent={"center"}
              spacing={2}
              className="mb-3"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  component="h2"
                  className="pricing-modal-title"
                >
                  Welcome to your <Logo className="welcome-youe-logo" /> canvas!
                </Typography>
              </Grid>
            </Grid>
            <div className="modal-inner-overflow-outer">
              <div className="modal-inner-overflow">
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                    <div className="videoWrap">
                      <Icon
                        icon="icon-park-outline:play"
                        className="video-play-btn"
                      />
                    </div>
                  </Grid> */}
                  <Grid item xs={12}>
                    <div className="pricing-pro-card">
                      <div className="card-header">
                        <Grid
                          container
                          spacing={2}
                          justifyContent={"space-between"}
                        >
                          <Grid item>
                            <h4 className="price-pro-title">
                              {planData?.plan}
                            </h4>
                          </Grid>
                          <Grid item>
                            {localStorage.getItem("subscribe") !== "false" && (
                              <span className="introductory-discount">
                                {planData?.currency_symbol}
                                {planData?.discount} off introductory discount
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={2}
                        justifyContent={"space-between"}
                      >
                        {planData?.features?.existing.length > 0 && (
                          <Grid item lg={6} xs={12}>
                            <p className="included-list-title">
                              What’s included
                            </p>
                            <ul className="option-list-group">
                              {planData?.features?.existing?.map((currPlan) => (
                                <li className="list-item">
                                  <Icon icon="iconamoon:check" />
                                  {currPlan}
                                </li>
                              ))}
                            </ul>
                          </Grid>
                        )}

                        {planData?.features?.coming_soon.length > 0 && (
                          <Grid item lg={6} xs={12}>
                            <p className="included-list-title">Coming soon</p>
                            <ul className="option-list-group">
                              {planData?.features?.coming_soon?.map(
                                (currPlan) => (
                                  <li className="list-item">
                                    <Icon icon="iconamoon:check" />
                                    {currPlan}
                                  </li>
                                )
                              )}
                            </ul>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <Space align="center">
                            <h2 className="amount-item">
                              {planData?.currency_symbol}
                              {localStorage.getItem("subscribe") === "false"
                                ? planData?.actual_annual_price
                                : planData?.final_annual_price}
                              {/* {planData?.final_annual_price} */}
                            </h2>
                            <div className="annually-amount">
                              {localStorage.getItem("subscribe") !==
                                "false" && (
                                <span>
                                  {planData?.currency_symbol}
                                  {planData?.actual_annual_price}
                                </span>
                              )}

                              <p>/Annually</p>
                            </div>
                          </Space>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <button
              type="button"
              className="btn black-btn"
              onClick={subscribeHandler}
            >
              Subscribe to Continue{" "}
              <Icon className="subscribe-arrow" icon="mingcute:right-line" />
            </button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default PricingModal;
