import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { ReactComponent as BankCard } from "../../../assets/images/bank-card-black.svg";
import { ReactComponent as ChatDots } from "../../../assets/images/chat-dots.svg";
import { useActiveProfile } from "../Context/activeProfileHeader";

const NavItems = [
  { name: "My Profile", navIcon: "iconamoon:profile" },
  { name: "Account Settings", navIcon: "tabler:settings" },
  { name: "Billing", navIcon: <BankCard /> },
  { name: "Support", navIcon: <ChatDots /> },
];

const ProfileNav = () => {
  const { setActiveNav, activeNav } = useActiveProfile();
  const handleNavItemClick = (name) => {
    setActiveNav(name);
  };
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const handleCommingSoon = () => {
    setComingSoonVisible(true);
  };
  const handleRemoveCommingSoon = () => {
    setComingSoonVisible(false);
  };

  return (
    <div className="profilepage-settings">
      <Grid className="d-flex justify-content-evenly flex-wrap gap-2">
        {NavItems.map((item) => (
          <Grid
            key={item.name}
            onClick={
              item.name === "Support"
                ? undefined
                : () => handleNavItemClick(item.name)
            }
            className={`tabNav ${activeNav === item.name && "active"}`}
          >
            <span
              className={`profilepage-My-Profile ${item.name.toLowerCase()}`}
              onMouseEnter={
                item.name === "Support" ? handleCommingSoon : undefined
              }
              onMouseLeave={
                item.name === "Support" ? handleRemoveCommingSoon : undefined
              }
            >
              {comingSoonVisible && item.name === "Support" && (
                <div className="comming-soon-nudge">Coming Soon</div>
              )}
              {item.name === "Billing" || item.name === "Support" ? (
                item.navIcon
              ) : (
                <Icon icon={item.navIcon} className="tabIcon" />
              )}

              {item.name}
            </span>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProfileNav;
