import React from "react";
import { Grid } from "@mui/material";
import CareerPathImg from "../../../assets/images/career-pathway.png";
import { useActiveTab } from "../../Dashboard/Context/activeTab";

const ClarifyCareer = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          Only Youe lets you create ambitious goals, set milestones, create
          actions, identify critical enablers and track your progress. With
          compelling insights revealed – and a holistic vision of your career
          pathways – you’ll be inspired and motivated to stay focused.
        </p>
        <button className="join-btn">Get Started</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img src={CareerPathImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default ClarifyCareer;
