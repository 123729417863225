import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { postMethod } from "../Api'sCall/Apicalling";
import { useEmailContext } from "./Context/loginContext";

const ResetPassword = () => {
  const Location = useLocation();
  const isResetPasswordPage = Location.pathname === "/reset-password";
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toasting, setToasting] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setToasting(false);
    }, 2000);
  }, [toasting]);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { passwordChangeEmail, setPasswordChangeEmail } = useEmailContext();

  const onSubmit = (data) => {
    setPasswordChangeEmail(data?.email);
    postMethod(
      `${process.env.REACT_APP_API_URL}password-reset/request-new-password/`,
      data
    ).then((response) => {
      if (response.status === "S") {
        setErrorMessage(`Your password
              has been successfully sent to your email address. Please check
              your inbox for further instructions.`);
        setToasting(true);
      } else {
        setErrorMessage(response.errorData.response.data.message);
        setToasting(true);
      }
    });
  };

  const handleResend = () => {
    if (errorMessage) {
      if (timer === 0) {
        postMethod(
          `${process.env.REACT_APP_API_URL}password-reset/resend-email/`,
          {
            email: passwordChangeEmail?.toLowerCase(),
          }
        ).then((response) => {
          if (response.status === "S") {
            setErrorMessage(response.response.message);
            setToasting(true);
          } else {
            setErrorMessage(response.errorData.response.data.message);
            setToasting(true);
          }
        });
        setTimer(10);
      }
    } else {
      setErrorMessage("Firstly you need to request for password");
    }
  };

  return (
    <div className="reset-con login-con">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {errorMessage && toasting && (
            <div className="common-toaster">
              <Icon icon="gg:info" className="toaster-icon" />
              {errorMessage}
            </div>
          )}
          <div className="rp-con">
            <h3 style={{ textAlign: "center" }}>
              {isResetPasswordPage
                ? "Reset your password"
                : "Creating a New Password"}
            </h3>
            <div className="Welcome-field">
              {isResetPasswordPage ? (
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <TextField
                      id="standard-start-adornment"
                      className="welcome-field reset-pass-field"
                      onChange={onChange}
                      error={error}
                      helperText={error?.message}
                      placeholder="Enter your email address"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="iconoir:mail" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <TextField
                      id="standard-start-adornment"
                      className="welcome-field reset-pass-field"
                      type={showPassword ? "text" : "password"}
                      onChange={onChange}
                      error={error}
                      helperText={error?.message}
                      placeholder="Enter your password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="majesticons:lock-line" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              icon={
                                showPassword
                                  ? "ph:eye-light"
                                  : "octicon:eye-closed-24"
                              }
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
              <button type="submit" className="reset-btn primary_btn">
                {isResetPasswordPage ? "Continue" : "Change password"}
              </button>
            </div>
            <div className="Welocme-line-login"></div>
            {isResetPasswordPage && (
              <>
                <div className="Welcome-signin">
                  Didn't receive email?
                  <span
                    onClick={handleResend}
                    className={`resend ${timer > 0 ? "resend-disabled" : ""}`}
                  >
                    Resend
                  </span>
                </div>
                {timer > 0 && (
                  <span className="resend-timer">
                    {" "}
                    Resend in {timer} seconds
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      {/* <div className="copyright">© 2023 Nudge2Edge Pty Ltd</div> */}
    </div>
  );
};

export default ResetPassword;
