import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg";
import EditMilestoneActionsModal from "./nodemapModals/EditMilestoneActionsModal";
import EditCriticalFactorsModal from "./nodemapModals/EditCriticalFactorsModal";

const MilestoneCard = ({
  nodemapData,
  nodemapDataId,
  getNodemapDataList,
  index,
  nodemapLength,
  haveMilestoneNumber,
  haveIntermediateNumber,
}) => {
  const [isEditMilestoneActionsModalOpen, setIsEditMilestoneActionsModalOpen] =
    useState(false);
  const [isEditCriticalFactorsModalOpen, setIsEditCriticalFactorsModalOpen] =
    useState(false);

  const handleOpenModal = (key) => {
    switch (key) {
      case "action":
        setIsEditMilestoneActionsModalOpen(true);
        break;
      case "critical":
        setIsEditCriticalFactorsModalOpen(true);
        break;
      default:
        break;
    }
  };

  const getActionColor = (progress) => {
    if (progress === 0) {
      return ["low-rating", ""];
    } else if (progress === 1) {
      return ["medium-rating", "a1"];
    } else if (progress === 2) {
      return ["high-rating", "a2"];
    } else if (progress === 3) {
      return ["high-rating", "a3"];
    } else if (progress === 4) {
      return ["high-rating", "a4"];
    } else {
      return ["", ""];
    }
  };

  const getCriticalColor = (progress) => {
    if (progress < 25) {
      return "low-progress";
    } else if (progress < 75) {
      return "medium-progress";
    } else {
      return "high-progress";
    }
  };

  const stepFill = (progress) => {
    const steps = Array.from({ length: 3 }, (_, index) => (
      <span
        key={index}
        className={`step-progress ${index <= progress ? "step-fill" : ""}`}
      ></span>
    ));
    return steps;
  };
  return (
    <>
      <EditMilestoneActionsModal
        isOpen={isEditMilestoneActionsModalOpen}
        handleModalOpenClose={setIsEditMilestoneActionsModalOpen}
        nodemapData={nodemapData}
        nodemapDataId={nodemapDataId}
        getNodemapDataList={getNodemapDataList}
        getActionColor={getActionColor}
        index={index}
        haveMilestoneNumber={haveMilestoneNumber}
        haveIntermediateNumber={haveIntermediateNumber}
      />

      <EditCriticalFactorsModal
        isOpen={isEditCriticalFactorsModalOpen}
        handleModalOpenClose={setIsEditCriticalFactorsModalOpen}
        nodemapData={nodemapData}
        nodemapDataId={nodemapDataId}
        getNodemapDataList={getNodemapDataList}
        index={index}
        haveMilestoneNumber={haveMilestoneNumber}
        haveIntermediateNumber={haveIntermediateNumber}
      />

      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item lg={nodemapLength === 2 ? 12 : 6} xs={12}>
          <div className="milestone-outer-card">
            <div className="milestone-card">
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  <Button className="action-btn">
                    Actions <Icon icon="icon-park-outline:right-small-up" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className="action-btn action-adit-btn"
                    onClick={() => handleOpenModal("action")}
                    disabled={nodemapData?.progress === 100 ? true : false}
                  >
                    <Edit />
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <div className="milestone-innner-wrap">
                {nodemapData.actions.map((action) => (
                  <div className="rating-wrap">
                    <div
                      className={`action-item-wrap ${
                        getActionColor(action?.action_id)[1]
                      }`}
                    >
                      <span>A{action?.action_id}</span>
                      <Tooltip
                        placement="top"
                        rootClassName="intermediate-tooltip"
                        title={action.title}
                      >
                        <p className="action-item-title">{action.title}</p>
                      </Tooltip>
                    </div>

                    <div
                      className={`step-progress-bar ${
                        getActionColor(action?.status)[0]
                      }`}
                    >
                      {stepFill(action?.status)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={nodemapLength === 2 ? 12 : 6} xs={12}>
          <div className="milestone-outer-card">
            <div className="milestone-card">
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  <Button className="action-btn">
                    Critical Enablers
                    <Icon icon="icon-park-outline:right-small-up" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className="action-btn action-adit-btn"
                    onClick={() => handleOpenModal("critical")}
                    disabled={nodemapData?.progress === 100 ? true : false}
                  >
                    <Edit />
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <div className="milestone-innner-wrap">
                <Grid
                  container
                  spacing={2}
                  justifyContent={{ lg: "space-evenly", xs: "space-evenly" }}
                >
                  {nodemapData.enablers &&
                    nodemapData.enablers.map((item) => (
                      <Grid item className="text-center">
                        <Box
                          sx={{
                            position: "relative",
                            display: "inline-flex",
                          }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={`${Math.round(item?.progress)}`}
                            className={`progress-circle ${getCriticalColor(
                              item?.progress
                            )}`}
                          />
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            className="progress-count"
                          >
                            {`${Math.round(item?.progress)}`}
                          </Typography>
                        </Box>
                        <p className="progress-title">{item.title}</p>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default MilestoneCard;
