import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import GrowKnowledgeImg from "../../../assets/images/grow-knowledge.png";

import { Grid } from "@mui/material";

const GrowKnowledge = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          Join in and expand your career planning know-how through our knowledge
          space. Our Learning Center offers a growing list of on-demand courses
          and short videos designed to give you just what you need when you need
          it. Access online courses, live workshops and webinars and AMAs with
          guest experts to keep you motivated.
        </p>
        <button className="join-btn">Explore content</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img src={GrowKnowledgeImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default GrowKnowledge;
