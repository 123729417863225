import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Space } from "antd";

const ActionConfirmationPopup = ({ isOpen, setIsOpen, title, onConfirm }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg confirmation-popup"
      >
        <Box className="modal-wrap">
          {title || `Are you sure you want to delete your account?`}

          <Grid
            container
            columnSpacing={2}
            rowSpacing={0}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Space size={15}>
                <Button
                  className="small-btn primary-btn conf-btn"
                  onClick={() => {
                    onConfirm();
                    handleClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="small-btn text-color-grey conf-btn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  No
                </Button>
              </Space>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ActionConfirmationPopup;
