import React from "react";
import { Icon } from "@iconify/react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Space, Typography } from "antd";
import { patchTripdata, postMethod } from "../../../Api'sCall/Apicalling";
import { useLoadingContext } from "../../../BeforeLogin/Context/loadingContext";

const ConfirmActionAlignmentModal = ({
  isOpen,
  isAddAction,
  notAllignedTitleArray,
  setNotAllignedTitleArray,
  allActionsData,
  nodemapDataId,
  getNodemapDataList,
  setIsAddAction,
  tempAddActionDataValues,
  tempEditActionDataValues,
  setIsConfirmActionAlignmentModalOpen,
  handleClosePopup,
  type,
}) => {
  const { setIsLoading } = useLoadingContext();
  const handleClose = (isMannualyClose) => {
    if (isMannualyClose) {
      setNotAllignedTitleArray([]);
      setIsConfirmActionAlignmentModalOpen(false);
    } else {
      setNotAllignedTitleArray([]);
      setIsConfirmActionAlignmentModalOpen(false);
      handleClosePopup();
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    if (isAddAction) {
      postMethod(`${process.env.REACT_APP_API_URL}nodemap/add-action/`, {
        id: nodemapDataId || "",
        action_data: tempAddActionDataValues,
      })
        .then((response) => {
          if (response.status === "S") {
            getNodemapDataList();
            setIsAddAction(false);
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    } else {
      patchTripdata(
        `${process.env.REACT_APP_API_URL}nodemap/edit-action/${
          nodemapDataId || ""
        }/`,
        {
          action_data: tempEditActionDataValues,
        }
      )
        .then((response) => {
          if (response.status === "S") {
            getNodemapDataList();
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => handleClose(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg addmilestone-modal"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <div className="collapse-item">
                  <p>Confirm Alignment</p>
                </div>
              </Grid>
              <Grid item>
                <Button
                  className="modal-close-btn"
                  onClick={() => handleClose(true)}
                >
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ margin: "10px 0 10px" }}>
              <Typography>
                The below{" "}
                {notAllignedTitleArray?.length > 1 ? "title’s are" : "title is"}{" "}
                not aligned with your{" "}
                {type === "intermediate_role"
                  ? "intermediate role"
                  : type === "milestone"
                  ? "milestone"
                  : "northstar"}
                . Do you still want to save it ?
              </Typography>
            </Grid>
            <Grid container style={{ margin: "10px 0 40px" }}>
              {notAllignedTitleArray?.length > 0 &&
                notAllignedTitleArray?.map((item, index) => (
                  <Grid item xs={12}>
                    <Typography>
                      <b>
                        {allActionsData?.actions?.map(
                          (items, idx) =>
                            items?.title === item && `A${idx + 1} - `
                        )}
                      </b>
                      {item}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
            <Grid container spacing={2} justifyContent={"flex-end"}>
              <Grid item>
                <Space size={15}>
                  <Button
                    className="small-btn text-color-grey px-20"
                    onClick={() => handleClose(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    className="small-btn primary-btn px-20"
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </Space>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmActionAlignmentModal;
