import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import Edit from "../../../assets/images/edit.png";
import LinkedinIocn from "../../../assets/images/linkedin.svg";
import TwitterIcon from "../../../assets/images/twitter.svg";
import ResumeIcon from "../../../assets/images/resume.svg";
import { ReactComponent as EditBig } from "../../../assets/images/edit_big.svg";
import { Dropdown, Button, Space, Modal } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditWorkExperienceModal from "./editProfileModals/EditWorkExperienceModal";
import EditEducationModal from "./editProfileModals/EditEducationModal";
import EditHobbiesModal from "./editProfileModals/EditHobbiesModal";
import EditCertificationsModal from "./editProfileModals/EditCertificationsModal";
import EditAchievementsModal from "./editProfileModals/EditAchievementsModal";
import EditSoftSkillsModal from "./editProfileModals/EditSoftSkillsModal";
import EditProfessionalModal from "./editProfileModals/EditProfessionalModal";
import { getMethod } from "../../Api'sCall/Apicalling";
import moment from "moment";
import { useLoadingContext } from "../../BeforeLogin/Context/loadingContext";
import { useStorageContext } from "../../BeforeLogin/Context/storageContext";

const MyProfile = () => {
  const { storageDetail } = useStorageContext();
  const [profiledata, setProfileData] = useState([]);
  const [currentModalData, setCurrentModalData] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [isEditWorkExperienceModalOpen, setIsEditWorkExperienceModalOpen] =
    useState(false);
  const [isEditEducationModalOpen, setIsEditEducationModalOpen] =
    useState(false);
  const [isProfessionalModalOpen, setIsProfessionalModalOpen] = useState(false);
  const [isCertificationsModalOpen, setIsCertificationsModalOpen] =
    useState(false);
  const [isAchievementsModalOpen, setIsAchievementsModalOpen] = useState(false);
  const [isHobbiesModalOpen, setIsHobbiesModalOpen] = useState(false);
  const [isSoftSkillsModalOpen, setIsSoftSkillsModalOpen] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [showMoreEducations, setShowMoreEducations] = useState(false);
  const [showMoreCerifications, setShowMoreCerifications] = useState(false);
  const [showMoreAchievements, setShowMoreAchievements] = useState(false);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [functionOptions, setFunctionOptions] = useState([]);
  const [industryVal, setIndustryVal] = useState("");
  const { setIsLoading } = useLoadingContext();
  const defaultViewableData = 5;

  const toggleShowMoreEducations = () => {
    setShowMoreEducations(!showMoreEducations);
  };

  const toggleShowMoreCerifications = () => {
    setShowMoreCerifications(!showMoreCerifications);
  };

  const toggleShowMoreAchievements = () => {
    setShowMoreAchievements(!showMoreAchievements);
  };

  const config = {
    title: "Error",
    content: (
      <>
        <span>Maximum 8 education entries are allowed</span>
        <br />
      </>
    ),
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };
  function CustomPrevArrow(props) {
    return (
      <button {...props} className="custom-arrow custom-prev-arrow">
        Previous
      </button>
    );
  }
  function CustomNextArrow(props) {
    return (
      <button {...props} className="custom-arrow custom-next-arrow">
        Next
      </button>
    );
  }

  const items = [
    {
      label: "Work Experiences",
      key: "0",
    },
    {
      label: "Educations",
      key: "1",
    },
    {
      label: "Professional Associations",
      key: "2",
    },
    {
      label: "Certifications and Courses",
      key: "3",
    },
    {
      label: "Achievements and Accolades",
      key: "4",
    },
    {
      label: "Soft Skills",
      key: "5",
    },
    {
      label: "Hobbies and Interests",
      key: "6",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    let id = storageDetail?.user_id;
    if (id) {
      getMethod(`${process.env.REACT_APP_API_URL}my-profile/${id}`).then(
        (response) => {
          if (response.status === "S") {
            setProfileData(response.response["Profile Data"]);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getMethod(`${process.env.REACT_APP_API_URL}industries`).then((response) => {
      if (response.status === "S") {
        setIndustryOptions(response?.response?.result);
      } else {
      }
    });
    getMethod(`${process.env.REACT_APP_API_URL}functions`).then((response) => {
      if (response.status === "S") {
        setFunctionOptions(response?.response?.result);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (industryVal) {
      setIsLoading(true);
      //make it dynamic once get the data now passing Technology
      getMethod(
        `${process.env.REACT_APP_API_URL}industries/?q=${industryVal}`
      ).then((response) => {
        if (response.status === "S") {
          setFunctionOptions(response?.response?.result?.functions);
          setIsLoading(false);
        } else {
          setFunctionOptions([]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryVal]);

  const handleOpenModal = (key) => {
    if (
      key === "1" &&
      profiledata &&
      profiledata?.education &&
      profiledata?.education.length === 8
    ) {
      modal.error(config);
      return;
    }
    setIsAdd(true);
    switch (key) {
      case "0":
        setIsEditWorkExperienceModalOpen(true);
        break;
      case "1":
        setIsEditEducationModalOpen(true);
        break;
      case "2":
        setIsProfessionalModalOpen(true);
        break;
      case "3":
        setIsCertificationsModalOpen(true);
        break;
      case "4":
        setIsAchievementsModalOpen(true);
        break;
      case "5":
        setIsSoftSkillsModalOpen(true);
        break;
      case "6":
        setIsHobbiesModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleEditOpen = (currentData, key) => {
    let currentDataModalWise = { ...currentData, key };
    setIsAdd(false);
    switch (key) {
      case "0":
        setIsEditWorkExperienceModalOpen(true);
        currentDataModalWise = {
          ...currentDataModalWise,
          isWorkExperience: true,
        };
        setCurrentModalData(currentDataModalWise);
        break;
      case "1":
        setIsEditEducationModalOpen(true);
        currentDataModalWise = { ...currentDataModalWise, isEducation: true };
        setCurrentModalData(currentDataModalWise);
        break;
      case "2":
        setIsProfessionalModalOpen(true);
        currentDataModalWise = {
          ...currentDataModalWise,
          isProfessional: true,
        };
        setCurrentModalData(currentDataModalWise);
        break;
      case "3":
        setIsCertificationsModalOpen(true);
        currentDataModalWise = {
          ...currentDataModalWise,
          isCerification: true,
        };
        setCurrentModalData(currentDataModalWise);
        break;
      case "4":
        setIsAchievementsModalOpen(true);
        currentDataModalWise = { ...currentDataModalWise, isAchievement: true };
        setCurrentModalData(currentDataModalWise);
        break;
      case "5":
        setIsSoftSkillsModalOpen(true);
        currentDataModalWise = { ...currentDataModalWise, isSoftSkill: true };
        break;
      case "6":
        setIsHobbiesModalOpen(true);
        currentDataModalWise = { ...currentDataModalWise, isHobbies: true };
        break;
      default:
        break;
    }
  };

  const getIndustry = (industry) => {
    setIndustryVal(industry);
  };

  return (
    <div>
      {/* Add more buttons when no data found initially 
      from resume uploaded or no data entered while signup  
      */}
      {profiledata && Object.keys(profiledata).length > 0 ? (
        <>
          <EditProfessionalModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            // id={item.id}
            isOpen={isProfessionalModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsProfessionalModalOpen}
          />
          {isEditWorkExperienceModalOpen && (
            <EditWorkExperienceModal
              data={[]}
              setProfileData={setProfileData}
              profiledata={profiledata}
              isOpen={isEditWorkExperienceModalOpen}
              isAdd={isAdd}
              currentModalData={currentModalData}
              setCurrentModalData={setCurrentModalData}
              handleModalOpenClose={setIsEditWorkExperienceModalOpen}
              industryOptions={industryOptions}
              functionOptions={functionOptions}
              getIndustry={getIndustry}
            />
          )}

          <EditEducationModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            isOpen={isEditEducationModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsEditEducationModalOpen}
          />
          <EditAchievementsModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            isOpen={isAchievementsModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsAchievementsModalOpen}
          />
          <EditCertificationsModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            isOpen={isCertificationsModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsCertificationsModalOpen}
          />
          <EditHobbiesModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            isOpen={isHobbiesModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsHobbiesModalOpen}
          />
          <EditSoftSkillsModal
            data={[]}
            setProfileData={setProfileData}
            profiledata={profiledata}
            isOpen={isSoftSkillsModalOpen}
            isAdd={isAdd}
            currentModalData={currentModalData}
            setCurrentModalData={setCurrentModalData}
            handleModalOpenClose={setIsSoftSkillsModalOpen}
          />
        </>
      ) : null}
      {/* Employment history */}
      {profiledata && profiledata.experience ? (
        <div className="card-outer-wrap">
          <div className="card-warpper">
            <p className="wrapTitle">Employment history</p>
            <Slider {...settings} className="common-slider">
              {Object.entries(profiledata).length > 0
                ? (profiledata.experience ? profiledata.experience : []).map(
                    (item, index) => {
                      return (
                        <>
                          <div className="greybg-card">
                            <Button
                              onClick={() => {
                                handleEditOpen(
                                  profiledata.experience[index],
                                  "0"
                                );
                              }}
                              className="edit-btn"
                            >
                              <img src={Edit} alt="edit icon" />
                            </Button>
                            {isEditWorkExperienceModalOpen && (
                              <EditWorkExperienceModal
                                data={
                                  profiledata.experience
                                    ? profiledata.experience[index]
                                    : []
                                }
                                setProfileData={setProfileData}
                                profiledata={profiledata}
                                isOpen={isEditWorkExperienceModalOpen}
                                isAdd={isAdd}
                                currentModalData={currentModalData}
                                setCurrentModalData={setCurrentModalData}
                                handleModalOpenClose={
                                  setIsEditWorkExperienceModalOpen
                                }
                                industryOptions={industryOptions}
                                functionOptions={functionOptions}
                                getIndustry={getIndustry}
                              />
                            )}

                            <div className="employee-wrap">
                              <span className="employee-img"></span>
                              <Button className="social-icon">
                                {item?.retrieved_from === "resume" && (
                                  <img src={ResumeIcon} alt="social icon" />
                                )}
                                {item?.retrieved_from === "linkedin" && (
                                  <img src={LinkedinIocn} alt="social icon" />
                                )}
                              </Button>
                            </div>
                            <div>
                              <p className="employee-role">{item.role}</p>
                              <p className="company-name">{item.company}</p>
                              {item?.start_date && item?.end_date !== null && (
                                <p className="duration">
                                  {moment(item?.start_date, "MMMM YYYY").format(
                                    "MMM. YYYY"
                                  )}
                                  {item?.end_date?.includes("Present") ? (
                                    <>
                                      <span className="divider-dash">-</span>
                                      {item.end_date}
                                    </>
                                  ) : (
                                    <>
                                      <span className="divider-dash">-</span>
                                      {moment(
                                        item?.end_date,
                                        "MMMM YYYY"
                                      ).format("MMM. YYYY")}
                                    </>
                                  )}
                                  {item.duration && (
                                    <>
                                      <span className="divider-dot"></span>
                                      {item.duration}
                                    </>
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    }
                  )
                : null}
            </Slider>
          </div>
        </div>
      ) : null}

      {/* Education */}
      <Grid container spacing={2}>
        {profiledata?.education && profiledata?.education.length > 0 ? (
          <Grid item md={6} xs={12}>
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <p className="wrapTitle">Education</p>
                {contextHolder}
                <Grid container spacing={2}>
                  {Object.entries(profiledata).length > 0
                    ? (profiledata.education ? profiledata.education : [])
                        .slice(
                          0,
                          showMoreEducations
                            ? profiledata.education.length
                            : defaultViewableData
                        )
                        .map((item, index) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <div className="greybg-card">
                                  <Button
                                    onClick={() => {
                                      handleEditOpen(
                                        profiledata.education[index],
                                        "1"
                                      );
                                    }}
                                    className="edit-btn"
                                  >
                                    <img src={Edit} alt="edit icon" />
                                  </Button>
                                  <EditEducationModal
                                    data={
                                      profiledata.education
                                        ? profiledata.education[index]
                                        : []
                                    }
                                    setProfileData={setProfileData}
                                    profiledata={profiledata}
                                    isOpen={isEditEducationModalOpen}
                                    isAdd={isAdd}
                                    currentModalData={currentModalData}
                                    setCurrentModalData={setCurrentModalData}
                                    handleModalOpenClose={
                                      setIsEditEducationModalOpen
                                    }
                                  />
                                  <div className="employee-wrap">
                                    <span className="employee-img"></span>
                                    <Button className="social-icon">
                                      {item?.retrieved_from === "resume" && (
                                        <img
                                          src={ResumeIcon}
                                          alt="social icon"
                                        />
                                      )}
                                      {item?.retrieved_from === "linkedin" && (
                                        <img
                                          src={LinkedinIocn}
                                          alt="social icon"
                                        />
                                      )}
                                    </Button>
                                  </div>

                                  <div>
                                    <p className="employee-role">
                                      {item?.university}
                                    </p>
                                    <p className="company-name">
                                      {item?.degree}
                                    </p>
                                    <p className="duration">
                                      {item.start_date && (
                                        <>
                                          {moment(
                                            item?.start_date,
                                            "MMMM YYYY"
                                          ).format("YYYY")}
                                        </>
                                      )}
                                      {item.end_date && (
                                        <>
                                          <span className="divider-dash">
                                            -
                                          </span>
                                          {moment(
                                            item?.end_date,
                                            "MMMM YYYY"
                                          ).format("YYYY")}
                                        </>
                                      )}
                                      {item.duration && (
                                        <>
                                          <span className="divider-dot"></span>
                                          {item.duration}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            </>
                          );
                        })
                    : null}
                </Grid>
                {profiledata.education.length > defaultViewableData && (
                  <div className="mt-3 text-center">
                    <button
                      className="add-more-btn"
                      onClick={toggleShowMoreEducations}
                    >
                      {!showMoreEducations ? (
                        <Space>View more</Space>
                      ) : (
                        <Space>View less</Space>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        ) : null}

        {/* Certifications */}
        <Grid item md={6} xs={12}>
          {profiledata && profiledata.certifications_and_courses ? (
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <p className="wrapTitle">Certifications and Courses</p>

                <Grid container spacing={2}>
                  {Object.entries(profiledata).length > 0
                    ? (profiledata.certifications_and_courses
                        ? profiledata.certifications_and_courses
                        : []
                      )
                        .slice(
                          0,
                          showMoreCerifications
                            ? profiledata.certifications_and_courses.length
                            : defaultViewableData
                        )
                        .map((item, index) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <div className="greybg-card">
                                  <Button
                                    onClick={() => {
                                      handleEditOpen(
                                        profiledata.certifications_and_courses[
                                          index
                                        ],
                                        "3"
                                      );
                                    }}
                                    className="edit-btn"
                                  >
                                    <img src={Edit} alt="edit icon" />
                                  </Button>
                                  <EditCertificationsModal
                                    data={
                                      profiledata.certifications_and_courses
                                        ? profiledata
                                            .certifications_and_courses[index]
                                        : []
                                    }
                                    setProfileData={setProfileData}
                                    profiledata={profiledata}
                                    id={item.id}
                                    isOpen={isCertificationsModalOpen}
                                    isAdd={isAdd}
                                    currentModalData={currentModalData}
                                    setCurrentModalData={setCurrentModalData}
                                    handleModalOpenClose={
                                      setIsCertificationsModalOpen
                                    }
                                  />
                                  <div className="employee-wrap">
                                    <span className="employee-img"></span>
                                    <Button className="social-icon">
                                      {item?.retrieved_from === "resume" && (
                                        <img
                                          src={ResumeIcon}
                                          alt="social icon"
                                        />
                                      )}
                                      {item?.retrieved_from === "linkedin" && (
                                        <img
                                          src={LinkedinIocn}
                                          alt="social icon"
                                        />
                                      )}
                                    </Button>
                                  </div>
                                  <div>
                                    <p className="employee-role">
                                      {item.title}
                                    </p>
                                    <p className="company-name">
                                      {item.from_organization}
                                    </p>
                                    <p className="company-name">
                                      {item.description}
                                    </p>
                                    <p className="duration">
                                      Exhibited:
                                      {item.exhibited_on && (
                                        <>
                                          {moment(
                                            item?.exhibited_on,
                                            "MMMM YYYY"
                                          ).format("MMM. YYYY")}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            </>
                          );
                        })
                    : null}
                </Grid>
                {profiledata.certifications_and_courses.length >
                  defaultViewableData && (
                  <div className="mt-3 text-center">
                    <button
                      className="add-more-btn"
                      onClick={toggleShowMoreCerifications}
                    >
                      {!showMoreCerifications ? (
                        <Space>View more</Space>
                      ) : (
                        <Space>View less</Space>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      {/* Achievements and Accolades */}
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          {profiledata?.achievements_and_accolades &&
          profiledata.achievements_and_accolades.length > 0 ? (
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <p className="wrapTitle">Achievements and Accolades</p>
                <Grid container spacing={2}>
                  {Object.entries(profiledata).length > 0
                    ? profiledata.achievements_and_accolades
                      ? (profiledata.achievements_and_accolades
                          ? profiledata.achievements_and_accolades
                          : []
                        )
                          .slice(
                            0,
                            showMoreAchievements
                              ? profiledata.achievements_and_accolades.length
                              : defaultViewableData
                          )
                          .map((item, index) => {
                            return (
                              <>
                                <Grid item xs={12}>
                                  <div className="greybg-card">
                                    <Button
                                      onClick={() => {
                                        handleEditOpen(
                                          profiledata
                                            .achievements_and_accolades[index],
                                          "4"
                                        );
                                      }}
                                      className="edit-btn"
                                    >
                                      <img src={Edit} alt="edit icon" />
                                    </Button>
                                    <EditAchievementsModal
                                      data={
                                        profiledata.achievements_and_accolades
                                          ? profiledata
                                              .achievements_and_accolades[index]
                                          : []
                                      }
                                      setProfileData={setProfileData}
                                      profiledata={profiledata}
                                      isOpen={isAchievementsModalOpen}
                                      isAdd={isAdd}
                                      currentModalData={currentModalData}
                                      setCurrentModalData={setCurrentModalData}
                                      handleModalOpenClose={
                                        setIsAchievementsModalOpen
                                      }
                                    />
                                    <div className="employee-wrap">
                                      <span className="employee-img"></span>
                                      <Button className="social-icon">
                                        {item?.retrieved_from === "resume" && (
                                          <img
                                            src={ResumeIcon}
                                            alt="social icon"
                                          />
                                        )}
                                        {item?.retrieved_from ===
                                          "linkedin" && (
                                          <img
                                            src={LinkedinIocn}
                                            alt="social icon"
                                          />
                                        )}
                                      </Button>
                                    </div>

                                    <div>
                                      <p className="employee-role">
                                        {item.title}
                                      </p>
                                      <p className="duration">
                                        From: {item.from_organization}&nbsp;
                                        {item.exhibited_on && (
                                          <>
                                            {moment(
                                              item?.exhibited_on,
                                              "MMMM YYYY"
                                            ).format("MMM. YYYY")}
                                          </>
                                        )}
                                      </p>

                                      <p className="card-description">
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                </Grid>
                              </>
                            );
                          })
                      : null
                    : null}
                </Grid>
                {profiledata.achievements_and_accolades.length >
                  defaultViewableData && (
                  <div className="mt-3 text-center">
                    <button
                      className="add-more-btn"
                      onClick={toggleShowMoreAchievements}
                    >
                      {!showMoreAchievements ? (
                        <Space>View more</Space>
                      ) : (
                        <Space>View less</Space>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Grid>

        {/* Recommendations */}
        <Grid item md={6} xs={12}>
          {profiledata && profiledata.recommendations ? (
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <p className="wrapTitle">Recommendations and References</p>

                <Grid container spacing={2}>
                  {Object.entries(profiledata).length > 0
                    ? (profiledata.recommendations
                        ? profiledata.recommendations
                        : []
                      ).map((item) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <div className="greybg-card">
                                <Button className="edit-btn">
                                  <img src={Edit} alt="edit icon" />
                                </Button>
                                <div className="employee-wrap">
                                  <span className="employee-img"></span>
                                  <Button className="social-icon">
                                    {item?.retrieved_from === "resume" && (
                                      <img src={ResumeIcon} alt="social icon" />
                                    )}
                                    {item?.retrieved_from === "linkedin" && (
                                      <img
                                        src={LinkedinIocn}
                                        alt="social icon"
                                      />
                                    )}
                                  </Button>
                                </div>
                                <div>
                                  <p className="employee-role">Name</p>
                                  <p className="company-name">
                                    Technical, Business, & Strategy Operations
                                    Professional
                                  </p>
                                  <p className="duration">
                                    29. March 2021, Name was customer:in of Name
                                  </p>
                                  <div className="recommendations-social-icon">
                                    <img
                                      src={TwitterIcon}
                                      alt="twitter"
                                      className="recommendations-twitterIcon"
                                    />
                                    Associated with Twitter
                                  </div>
                                  <p className="card-description">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et.
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          </>
                        );
                      })
                    : null}
                </Grid>
                {profiledata.education.length > defaultViewableData && (
                  <div className="mt-3 text-center">
                    <button
                      className="add-more-btn"
                      onClick={toggleShowMoreEducations}
                    >
                      {!showMoreEducations ? (
                        <Space>View more</Space>
                      ) : (
                        <Space>View less</Space>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      {/* Professional Associations */}
      {profiledata?.professional_association &&
      profiledata.professional_association.length > 0 ? (
        <div className="card-outer-wrap">
          <div className="card-warpper">
            <p className="wrapTitle">Professional Associations</p>
            <Slider {...settings} className="common-slider">
              {Object.entries(profiledata).length > 0
                ? (profiledata.professional_association
                    ? profiledata.professional_association
                    : []
                  ).map((item, index) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          className="profilepage-experience-item-grid"
                        >
                          <div className="greybg-card">
                            <Button
                              onClick={() => {
                                handleEditOpen(
                                  profiledata.professional_association[index],
                                  "2"
                                );
                              }}
                              className="edit-btn"
                            >
                              <img src={Edit} alt="edit icon" />
                            </Button>
                            <EditProfessionalModal
                              data={
                                profiledata.professional_association
                                  ? profiledata.professional_association[index]
                                  : []
                              }
                              setProfileData={setProfileData}
                              profiledata={profiledata}
                              id={item.id}
                              isOpen={isProfessionalModalOpen}
                              isAdd={isAdd}
                              currentModalData={currentModalData}
                              setCurrentModalData={setCurrentModalData}
                              handleModalOpenClose={setIsProfessionalModalOpen}
                            />
                            <div className="employee-wrap">
                              <span className="employee-img"></span>
                              <Button className="social-icon">
                                {item?.retrieved_from === "resume" && (
                                  <img src={ResumeIcon} alt="social icon" />
                                )}
                                {item?.retrieved_from === "linkedin" && (
                                  <img src={LinkedinIocn} alt="social icon" />
                                )}
                              </Button>
                            </div>
                            <div>
                              <p className="employee-role">
                                {item?.association_name}
                              </p>
                              <p className="company-name">
                                {item?.description}
                              </p>
                              <p className="duration">
                                {item.start_date && (
                                  <>
                                    {moment(
                                      item?.start_date,
                                      "MMMM YYYY"
                                    ).format("MMM. YYYY")}
                                  </>
                                )}
                                {item.end_date && (
                                  <>
                                    <span className="divider-dash">-</span>
                                    {moment(item?.end_date, "MMMM YYYY").format(
                                      "MMM. YYYY"
                                    )}
                                  </>
                                )}
                                {item.duration && (
                                  <>
                                    <span className="divider-dot"></span>
                                    {item.duration}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      </>
                    );
                  })
                : null}
            </Slider>
          </div>
        </div>
      ) : null}

      <Grid container spacing={2}>
        {/* Soft Skills */}
        {profiledata &&
        profiledata?.soft_skills &&
        profiledata.soft_skills.length ? (
          <Grid item md={6} xs={12}>
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <p className="wrapTitle mb-0">Soft Skills</p>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleEditOpen(profiledata.hobbies, "5")}
                      className="edit-big-btn"
                    >
                      <EditBig />
                    </Button>
                    <EditSoftSkillsModal
                      type="bigEdit"
                      data={
                        profiledata.soft_skills ? profiledata.soft_skills : []
                      }
                      setProfileData={setProfileData}
                      profiledata={profiledata}
                      isOpen={isSoftSkillsModalOpen}
                      handleModalOpenClose={setIsSoftSkillsModalOpen}
                      isAdd={isAdd}
                      currentModalData={currentModalData}
                      setCurrentModalData={setCurrentModalData}
                    />
                  </Grid>
                </Grid>
                <div className="softskills">
                  {Object.entries(profiledata).length > 0
                    ? (profiledata.soft_skills
                        ? profiledata.soft_skills
                        : []
                      ).map((item) => {
                        return <span className="softskill-item">{item}</span>;
                      })
                    : null}
                </div>
              </div>
            </div>
          </Grid>
        ) : null}

        {/* Hobbies */}
        {profiledata && profiledata.hobbies && profiledata.hobbies.length ? (
          <Grid item md={6} xs={12}>
            <div className="card-outer-wrap">
              <div className="card-warpper">
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <p className="wrapTitle mb-0">Hobbies and Interests</p>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleEditOpen(profiledata.hobbies, "6")}
                      className="edit-big-btn"
                    >
                      <EditBig />
                    </Button>
                    <EditHobbiesModal
                      type="bigEdit"
                      data={profiledata?.hobbies ? profiledata.hobbies : []}
                      setProfileData={setProfileData}
                      profiledata={profiledata}
                      isOpen={isHobbiesModalOpen}
                      handleModalOpenClose={setIsHobbiesModalOpen}
                      isAdd={isAdd}
                      currentModalData={currentModalData}
                      setCurrentModalData={setCurrentModalData}
                    />
                  </Grid>
                </Grid>
                <div className="softskills">
                  {Object.entries(profiledata).length > 0
                    ? (profiledata.hobbies ? profiledata.hobbies : []).map(
                        (item) => {
                          return (
                            <span className="softskill-item hobbies-item">
                              {item}
                            </span>
                          );
                        }
                      )
                    : null}
                </div>
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>

      <div className="add-more-wrap">
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => handleOpenModal(key),
          }}
          trigger={["click"]}
        >
          <button className="add-more-btn" onClick={(e) => e.preventDefault()}>
            <Space>
              <Icon icon="icon-park-outline:add" />
              Add more
            </Space>
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default MyProfile;
