import React from "react";
import VerifyCheckmark from "../../assets/images/verify-checkmark.png";
import "../CSS/forgotFlow.css";
import { useNavigate } from "react-router-dom";

const PasswordChangedPage = () => {
  const navigate = useNavigate();
  return (
    <div className="password-wrapper">
      <div className="password-change">
        <div className="pwd-img">
          <img src={VerifyCheckmark} alt="verify-checkmark" />
        </div>
        <div className="pwd">
          <span>Password Successfully Changed</span>
        </div>
        <div className="pwd-btn">
          <button onClick={() => navigate("/login")}>Login</button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangedPage;
