import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import { Button, Space } from "antd";
import { ReactComponent as YoueAi } from "../../../../assets/images/youe-ai-black.svg";

const ConfirmationPopup = ({
  isOpen,
  setIsOpen,
  title,
  handleSaveData,
  handleSaveTimeline,
  handleAlignment,
  mode,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg confirmation-popup"
      >
        <Box className="modal-wrap">
          {title ||
            `A new milestone has been added. Would you like ${(
              <YoueAi />
            )} to create new actions and critical enablers?`}

          <Grid
            container
            columnSpacing={2}
            rowSpacing={0}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Space size={15}>
                <Button
                  className="small-btn primary-btn conf-btn"
                  onClick={() => {
                    handleClose();
                    switch (mode) {
                      case "regularConfirmation":
                        return handleSaveData(true);
                      case "timeLineConfirmation":
                        return handleSaveTimeline(true);
                      case "alignmentConfirmation":
                        return handleAlignment(true);
                      default:
                        return handleSaveData(true);
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="small-btn text-color-grey conf-btn"
                  onClick={() => {
                    handleClose();
                    switch (mode) {
                      case "regularConfirmation":
                        return handleSaveData(false);
                      case "timeLineConfirmation":
                        return handleSaveTimeline(false);
                      case "alignmentConfirmation":
                        return handleAlignment(false);
                      default:
                        return handleSaveData(false);
                    }
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationPopup;
