import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { postMethod } from "../../Api'sCall/Apicalling";
import { useLoadingContext } from "../Context/loadingContext";
import { useStepContext } from "../Context/activeStepContext";
import { toast } from "react-toastify";
import { useStorageContext } from "../Context/storageContext";

const LinkedinSuccessPage = () => {
  const { setStorageDetail } = useStorageContext();
  const navigate = useNavigate();
  const { setStep } = useStepContext();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { setIsLoading } = useLoadingContext();

  console.log("Inside success");

  useEffect(() => {
    setIsLoading(true);
    const code = [...searchParams].map((item) => item[0] === "code" && item[1]);
    let type = "";
    if (pathname === "/linkedin-signup/success") {
      type = "signup";
    } else {
      type = "signin";
    }
    if (code[0]) {
      postMethod(`${process.env.REACT_APP_API_URL}linkedin-${type}/`, {
        code: code[0],
      }).then((response) => {
        if (response.status === "S") {
          setStorageDetail({ user_id: response.response.user_id });
          // sessionStorage.setItem("user_id", response.response.user_id);
          navigate("/email-signup");
          if (response.response?.redirect_step) {
            setStep(response.response?.redirect_step + 3);
          }
          setIsLoading(false);
        } else if (
          response?.errorData?.response?.status === 400 &&
          response?.errorData?.response?.data?.user_id
        ) {
          toast.error(response?.errorData?.response?.data?.message || "");
          navigate("/login");

          setIsLoading(false);
        } else if (
          response?.errorData?.response?.status === 404 ||
          response?.errorData?.response?.status === 400
        ) {
          toast.error(response?.errorData?.response?.data?.message || "");
          navigate("/login");

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <div></div>;
};

export default LinkedinSuccessPage;
