import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import AvoidGoalImg from "../../../assets/images/goal-ambiguity.png";
import { Grid } from "@mui/material";

const AvoidGoal = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          Most professionals struggle to find clarity in their career goals,
          leaving them directionless.
        </p>
        <p className="details-text">
          Youe provides you with personalised roadmaps to keep moving toward
          where you want to go.
        </p>
        <button className="join-btn">Become a member</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img src={AvoidGoalImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default AvoidGoal;
