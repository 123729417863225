import React, { useEffect, useState } from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Input, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import DeleteIcon from "../../../../assets/images/delete.png";
import {
  deleteTrip,
  getMethod,
  patchTripdata,
  postMethod,
} from "../../../Api'sCall/Apicalling";
import "../../../CSS/Welcome.css";
import "./modals.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const { Option } = Select;
const { MonthPicker } = DatePicker;
const currentRoleOptions = [
  "Senior Product Designer",
  "Junior Product Designer",
];
const industryOption = ["Technology"];

const EditEducationModal = ({
  data,
  isAdd,
  currentModalData,
  setCurrentModalData,
  setProfileData,
  profiledata,
  isOpen,
  handleModalOpenClose,
}) => {
  const { storageDetail } = useStorageContext();
  const [iconState, setIconState] = useState({
    dropdown1: false,
    dropdown2: false,
  });

  const handleResetClose = () => {
    handleModalOpenClose(false);
    setCurrentModalData({});
    reset();
  };

  const getData = (type, editedFormData) => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}my-profile/education/${user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          if (type === "edit") {
            const profiledataCopy = { ...profiledata };

            const editIndex = profiledata.education.findIndex(
              (item) => item.id === currentModalData.id
            );
            profiledataCopy.education[editIndex] = editedFormData;

            setProfileData(profiledataCopy);
          } else {
            setProfileData({
              ...profiledata,
              education: response.response.education,
            });
            setCurrentModalData({});
            handleResetClose();
          }
          handleResetClose();
        } else {
        }
      });
    }
  };

  const handleDelete = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      deleteTrip(
        `${process.env.REACT_APP_API_URL}my-profile/delete-education/${user_id}/${currentModalData.id}/`
      ).then((response) => {
        if (response.status === "S") {
          handleResetClose();
          getData();
        }
      });
    }
  };

  const handleEdit = (editData) => {
    const user_id = storageDetail?.user_id;
    let apiData = {
      ...editData,
      start_date: moment(editData.start_date).format("MMMM YYYY"),
      end_date: moment(editData.end_date).format("MMMM YYYY"),
    };
    if (user_id) {
      if (isAdd) {
        postMethod(
          `${process.env.REACT_APP_API_URL}my-profile/education/${user_id}/`,
          { education: apiData }
        ).then((response) => {
          if (response.status === "S") {
            // setIsLoading(false);
            getData();
            // reset();
          } else {
            // setApiError(response.errorData.response.currentModalData.message);
            // setIsLoading(false);
          }
        });
      } else {
        apiData = {
          ...apiData,
          id: currentModalData.id,
        };
        patchTripdata(
          `${process.env.REACT_APP_API_URL}my-profile/edit-education/${user_id}/${currentModalData.id}/`,
          { updated_education: apiData }
        ).then((response) => {
          if (response.status === "S") {
            // reset();
            getData("edit", apiData);
          }
        });
      }
    }
  };

  const toggleDropdown = (dropdown) => {
    setIconState((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const validationSchema = yup.object().shape({
    university: yup
      .string()
      .required("University is required")
      .trim()
      .matches(
        /[a-zA-Z]+/,
        "University must contain at least one alphabet character"
      )
      .min(1, "University must contain at least one character")
      .max(150, "Maximum 150 characters allowed"),
    degree: yup
      .string()
      .required("Degree is required")
      .max(100, "Maximum 100 characters allowed"),
    specialisation: yup
      .string()
      .nullable()
      .required("Specialisation is required")
      .max(100, "Maximum 100 characters allowed"),
    start_date: yup
      .date()
      .required("Start Date is required")
      .test(
        "is-future-date",
        "Start date cannot be in the future",
        function (value) {
          const startDate = new Date(value);

          const currentDate = new Date();

          return !startDate || startDate <= currentDate;
        }
      ),
    end_date: yup
      .date()
      .nullable()
      .when("start_date", (start_date, schema) =>
        schema.test(
          "is-after-start-date",
          "End date must be after the start date",
          function (end_date) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);
            return !endDate || endDate >= startDate;
          }
        )
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isAdd === true) {
      reset({ university: "", degree: "", specialisation: "" });
    } else {
      reset({
        university: currentModalData.university,
        degree: currentModalData.degree,
        specialisation: currentModalData.specialisation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onFinish = (currentModalData) => {
    handleEdit(currentModalData);
  };

  let startDateMonthName,
    startDateYear,
    endDateMonthName,
    endDateYear = null;

  if (
    !isAdd &&
    currentModalData &&
    Object.keys(currentModalData).length &&
    currentModalData?.start_date &&
    currentModalData.isEducation
  ) {
    [startDateMonthName, startDateYear] =
      currentModalData?.start_date.split(" ");
    [endDateMonthName, endDateYear] = currentModalData?.end_date.split(" ");
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <div className="modal-overflow">
          <Box className="modal-wrap">
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h6" component="h2" className="modal-title">
                  {isAdd ? "Add" : "Edit"} Education
                </Typography>
              </Grid>
              <Grid item>
                <Button className="modal-close-btn" onClick={handleResetClose}>
                  <Icon icon="ic:round-close" />
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onFinish)}>
              <div className="form-wrap">
                <Grid container spacing={2} className="skill_role_side">
                  <Grid item xs={12}>
                    <label htmlFor="">University</label>
                    <Controller
                      name="university"
                      defaultValue={!isAdd ? currentModalData.university : ""}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="custom_input"
                          placeholder="University of Oxford"
                          defaultValue={
                            !isAdd ? currentModalData.university : ""
                          }
                        />
                      )}
                    />
                    {errors.university && (
                      <p className="custom-modal-error">
                        {errors.university.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="degree">Degree</label>
                      <Controller
                        name="degree"
                        control={control}
                        defaultValue={!isAdd ? currentModalData.degree : ""}
                        render={({ field }) => (
                          // <Select
                          //   className="select-design-signin-ant"
                          //   {...field}
                          //   placeholder="Select degree"
                          //   dropdownStyle={{ zIndex: 2000 }}
                          //   onDropdownVisibleChange={() =>
                          //     toggleDropdown("dropdown1")
                          //   }
                          //   defaultValue={!isAdd ? currentModalData.degree : ""}
                          //   suffixIcon={
                          //     <Icon
                          //       icon={
                          //         iconState.dropdown1
                          //           ? "iconamoon:arrow-up-2"
                          //           : "iconamoon:arrow-down-2"
                          //       }
                          //       className={
                          //         iconState.dropdown1
                          //           ? "dorp-down-icon dd-opened"
                          //           : "dorp-down-icon"
                          //       }
                          //     />
                          //   }
                          // >
                          //   {currentRoleOptions.map((option) => (
                          //     <Option key={option} value={option}>
                          //       {option}
                          //     </Option>
                          //   ))}
                          // </Select>

                          <Input
                            {...field}
                            className="custom_input"
                            placeholder="Enter your degree"
                            defaultValue={!isAdd ? currentModalData.degree : ""}
                          />
                        )}
                      />
                      {errors.degree && (
                        <p className="custom-modal-error">
                          {errors.degree.message}
                        </p>
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <div className="select-field">
                      <label htmlFor="specialisation">Specialisation</label>
                      <Controller
                        name="specialisation"
                        control={control}
                        defaultValue={
                          !isAdd ? currentModalData.specialisation : ""
                        }
                        render={({ field }) => (
                          // <Select
                          //   className="select-design-signin-ant"
                          //   {...field}
                          //   placeholder="Select specialisation"
                          //   dropdownStyle={{ zIndex: 2000 }}
                          //   defaultValue={
                          //     !isAdd ? currentModalData.specialisation : ""
                          //   }
                          //   onDropdownVisibleChange={() =>
                          //     toggleDropdown("dropdown2")
                          //   }
                          //   suffixIcon={
                          //     <Icon
                          //       icon={
                          //         iconState.dropdown2
                          //           ? "iconamoon:arrow-up-2"
                          //           : "iconamoon:arrow-down-2"
                          //       }
                          //       className={
                          //         iconState.dropdown2
                          //           ? "dorp-down-icon dd-opened"
                          //           : "dorp-down-icon"
                          //       }
                          //     />
                          //   }
                          // >
                          //   {industryOption.map((option) => (
                          //     <Option value={option}>{option}</Option>
                          //   ))}
                          // </Select>
                          <Input
                            {...field}
                            className="custom_input"
                            placeholder="Enter your specialisation"
                            defaultValue={
                              !isAdd ? currentModalData.specialisation : ""
                            }
                          />
                        )}
                      />
                      {errors.specialisation && (
                        <p className="custom-modal-error">
                          {errors.specialisation.message}
                        </p>
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <label htmlFor="start_date">Start Date</label>
                    <Controller
                      name="start_date"
                      control={control}
                      defaultValue={
                        !isAdd && startDateMonthName && startDateYear
                          ? dayjs(
                              startDateYear +
                                " " +
                                moment().month(startDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && startDateMonthName && startDateYear
                              ? dayjs(
                                  startDateYear +
                                    " " +
                                    moment()
                                      .month(startDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors.start_date && (
                      <p className="custom-modal-error">
                        {errors.start_date.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="end_date">End Date</label>
                    <Controller
                      name="end_date"
                      control={control}
                      defaultValue={
                        !isAdd && endDateMonthName && endDateYear
                          ? dayjs(
                              endDateYear +
                                " " +
                                moment().month(endDateMonthName).format("MM"),
                              "YYYY MM"
                            )
                          : dayjs()
                      }
                      render={({ field }) => (
                        <MonthPicker
                          {...field}
                          format="MMMM-YYYY"
                          picker="month"
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          defaultValue={
                            !isAdd && endDateMonthName && endDateYear
                              ? dayjs(
                                  endDateYear +
                                    " " +
                                    moment()
                                      .month(endDateMonthName)
                                      .format("MM"),
                                  "YYYY MM"
                                )
                              : dayjs()
                          }
                          className="date-fielde"
                        />
                      )}
                    />
                    {errors.end_date && (
                      <p className="custom-modal-error">
                        {errors.end_date.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  {!isAdd && (
                    <Button className="btn danger-btn" onClick={handleDelete}>
                      <img
                        src={DeleteIcon}
                        alt="delete icon"
                        className="delete-icon"
                      />
                      Delete
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button className="btn primary primary-btn" htmlType="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default EditEducationModal;
