import React, { useEffect, useState } from "react";
import CustomSideSection from "../../CustomComponents/CustomSideSection";
import BottomBtnFooter from "../../CustomComponents/BottomBtnFooter";
import SingleSelectTag from "../../CustomComponents/SingleSelectTag";
import { Input, Tag } from "antd";
import { useForm, Controller } from "react-hook-form";
import { getMethod, postMethod } from "../../Api'sCall/Apicalling";
import { useStepContext } from "../Context/activeStepContext";
import { useProfileContext } from "../Context/profileSetupContext";
import { useLoadingContext } from "../Context/loadingContext";
import { useStorageContext } from "../Context/storageContext";

const options = ["yes", "no"];
const QuestionFourNew = () => {
  const { storageDetail } = useStorageContext();
  const [goalValue, setGoalValue] = useState("yes");
  const { profileData, setProfileData } = useProfileContext();
  const { setIsLoading } = useLoadingContext();
  const [initiatedStepValue, setInitiatedStepValue] = useState("");
  const [initiatedError, setInitiatedError] = useState("");
  const { step, setStep } = useStepContext();
  const [tags, setTags] = useState([]);
  const [newlyAddedTag, setNewlyAddedTag] = useState([]);
  const [userPrevStep, setUserPrevStep] = useState(null);

  const getData = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL}onboarding/questionnaire-4/${storageDetail?.user_id}/`
      ).then((response) => {
        if (response.status === "S") {
          setGoalValue(
            response.response.questionnaire
              .initiated_steps_towards_next_goal === true ||
              response.response.questionnaire
                .initiated_steps_towards_next_goal === null
              ? "yes"
              : "no"
          );
          setTags(
            response.response.questionnaire.list_significant_actions_taken || []
          );
          setProfileData(response.response.questionnaire);
        } else {
          setGoalValue("yes");
          setInitiatedStepValue("");
        }
      });
    }
  };

  useEffect(() => {
    getData();
    getQuestionThreeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagValue = (tag) => {
    setGoalValue(tag);
  };

  const onSubmit = (newTags) => {
    const que4Data = {
      initiated_steps_towards_next_goal: goalValue === "yes" ? true : false,
      list_significant_actions_taken:
        goalValue === "yes" ? (newTags ? newTags : tags) : [],
    };
    if (tags?.length <= 0 && goalValue === "yes") {
      setInitiatedError("At least one step is required");
    }

    if (tags?.length > 0 || goalValue === "no") {
      setIsLoading(true);
      postMethod(
        `${process.env.REACT_APP_API_URL}onboarding/questionnaire-4/`,
        {
          user_id: storageDetail?.user_id,
          ...que4Data,
        }
      ).then((response) => {
        if (response.status === "S") {
          setIsLoading(false);
          setStep((prevStep) => prevStep + 1);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const tagHandleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setValue("list_significant_actions_taken", newTags);
    setTags(newTags);
    setInitiatedError("");
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  const handleInputChange = (e) => {
    setInitiatedStepValue(e.target.value);

    if (/[a-zA-Z]/.test(e.target.value)) {
      setInitiatedError(null);
    } else if (e.target.value === "") {
      setInitiatedError(null);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      if (
        e.target.value.trim().length >= 1 &&
        e.target.value.trim().length <= 100
      ) {
        const newTags = [...tags].filter(
          (tag) => tag.toLowerCase() !== initiatedStepValue.toLowerCase()
        );
        if (/[a-zA-Z]/.test(initiatedStepValue)) {
          if (tags.length >= 5) {
            setInitiatedError("Maximum 5 actions are allowed");
          } else {
            setTags([...newTags, initiatedStepValue]);
            setNewlyAddedTag([...newlyAddedTag, initiatedStepValue]);
            setValue("list_significant_actions_taken", [
              ...newTags,
              initiatedStepValue,
            ]);
            trigger();
            setInitiatedStepValue("");
          }
        } else {
          setInitiatedError(
            "Steps must contain at least one alphabet character"
          );
        }
      } else {
        setInitiatedError("Maximum 100 characters allowed");
      }
    }
  };

  const getQuestionThreeData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (response?.response?.questionnaire?.career_runway_duration >= 24) {
          setUserPrevStep(9); // Intermediate
        } else {
          setUserPrevStep(8); // Q3
        }
      }
    });
  };

  return (
    <>
      {profileData && Object.keys(profileData).length > 0 && (
        <div className="section_wrapper">
          <CustomSideSection
            step={step}
            heading={"Tell us what you have been up to!"}
            subHeading={
              "Your answers here help us tailor your experience on youe to help you design your perfect career"
            }
          />
          <div className="user_from_data height_auto">
            <div className="career_goal tags_blocks career_flex_goal">
              <h2 className="question_heading">
                Have you initiated steps towards your career transition or next
                goal?
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                {goalValue && (
                  <SingleSelectTag
                    options={options}
                    getTagValue={getTagValue}
                    value={goalValue}
                  />
                )}
                {goalValue === "yes" && (
                  <>
                    <div className="initial_steps">
                      <h4>
                        If yes, list one or two significant actions you've taken
                        so far.
                      </h4>
                      <div className="input_block_error">
                        <Input
                          className="custom_input w-100"
                          placeholder="Type in initiated steps"
                          value={initiatedStepValue}
                          onChange={handleInputChange}
                          onKeyDown={handleInputKeyPress}
                        />
                        {errors.list_significant_actions_taken && (
                          <p className="custom-modal-error">
                            {errors.list_significant_actions_taken.message}
                          </p>
                        )}
                        <p className="custom-modal-error">
                          {" "}
                          {initiatedError && initiatedError}
                        </p>

                        <div className="tags-group pt-0">
                          <div className="tags-group">
                            <Controller
                              name="list_significant_actions_taken"
                              control={control}
                              render={({ field }) => (
                                <>
                                  {tags &&
                                    tags.map((tag, index) => (
                                      <Tag
                                        key={index}
                                        {...field}
                                        value={tags}
                                        className="tag-item"
                                        closable
                                        disabled={tags.length === 1}
                                        onClose={(e) => {
                                          e.preventDefault();
                                          tagHandleClose(tag);
                                        }}
                                      >
                                        {tag}
                                      </Tag>
                                    ))}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                )}
              </form>
            </div>
            <BottomBtnFooter
              onSubmit={onSubmit}
              providedPrevStep={userPrevStep}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionFourNew;
