import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Box, Modal } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Button } from "antd";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getMethod, postMethod } from "../../../Api'sCall/Apicalling";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const EmailVerificationModal = ({
  email,
  password,
  phone_number,
  country_code,
  getData,
  isOTPModalVisible,
  isFrom,
  // setIsOTPModalVisible,
  // handleOTPModalOpen,
  handleOTPModalClose,
}) => {
  const { storageDetail } = useStorageContext();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [apiError, setApiError] = useState();
  const [activeOTP, setActiveOTP] = useState(0);
  const [timer, setTimer] = useState(0);
  const [passwordEmail, setPasswordEmail] = useState("");

  let currentOTPIndex = 0;
  const inputRef = useRef(null);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    let id = localStorage.getItem("user_id");

    if (id && isFrom === "password") {
      getMethod(
        `${process.env.REACT_APP_API_URL}user/basic-details/?id=${id}`
      ).then((response) => {
        if (response.status === "S") {
          setPasswordEmail(response?.response?.email);
        } else {
        }
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const { control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resendHandler = () => {
    setOtp(new Array(6).fill(""));
    if (timer === 0) {
      const user_id = storageDetail?.user_id;
      let apiObj = { user_id: user_id };
      let endPoint = `${process.env.REACT_APP_API_URL}account-settings/email/resend-otp/`;
      if (isFrom === "email") {
        apiObj = { ...apiObj, email: email };
      } else if (isFrom === "password") {
        apiObj = { ...apiObj, password: password };
        endPoint = `${process.env.REACT_APP_API_URL}account-settings/password/resend-otp/`;
      } else {
        apiObj = {
          ...apiObj,
          phone_number: phone_number,
          country_code: country_code,
        };
        endPoint = `${process.env.REACT_APP_API_URL}account-settings/mobilenumber/resend-otp/`;
      }
      postMethod(`${endPoint}`, {
        ...apiObj,
      }).then((response) => {
        if (response.status === "S") {
        } else {
        }
      });
      setTimer(10);
    }
  };

  const handleChange = (e, index) => {
    if (index !== currentOTPIndex) {
      currentOTPIndex = index;
    }
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) setActiveOTP(currentOTPIndex - 1);
    else setActiveOTP(currentOTPIndex + 1);
    setOtp(newOtp);
    if (newOtp.join("").length === 6) {
      otpSending(newOtp.join(""));
    }
  };

  const otpSending = (OTP) => {
    const user_id = storageDetail?.user_id;
    let apiObj = { otp: OTP, user_id: user_id };
    let endPoint = `${process.env.REACT_APP_API_URL}/account-settings/email/verify-otp/`;
    if (isFrom === "email") {
      apiObj = { ...apiObj, email: email };
    } else if (isFrom === "password") {
      apiObj = { ...apiObj, password: password };
      endPoint = `${process.env.REACT_APP_API_URL}/account-settings/password/verify-otp/`;
    } else {
      // phone
      apiObj = {
        ...apiObj,
        country_code: country_code,
        phone_number: phone_number,
      };
      endPoint = `${process.env.REACT_APP_API_URL}/account-settings/mobilenumber/verify-otp/`;
    }

    postMethod(`${endPoint}`, {
      ...apiObj,
    }).then((response) => {
      if (response.status === "S") {
        setOtp(new Array(6).fill(""));
        handleOTPModalClose();
        getData();
        if (response.response.status === "fail") {
          setApiError(response?.response?.message);
        }
      } else {
        let errorMsg = response.errorData.response.data;
        if (errorMsg?.message) {
          setApiError(response?.errorData?.response?.data?.message);
        } else {
          setApiError(response?.errorData?.response?.data?.message);
        }
      }
    });
  };

  const handleOnKeyDown = ({ key, target, preventDefault }, index) => {
    currentOTPIndex = index;
    if (key === "Tab" || key === "ArrowRight") {
      setActiveOTP(currentOTPIndex + 1);
    }
    if ((key === "Backspace" && !target.value) || key === "ArrowLeft") {
      setActiveOTP(currentOTPIndex - 1);
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [activeOTP]);

  return (
    <div>
      <Modal
        open={isOTPModalVisible}
        onClose={handleOTPModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap emailverification-modal">
          <Button
            className="modal-close-btn otp-modal"
            onClick={handleOTPModalClose}
          >
            <Icon icon="ic:round-close" />
          </Button>
          <form>
            <div className="otp_section email_verify_madal_otp_section">
              {apiError && <div className="api-error-otp">{apiError}</div>}
              <div className="otp-sub-text">
                <div className="Email-Verification">
                  {/* <h2>Email Verification</h2> */}
                  <h2>
                    {isFrom === "phone" ? "Mobile" : "Password"} Verification
                  </h2>
                </div>
              </div>
              <div className="otp-subText">
                <div className="send_code_text">
                  <p>
                    We've sent a 6-character code to {phone_number}
                    <span className="text-style-1-email">
                      {email ? email : passwordEmail}
                    </span>
                    .
                    <br />
                    This code is time-sensitive, so please input it promptly.
                  </p>
                </div>
              </div>

              <div className="otp_block ">
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <div className="otp-container">
                      {otp.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <input
                              className={
                                apiError
                                  ? "otp-field-error otp-field"
                                  : "otp-field"
                              }
                              type="text"
                              id={`elem${index + 1} `}
                              maxLength="1"
                              onKeyDown={(e) => {
                                handleOnKeyDown(e, index);
                              }}
                              ref={index === activeOTP ? inputRef : null}
                              value={otp[index]}
                              onChange={(e) => handleChange(e, index)}
                              onFocus={(e) => e.target.select()}
                            />
                            {index === 2 && (
                              <div className="hypen-con" id="hypen-icon">
                                <Icon icon="material-symbols:remove" />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                />

                <span className="Didnt-receive-code">
                  Didn't receive code?
                  <span
                    className={`text-style-1 ${
                      timer > 0 ? "resend-disabled" : ""
                    }`}
                    onClick={() => {
                      resendHandler();
                    }}
                  >
                    Resend
                  </span>
                </span>
              </div>
              {timer > 0 && (
                <span className="resend-timer"> Resend in {timer} seconds</span>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailVerificationModal;
