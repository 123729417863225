import React from "react";
import { EmailProvider } from "../BeforeLogin/Context/loginContext";
import { StepProvider } from "../BeforeLogin/Context/activeStepContext";
import { ActiveNameProvider } from "../Dashboard/Context/activeProfileHeader";
import { ProfileProvider } from "../BeforeLogin/Context/profileSetupContext";
import { LoadingProvider } from "../BeforeLogin/Context/loadingContext";
import { LogoutProvider } from "../BeforeLogin/Context/logoutMessageContext";
import { ProfileHeaderProvider } from "../Dashboard/Context/profileDashboardData";
import { BasicDataProvider } from "../Dashboard/Context/basicDetails";
import { ActiveTabProvider } from "../Dashboard/Context/activeTab";
import { StorageProvider } from "../BeforeLogin/Context/storageContext";

function RootProviders({ children }) {
  return (
    <LoadingProvider>
      <StorageProvider>
        <EmailProvider>
          <StepProvider>
            <ProfileProvider>
              <ActiveNameProvider>
                <LogoutProvider>
                  <ProfileHeaderProvider>
                    <ActiveTabProvider>
                      <BasicDataProvider>{children}</BasicDataProvider>
                    </ActiveTabProvider>
                  </ProfileHeaderProvider>
                </LogoutProvider>
              </ActiveNameProvider>
            </ProfileProvider>
          </StepProvider>
        </EmailProvider>
      </StorageProvider>
    </LoadingProvider>
  );
}

export default RootProviders;
