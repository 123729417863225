import React, { useState } from "react";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/youe_logo.svg";
import LinkedinBtn from "../CustomComponents/LinkedinBtn";
import { useStepContext } from "./Context/activeStepContext";
import { useLoadingContext } from "./Context/loadingContext";
import { useStorageContext } from "./Context/storageContext";

const WelcomeField = ({ setMail }) => {
  const { storageDetail, setStorageDetail } = useStorageContext();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();
  const { setStep } = useStepContext();
  const { setIsLoading } = useLoadingContext();
  const [isFocus, setFocus] = useState(false);
  const [apiError, setApiError] = useState();
  const [agreeTermsError, setAgreeTermsError] = useState();
  const emailFormat =
    /^[a-zA-Z]+(?:.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*$/;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email id")
      .required("Email Id is required")
      .matches(emailFormat, "Please provide a valid Email")
      .max(100, "Maximum 100 characters allowed"),
  });

  const { handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getQuestionThreeData = () => {
    getMethod(
      `${process.env.REACT_APP_API_URL}onboarding/questionnaire-3/${storageDetail?.user_id}/`
    ).then((response) => {
      if (response.status === "S") {
        if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === true
        ) {
          // if clear_vision ="yes"
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            setStep(9);
          } else {
            // Q4
            setStep(10);
          }
        } else if (
          response?.response?.questionnaire
            ?.clear_vision_for_next_career_runway === false
        ) {
          if (response?.response?.questionnaire?.career_runway_duration >= 24) {
            // Intermediate
            setStep(9);
          } else {
            // if clear_vision ="no" => Runway recommendation
            setStep(11);
          }
        }
      }
    });
  };

  const onSubmit = (data) => {
    if (agreeTerms) {
      setIsLoading(true);
      postMethod(
        `${process.env.REACT_APP_API_URL}onboarding/email-verification/`,
        data
      ).then((response) => {
        if (response.status === "S") {
          if (response.response.onboarding_status === 1) {
            // on-boarding incomplete
            setStorageDetail({ user_id: response.response.user_id });
            // sessionStorage.setItem("user_id", response.response.user_id);
            const redirectStep = response?.response?.redirect_step;
            [1, 2, 3, 4, 5].includes(redirectStep) && setStep(redirectStep + 3);

            // setStep(redirectStep);
            if (redirectStep === 6) {
              getQuestionThreeData();
            } else if (redirectStep === 8) {
              setStep(11);
            }
            setIsLoading(false);
          } else if (response.response.onboarding_status === 2) {
            setIsLoading(false);
            navigate("/login");
          } else {
            setStep((prevStep) => prevStep + 1);
            setIsLoading(false);
          }
          setMail(response.response.email);
        } else {
          if (response.errorData.response.data.onboarding_status === 2) {
            navigate("/login");
            setIsLoading(false);
            toast.error(response.errorData.response.data.message);
          }
          setApiError(response.errorData.response.data.message);
        }
      });
    } else {
      setAgreeTermsError("Agree to terms and conditions are mandatory");
    }
  };

  const handleagreeTermsChange = () => {
    if (!agreeTerms === false) {
      setAgreeTermsError("Agree to terms and conditions are mandatory");
    } else {
      setAgreeTermsError(null);
    }
    setAgreeTerms(!agreeTerms);
  };

  return (
    <div className="login-con">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-container">
          <div className="Welcome-back-con-main">
            <div className="Welcome-back-to-con">
              <h2 className="">Welcome to</h2>
              <span className="logo">
                <img src={Logo} alt="" />
              </span>
            </div>
          </div>

          <div className="login-field">
            <Controller
              control={control}
              name="email"
              render={({ field: { value, name }, fieldState: { error } }) => (
                <div className={error ? "input-con-e" : "input-con"}>
                  <Icon
                    icon={"majesticons:mail-line"}
                    className={
                      error
                        ? "failure-icon icon"
                        : value && isFocus?.email
                        ? "sucess-icon icon"
                        : "icon"
                    }
                  />
                  <input
                    type="text"
                    name={name}
                    onBlur={() => {
                      setFocus((prev) => ({ ...prev, [name]: false }));
                    }}
                    onChange={({ target: { value } }) => {
                      setApiError();
                      setValue(name, value, { shouldValidate: true });
                      setFocus((prev) => ({ ...prev, [name]: true }));
                    }}
                    placeholder="Enter your email address"
                    className={
                      error
                        ? "outline-failure input-design"
                        : value && isFocus?.email
                        ? "outline-success input-design"
                        : "input-design"
                    }
                  ></input>
                  <div className="error-custom-login">
                    {apiError ? apiError : error?.message}
                  </div>
                </div>
              )}
            />
            <div className="remember-checkbox">
              <div className="checkbox-wrapper-28 checkbox-svg-wrapper">
                <input
                  id="tmp-28"
                  type="checkbox"
                  className="promoted-input-checkbox"
                  onChange={handleagreeTermsChange}
                />
                <svg>
                  <use href="#checkmark-28" />
                </svg>
                <label for="tmp-28"></label>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "none" }}
                  className="checkicon"
                >
                  <g fill="#FF7228" fill-rule="evenodd">
                    <path d="M12.707 6.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L8 9.586l3.293-3.293a1 1 0 0 1 1.414 0z" />
                  </g>
                </svg>
              </div>
              <h6 className="remember-span1">
                I accept the{" "}
                <a
                  href="https://mountain-vulture-6f0.notion.site/Terms-and-Conditions-3c35f42cab974ec4ad4d7ff3ecad0e7d"
                  target="blank"
                >
                  Terms of Service
                </a>{" "}
                &{" "}
                <a
                  href="https://mountain-vulture-6f0.notion.site/Privacy-Policy-44158dcee5634111a4ceeef552beb591"
                  target="blank"
                >
                  Privacy Policy
                </a>
              </h6>
            </div>
            <div className="error-custom-login checkbox-error">
              {agreeTermsError}
            </div>
            <button type="submit" className="email-signup-button primary_btn">
              Get Started
            </button>
            <div className="Welocme-line-login"></div>
            <LinkedinBtn />
            <div className="Welocme-line-login"></div>
            <div className="Welcome-signin">
              Do you have an account?{" "}
              <span
                className="signup"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign in
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WelcomeField;
