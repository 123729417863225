import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { Button } from "antd";
import { ReactComponent as EditBig } from "../../../../assets/images/edit_big.svg";
import PhoneInput from "react-phone-input-2";
import EmailVerificationModal from "./EmailVerificationModal";
import { postMethod } from "../../../Api'sCall/Apicalling";
import "react-phone-input-2/lib/style.css";
import { useStorageContext } from "../../../BeforeLogin/Context/storageContext";

const EditPhoneNumberModal = ({ getData, defaultPhoneNumber }) => {
  let phoneDefault = defaultPhoneNumber ? defaultPhoneNumber.substring(0) : "";
  const { storageDetail } = useStorageContext();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneObj, setPhoneObj] = useState(phoneDefault);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
  };
  const handleOTPModalOpen = () => setIsOTPModalVisible(true);
  const handleOTPModalClose = () => {
    getData();
    setIsOTPModalVisible(false);
  };

  const handleChangePhone = () => {
    const countryCode = `+${phoneObj?.dialCode}`;
    var modifiedPhoneNumber = phoneNumber.slice(phoneObj?.dialCode.length);
    const user_id = storageDetail?.user_id;

    if (!(modifiedPhoneNumber && countryCode)) {
      setErrorMessage("Please enter valid phone number");
      return;
    }
    if (user_id) {
      postMethod(
        `${process.env.REACT_APP_API_URL}account-settings/update-mobilenumber/`,
        {
          user_id: user_id,
          country_code: countryCode,
          phone_number: modifiedPhoneNumber,
        }
      ).then((response) => {
        if (response.status === "S") {
          setErrorMessage("");
          handleClose();
          handleOTPModalOpen();
        } else {
          setErrorMessage(response?.errorData?.response?.data?.message);
        }
      });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} className="flied-edit-btn">
        <EditBig />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalBg"
      >
        <Box className="modal-wrap samll_modal">
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            className="mb-3"
          >
            <Grid item>
              <Typography variant="h6" component="h2" className="modal-title">
                youe
              </Typography>
            </Grid>
            <Grid item>
              <Button className="modal-close-btn" onClick={handleClose}>
                <Icon icon="ic:round-close" />
              </Button>
            </Grid>
          </Grid>

          <div className="login-field change-pass-modal">
            <label className="field-label">Edit Phone Number</label>
            <PhoneInput
              className="phone-number-field"
              enableSearch
              // value={phoneObj}
              onChange={(value, data) => {
                setPhoneObj(data);
                setPhoneNumber(value);
                setErrorMessage("");
              }}
            />
            {errorMessage && <span className="error-msg">{errorMessage}</span>}
            <Button
              className="email-signup-button primary_btn mb-0"
              type="submit"
              onClick={handleChangePhone}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <EmailVerificationModal
        country_code={`+${phoneObj?.dialCode}`}
        phone_number={phoneNumber?.slice(phoneObj?.dialCode?.length)}
        isFrom={"phone"}
        isOTPModalVisible={isOTPModalVisible}
        setIsOTPModalVisible={setIsOTPModalVisible}
        handleOTPModalOpen={handleOTPModalOpen}
        handleOTPModalClose={handleOTPModalClose}
        getData={getData}
      />
    </div>
  );
};

export default EditPhoneNumberModal;
