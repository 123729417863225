import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { getMethod, postMethod } from "../Api'sCall/Apicalling";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStepContext } from "./Context/activeStepContext";
import { useLoadingContext } from "./Context/loadingContext";
import { useStorageContext } from "./Context/storageContext";
import { toast } from "react-toastify";

const CreatePassWordField = ({ email }) => {
  const { setStorageDetail } = useStorageContext();
  const [isFocus, setFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id, name } = useParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const { setStep } = useStepContext();
  const { pathname } = useLocation();
  const { setIsLoading } = useLoadingContext();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    create_password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short")
      // .matches(/\d+/, "Password no number")
      // .matches(/[a-z]+/, "Password no lowercase")
      // .matches(/[A-Z]+/, "Password no uppercase")
      .matches(/[!@#$%^&*()-+]+/, "Password has no special character")
      .matches(/^[\S]+$/, "Password should not have spaces"),
    // .min(8)
    // .matches(new RegExp(/[*@!#%$&()^~{}]+/))
    // .matches(/^[\S]+$/, "Password should not have spaces")
    // .required("Password is required"),
  });

  const { handleSubmit, getValues, setValue, control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const values = getValues();
  // const watchingField = watch("create_password");

  useEffect(() => {
    const ids = pathname.split("/")[2];
    const token = pathname.split("/")[3];
    setIsLoading(true);
    getMethod(
      `${process.env.REACT_APP_API_URL}password-reset/check-reset-link/${ids}/${token}`
    ).then((response) => {
      if (response.status === "S") {
        setIsLoading(false);
      } else {
        toast.error(response.errorData.response.data.message);
        setIsLoading(false);
        navigate("/login");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (data) => {
    const updatedEndPoint = pathname.includes("/reset-password")
      ? `password-reset/setup-new-password/${id}/${name}/`
      : "onboarding/setup-password/";
    const passwordData = pathname.includes("/reset-password")
      ? {
          password: data.create_password,
        }
      : {
          email,
          password: data.create_password,
        };
    const ids = pathname.split("/")[2];
    const token = pathname.split("/")[3];
    setIsLoading(true);
    getMethod(
      `${process.env.REACT_APP_API_URL}password-reset/check-reset-link/${ids}/${token}`
    ).then((response) => {
      if (response.status === "S") {
        postMethod(
          `${process.env.REACT_APP_API_URL}${updatedEndPoint}`,
          passwordData
        ).then((response) => {
          if (response.status === "S") {
            if (pathname.includes("/reset-password")) {
              setIsLoading(false);
              navigate("/password-successfully-changed");
            } else {
              setIsLoading(false);
              setStep((prevStep) => prevStep + 1);
            }
            setStorageDetail({ user_id: response.response.user_id });
          } else {
            setIsLoading(false);
            setErrorMessage(response?.errorData?.response?.data?.message);
          }
        });
      } else {
        toast.error(response.errorData.response.data.message);
        setIsLoading(false);
        navigate("/login");
      }
    });
  };

  const validating = (fun) => {
    let flag = false;

    // if(errors?.create_password?.type===fun){
    //   if(isDirty)
    //      flag=false
    // }
    if (values?.create_password) {
      if (fun === "min") {
        if (values?.create_password?.length < 8) flag = true;
      } else {
        if (/[*@!#%$&()^~{}]+/.test(values?.create_password)) {
          flag = false;
        } else {
          flag = true;
        }
      }
    }

    return flag;
  };
  return (
    <div className="login-con">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-container">
          <div className="create-a-password">
            <h2>Create a new password</h2>
          </div>
          <div className="login-field">
            <Controller
              control={control}
              name="create_password"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <div className={"input-con"}>
                  <Icon
                    icon={
                      showPassword ? "iconamoon:eye" : "octicon:eye-closed-16"
                    }
                    className={
                      error
                        ? "failure-icon eye-password"
                        : value && isFocus?.password
                        ? "sucess-icon eye-password"
                        : "eye-password"
                    }
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                  <Icon
                    icon={"majesticons:lock-line"}
                    className={
                      error
                        ? "failure-icon icon"
                        : value && isFocus?.password
                        ? "sucess-icon icon"
                        : "icon"
                    }
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    name={name}
                    onBlur={() => {
                      setFocus((prev) => ({ ...prev, [name]: false }));
                    }}
                    onChange={({ target: { value } }) => {
                      setValue(name, value, { shouldValidate: true });
                      setFocus((prev) => ({ ...prev, [name]: true }));
                    }}
                    placeholder="Enter password"
                    className={
                      error
                        ? "outline-failure input-design"
                        : value && isFocus?.password
                        ? "outline-success input-design"
                        : "input-design"
                    }
                    // className='outline-failure'
                  ></input>

                  <div className="error-custom-login">{error?.message}</div>
                  {errorMessage && (
                    <span className="error-msg">{errorMessage}</span>
                  )}
                </div>
              )}
            />

            <div className="validate-con">
              <div className="validate-msg">
                {values?.create_password ? (
                  <span>
                    <Icon
                      icon={validating("min") ? "iconoir:cancel" : "mdi:tick"}
                      className={!validating("min") ? "tick" : "cancel"}
                    />
                  </span>
                ) : (
                  <span>
                    <Icon icon={"iconoir:cancel"} className="cancel" />
                  </span>
                )}{" "}
                <span className="password_req desktop_elem">
                  8 characters or more
                </span>
                <span className="password_req mobile_elem">8 characters</span>
              </div>
              <div className="validate-msg">
                {values?.create_password ? (
                  <span className="ic-width">
                    <Icon
                      icon={
                        validating("matches") ? "iconoir:cancel" : "mdi:tick"
                      }
                      className={!validating("matches") ? "tick" : "cancel"}
                    />
                  </span>
                ) : (
                  <span className="ic-width">
                    <Icon icon={"iconoir:cancel"} className="cancel" />
                  </span>
                )}{" "}
                <span className="need_special_chrc">
                  at least 1 special character
                </span>
              </div>
            </div>

            <button type="submit" className="email-signup-button primary_btn">
              {pathname === "/create-password" ? "Change password" : "Continue"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePassWordField;
