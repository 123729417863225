import React from "react";
import { useActiveTab } from "../../Dashboard/Context/activeTab";
import AccelerateGrowthImg from "../../../assets/images/career-goals.png";

import { Grid } from "@mui/material";

const AccelerateGrowth = () => {
  const { activeTab } = useActiveTab();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h3 className="tabs-content-title">{activeTab}</h3>
        <p className="details-text">
          Keep your finger on the pulse and see how you’re tracking. Pinpoint
          areas that need more development at a glance with RoadMap. Powerful
          tracking tools show you exactly what’s going well and what needs more
          work.
        </p>
        <button className="join-btn">Meet roadmap</button>
      </Grid>
      <Grid item xs={7} className="text-end">
        <img src={AccelerateGrowthImg} alt="" className="img-fluid" />
      </Grid>
    </Grid>
  );
};

export default AccelerateGrowth;
