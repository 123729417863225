import React from "react";
import "../CSS/customeSideSection.css";
import { ReactComponent as Logo } from "../../assets/images/youe_logo_black.svg";
import { useStepContext } from "../BeforeLogin/Context/activeStepContext";

const CustomSideSection = ({ heading, subHeading }) => {
  const { step } = useStepContext();

  const h2Style =
    step === 4 || step === 5
      ? { color: "var(--primary-color)" }
      : { color: "#22262b" };
  return (
    <div
      className="custom_sidebar"
      style={{ backgroundColor: `rgba(255, 114, 40,${step / 10})` }}
    >
      <div className="coustome_side_block">
        <div className="dark_logo">
          <Logo />
        </div>
        <div className="section_heading_text">
          <h2 style={h2Style}>{heading ? heading : "Welcome to youe!"}</h2>
          <p>
            {subHeading
              ? subHeading
              : "You are about to get access to our career growth platform to enable you to design your perfect career."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomSideSection;
